import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ReactSelect from "react-select";
import { ToastContainer } from "react-toastify";
import { URL } from "../../../config";
import { PLATFORMCOREFEEDER_APIS } from "../../../Constants/endpoints";
import { errorToast, successToast } from "../../../Containers/toastFunctions";
import { getToken } from "../../../Functions/userAuth";
import "./MzaaloSeo.scss";

const tableName = [
  {
    id: "VideoContents",
    value: "VideoContents",
    label: "Video Contents",
  },
  {
    id: "Episodes",
    label: "Episodes",
    value: "Episodes",
  },
  {
    id: "Contents",
    label: "Contents",
    value: "Contents",
  },
];

const DownloadSeoData = () => {
  const [type, setType] = useState();
  const [csvData, setCsvData] = useState();
  const [loading, setLoading] = useState(false);

  const onChange = (type) => {
    setType(type);
  };

  const handleGetCsv = () => {
    if (!type) {
      return errorToast("Please select one type");
    }

    setLoading(true);

    axios
      .get(`${PLATFORMCOREFEEDER_APIS._getCsv}?tablename=${type.value}`, {
        headers: {
          Authorization: getToken(),
          "Ocp-Apim-Subscription-Key": URL.mzaalo_ocp_key,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res) {
          setCsvData(res.data);
          return successToast("Downloaded CSV");
        } else {
          return errorToast("Something went wrong!");
        }
      })
      .catch((err) => {
        setLoading(false);
        return errorToast(err);
      });
  };

  return (
    <>
      <div className="seo-container">
        <p>Please select any type</p>
        <ReactSelect
          className="seo-container--select"
          placeholder="Select Type"
          options={tableName}
          onChange={onChange}
          isMulti={false}
          value={type}
        />
        <Button
          disabled={loading}
          onClick={handleGetCsv}
          className="seo-container--btn"
        >
          Get CSV
        </Button>
        {csvData && <a href={csvData.fileLink}>Click here to download CSV</a>}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
};

export default DownloadSeoData;
