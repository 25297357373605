import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Alert, Col, Row } from "reactstrap";
import Header from "../../Header";
import { APIS } from "../../../Constants/endpoints";
import { URL } from "../../../config";

const UpdateRewardCategory = () => {
  const { clientId, clientName, id, name, partner } = useParams();

  const [categories, setCategories] = useState({});
  const [category, setCategory] = useState({});
  const [visible, setVisible] = useState();

  const allCategorys = async () => {
    //Request to Get all partner category and redemption category
    if (partner) {
      const { data } = await axios.get(
        `${APIS._getRewardPartnerCategory}?client_id=${partner}`,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": URL.mzaaloAdmin_ocp_key,
          },
        },
      );

      if (data) {
        setCategories(data.data[0]);
      }
    }
  };

  const updateCategory = async () => {
    if (clientId && category.id && category.category_name) {
      const { data } = await axios.put(
        `${APIS._updateRewardCategory}?client_category_id=${clientId}&system_id=${category.id}&system_name=${category.category_name}`,
        {},
        {
          headers: {
            "Ocp-Apim-Subscription-Key": URL.mzaaloAdmin_ocp_key,
          },
        },
      );

      if (data.data && data.success) {
        setVisible(true);
      }
    }
  };

  const onDismiss = () => {
    setVisible(false);
  };

  useEffect(() => {
    allCategorys();
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    updateCategory();
  };

  return (
    <div>
      <Header headerText="Update Reward Category " />
      {visible && (
        <Alert color="info" isOpen={visible} toggle={onDismiss}>
          Successfully Category Updated ...
        </Alert>
      )}
      <form onSubmit={submitHandler}>
        <Container
          style={{
            margin: 10,
            padding: 10,
            border: "2px solid",
            borderRadius: "10px",
          }}
        >
          <Link to={"/rewards-category"}>Go Back</Link>
          <Row style={{ margin: 10 }}>
            <Col>
              <FormControl style={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="outlined-required"
                  value={clientName}
                  helperText="Partner Category"
                  size="medium"
                  variant="filled"
                />
              </FormControl>
            </Col>

            <Col>
              <FormControl isrequired="true" style={{ width: "100%" }}>
                <Select
                  id="demo-simple-select"
                  required
                  fullWidth
                  variant="outlined"
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                >
                  {categories &&
                    categories?.redemptionCategory?.map((p) => (
                      <MenuItem key={p.id} value={p}>
                        {p.category_name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>Select Category</FormHelperText>
              </FormControl>
            </Col>
            <Col>
              <FormControl style={{ width: "70%" }}>
                <Button
                  id="update"
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="primary"
                  style={{ padding: 10 }}
                >
                  Update
                </Button>
              </FormControl>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};

export default UpdateRewardCategory;
