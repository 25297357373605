export const ISOtoDateTimeLocal = (date) => {
  var dateTimeISOString = date || "2020-12-31T12:00:00.000Z";
  var dateTime = new Date(dateTimeISOString);
  var tzoffset = new Date().getTimezoneOffset() * 60000;
  var localISOTime = new Date(dateTime - tzoffset).toISOString().slice(0, -1);
  return localISOTime;
};

export const convertDateToString = (date) => {
  const isoDate = new Date(date);
  return isoDate.toLocaleString();
};
