import React, { useState } from "react";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import {
  Add,
  Close,
  Assessment,
  PersonAdd,
  List,
  GroupWork,
  Campaign,
  AddBox,
  Event,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const PromoNavPanel = (props) => {
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();

  return (
    <div style={{ position: "absolute", margin: "10px", zIndex: "1000" }}>
      <FloatingMenu
        slideSpeed={500}
        direction="down"
        spacing={10}
        isOpen={isOpen}
      >
        <MainButton
          iconResting={<Add style={{ fontSize: 20 }} nativeColor="white" />}
          iconActive={<Close style={{ fontSize: 20 }} nativeColor="white" />}
          backgroundColor="black"
          onClick={() => setOpen(!isOpen)}
          size={props.size ? 30 : 56}
        />
        <ChildButton
          icon={<Add style={{ fontSize: 20 }} nativeColor="black" />}
          backgroundColor="white"
          size={props.size ? 25 : 40}
          onClick={() => history.push({ pathname: "/promocode/create" })}
        />
        <ChildButton
          icon={<List style={{ fontSize: 20 }} nativeColor="black" />}
          backgroundColor="white"
          size={props.size ? 25 : 40}
          onClick={() => history.push({ pathname: "/promocode/view" })}
        />
        <ChildButton
          icon={<PersonAdd style={{ fontSize: 20 }} nativeColor="black" />}
          backgroundColor="white"
          size={props.size ? 25 : 40}
          onClick={() =>
            history.push({ pathname: "/promocode/partner/create" })
          }
        />
        <ChildButton
          icon={<GroupWork style={{ fontSize: 20 }} nativeColor="black" />}
          backgroundColor="white"
          size={props.size ? 25 : 40}
          onClick={() => history.push({ pathname: "/promocode/partner/view" })}
        />
        <ChildButton
          icon={<AddBox style={{ fontSize: 20 }} nativeColor="black" />}
          backgroundColor="white"
          size={40}
          onClick={() =>
            history.push({ pathname: "/promocode/campaign/create" })
          }
        />
        <ChildButton
          icon={<Event style={{ fontSize: 20 }} nativeColor="black" />}
          backgroundColor="white"
          size={40}
          onClick={() => history.push({ pathname: "/promocode/campaign/view" })}
        />
        <ChildButton
          icon={<Assessment style={{ fontSize: 20 }} nativeColor="black" />}
          backgroundColor="white"
          size={40}
          onClick={() => history.push({ pathname: "/promocode/report" })}
        />
      </FloatingMenu>
    </div>
  );
};

export default PromoNavPanel;
