import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { URL } from "../../../config";
import { ToastContainer } from "react-toastify";
import { PLATFORMCOREFEEDER_APIS } from "../../../Constants/endpoints";
import { errorToast, successToast } from "../../../Containers/toastFunctions";
import { getToken } from "../../../Functions/userAuth";
import "./MzaaloSeo.scss";

const UploadSeoData = () => {
  const [loading, setLoading] = useState(false);
  const [seoFile, setSeoFile] = useState();

  const onFileUpload = (e) => {
    e.preventDefault();
    setSeoFile(e.target.files[0]);
  };

  const handleUploadCsv = () => {
    if (!seoFile) {
      return errorToast("Please upload a file");
    }

    if (seoFile?.name.split(".").pop() !== "csv") {
      return errorToast("Please upload csv file only");
    }

    setLoading(true);

    const data = new FormData();
    data.append("excelFile", seoFile);

    axios
      .post(PLATFORMCOREFEEDER_APIS._uploadDescriptionCsv, data, {
        headers: {
          Authorization: getToken(),
          "Ocp-Apim-Subscription-Key": URL.mzaalo_ocp_key,
        },
      })
      .then((res) => {
        setLoading(false);
        console.log("res", res);
        if (!res?.data?.success) {
          return errorToast("something went wrong");
        }
        successToast("Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);
        return errorToast(err);
      });
  };

  return (
    <>
      <div className="seo-container">
        <p>Please upload a CSV file</p>
        <input
          type="file"
          accept=".csv"
          onChange={onFileUpload}
          name="seoFile"
          className="seo-container--file"
        />
        <Button
          disabled={loading}
          onClick={handleUploadCsv}
          className="seo-container--btn"
        >
          Upload CSV
        </Button>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
};

export default UploadSeoData;
