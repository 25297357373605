import React, { useRef } from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";

const BiddingFlippingCard = (props) => {
  const ref = useRef();

  const randomValue = () => {
    return Math.floor(Math.random() * 256);
  };

  return (
    <Flippy
      flipOnHover={false} // default false
      flipOnClick={true} // default false
      flipDirection="horizontal" // horizontal or vertical
      ref={ref} // to use toggle method like ref.curret.toggle()
      // if you pass isFlipped prop component will be controlled component.
      // and other props, which will go to div
      style={{
        margin: "20px",
        minWidth: "340px",
        maxWidth: "340px",
        minHeight: "340px",
      }} /// these are optional style, it is not necessary
    >
      <FrontSide
        style={{
          background: `rgb(${randomValue()}, ${randomValue()}, ${randomValue()})`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <h4>
          <b>{props.item}</b>
        </h4>
      </FrontSide>
      <BackSide style={{ overflow: "hidden" }}>{props.children}</BackSide>
    </Flippy>
  );
};

export default BiddingFlippingCard;
