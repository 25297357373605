import axios from "axios";
import React, { useEffect, useState } from "react";
import { URL } from "../../../config";
import { PROMOCODE_APIS } from "../../../Constants/endpoints";
import { getToken } from "../../../Functions/userAuth";
import useInfiniteScroll from "../../../Utils/hooks/useInfiniteScroll";
import Loader from "../../Common/Loader";
import Header from "../../Header";
import PromoCampaignFlippingCard from "./PromoCampaignFlippingCard";
import PromoNavPanel from "./PromoNavPanel";
import PromoPartnerFlippingCard from "./PromoPartnerFlippingCard";

let items = 20;

const ViewCampaign = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);

  const getPromoCampaignList = () => {
    setLoading(true);
    axios
      .get(`${PROMOCODE_APIS._getCampaignList}?page=${page}&items=${items}`, {
        headers: {
          authorization: `Bearer ${getToken("token")}`,
          "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
        },
      })
      .then((res) => {
        let _data = res && res.data && res.data.data && res.data.data.data;
        if (_data.length > 0) {
          _data = data.concat(_data);
          setData(_data);
        } else {
          setLoading(false);
          setStopScroll(true);
          setIsFetching(false);
        }
        _data = null;
      })
      .catch((err) => {
        setLoading(false);
        setStopScroll(true);
      });
    setIsFetching(false);
  };

  const [isFetching, setIsFetching, setStopScroll] = useInfiniteScroll(() => {
    getPromoCampaignList();
    setPage(page + 1);
  });

  useEffect(() => {
    getPromoCampaignList();
    setPage(page + 1);
  }, []);

  return (
    <>
      <Header type="mainHeader" headerText="View Promocode Campaign List" />
      <PromoNavPanel />
      <PromoCampaignFlippingCard items={data} />
      {(isLoading || isFetching) && (
        <div className="flexbox-center flex-column">
          <Loader />
          <p>Please wait, loading promo partners...</p>
        </div>
      )}
    </>
  );
};

export default ViewCampaign;
