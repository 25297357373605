import React, { useState, useEffect } from "react";
import Header from "../../Header";
import { BIDDING_APIS } from "../../../Constants/endpoints";
import { getToken } from "../../../Functions/userAuth";
import "../../Common/flex.scss";
import { URL } from "../../../config";
import MaterialTable from "material-table";
import { convertDateToString } from "../../../Functions/dateFormats";

const MzaaloSupport = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const getAllSupportQueries = () => {
    setLoading(true);
    fetch(BIDDING_APIS._getSupportQueries, {
      method: "GET",
      headers: {
        authorization: `Bearer ${getToken()}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        let data = res && res.data && res.data.data;
        setLoading(false);
        if (data) {
          setData(data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  let columns = [
    {
      field: "id",
      title: "Support Id",
      hidden: false,
      export: true,
    },
    {
      field: "userName",
      title: "User Name",
      hidden: false,
      export: true,
    },
    {
      field: "email",
      title: "User Email",
      hidden: false,
      export: true,
    },
    {
      field: "category",
      title: "Support Category",
      hidden: false,
      export: true,
    },
    {
      field: "message",
      title: "User Query",
      hidden: true,
      export: true,
    },
    {
      field: "mobile",
      title: "Mobile Number",
      hidden: false,
      export: true,
    },
    {
      field: "createdAt",
      title: "Created At",
      hidden: false,
      export: true,
      render: (rowData) => convertDateToString(rowData.createdAt),
    },
    {
      field: "updatedAt",
      title: "Updated At",
      hidden: true,
      export: true,
    },
  ];

  useEffect(() => {
    getAllSupportQueries();
  }, []);

  return (
    <>
      <Header headerText="Mzaalo Support" type="mainHeader" />
      <MaterialTable
        title={"User Queries"}
        columns={columns}
        data={data}
        options={{
          headerStyle: {
            backgroundColor: "#245642",
            color: "#FFF",
            //   textAlign: 'center',
          },
          detailPanelColumnAlignment: "right",
          search: true,
          exportButton: true,
          // selection: true,
          // detailPanelType: "multiple",
          sorting: true,
          paginationType: "stepped",
          actionsColumnIndex: -1,
        }}
        detailPanel={[
          {
            icon: "keyboard_arrow_right",
            openIcon: "keyboard_arrow_down",
            tooltip: "Show User Query",
            render: (rowData) => {
              return (
                <div
                  style={{ padding: "10px", backgroundColor: "darkseagreen" }}
                >
                  {rowData.message}
                </div>
              );
            },
          },
        ]}
      />
    </>
  );
};

export default MzaaloSupport;
