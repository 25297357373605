import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormText,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Button,
  Alert,
} from "reactstrap";
import Header from "./Header";
import { APIS } from "../Constants/endpoints";
import { getItemFromLS } from "../Utils/localStorage";
import { ToastsStore, ToastsContainer } from "react-toasts";

export default class RegisterUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      status: "",
      user_email: "",
      company_logo: "",
      brand_images: "",
      registered_address: "",
      alertVisible: "true",
      cLPictures: [],
      bIPictures: [],
      isCompanyLogoUploaded: false,
      isBrandImagesUploaded: false,
    };
  }

  onCLDrop = (picture) => {
    this.state.cLPictures.splice(0, 1);
    this.setState({
      cLPictures: this.state.cLPictures.concat(picture),
    });
  };

  onBIDrop = (picture) => {
    this.state.bIPictures.splice(0, 1);
    this.setState({
      bIPictures: this.state.bIPictures.concat(picture),
    });
  };

  handleAddPartner = (e) => {
    let token = getItemFromLS("token");
    e.preventDefault();
    fetch(APIS._addBrand, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
      body: JSON.stringify({
        name: this.state.name,
        device_type: "All",
        user_type: "partner",
        is_deleted: this.state.status === "Active" ? "false" : "true",
        user_email: this.state.user_email,
        company_logo: this.state.company_logo,
        brand_images: [this.state.brand_images],
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success === "User and Brand registered!") {
          ToastsStore.success("Registration Successful");
          this.props.history.push("/");
        } else {
          ToastsStore.error(res);
        }
      })
      .catch((err) => {
        ToastsStore.error("Registration failed");
      });
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <>
        <Header headerText="Register New User" type="mainHeader" />
        <Container style={{ marginTop: "10px" }}>
          <Alert
            color="info"
            isOpen={this.state.alertVisible}
            toggle={this.onDismiss}
          >
            All <span className="required">*</span> fields are mandatory. To
            Register a New User, Please fill all the details.
          </Alert>
          <Form
            onSubmit={this.handleAddPartner}
            style={{
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "20px",
              margin: "10px",
            }}
          >
            <FormGroup>
              <Label for="user_email">
                User Email<span className="required">*</span>
              </Label>
              <Input
                type="email"
                name="user_email"
                id="user_email"
                required
                placeholder="Enter User Email"
                onChange={(e) => this.onChange(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="user_type">
                User Type<span className="required">*</span>
              </Label>
              <CustomInput
                type="select"
                name="user_type"
                id="user_type"
                required
                placeholder="Select User Type"
                onChange={(e) => this.onChange(e)}
              >
                <option value="">Select User Type</option>
                <option value="admin">Admin</option>
                <option value="partner">Partner</option>
              </CustomInput>
            </FormGroup>
            <FormGroup>
              <Label for="name">
                Name<span className="required">*</span>
              </Label>
              <Input
                type="text"
                name="name"
                id="name"
                required
                placeholder="Enter User Name"
                onChange={(e) => this.onChange(e)}
              />
            </FormGroup>
            <Button>Submit</Button>
          </Form>
        </Container>
        <ToastsContainer store={ToastsStore} />
      </>
    );
  }
}
