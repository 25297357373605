import React, { Component } from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import { Card, ListGroup } from "react-bootstrap";
import CSVDownloader from "./CSVDownloader";
import {
  ExportCouponContainer,
  CouponCodeContainer,
  ImageContainer,
} from "../Styles/base";

export default class FlippingCard extends Component {
  chipContainer = (val) => {
    return (
      <span
        className="mdl-chip"
        style={{ background: "darkgreen", color: "white" }}
      >
        <span className="mdl-chip__text"> {val}</span>
      </span>
    );
  };

  randomValue = () => {
    return Math.floor(Math.random() * 256);
  };

  render() {
    return (
      <>
        <Flippy
          flipOnHover={false} // default false
          flipOnClick={true} // default false
          flipDirection="horizontal" // horizontal or vertical
          ref={(r) => (this.flippy = r)} // to use toggle method like this.flippy.toggle()
          // if you pass isFlipped prop component will be controlled component.
          // and other props, which will go to div
          style={{ height: "50vh" }} /// these are optional style, it is not necessary
        >
          <FrontSide
            style={{
              background: `rgb(${this.randomValue()}, ${this.randomValue()}, ${this.randomValue()})`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {this.props.couponType === "generic" ? (
              this.props.coupons.map((v) => {
                return (
                  <div>
                    <CouponCodeContainer>
                      <ImageContainer src={this.props.company_logo} />
                      {v.coupon_code}
                    </CouponCodeContainer>
                    <div style={{ padding: "2px" }}>
                      <i>Click here for Coupon Details</i>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <div style={{ textAlign: "center" }}>
                  <ImageContainer src={this.props.company_logo} />
                </div>
                <p>
                  <i>To Download Click Below</i>
                </p>
                <ExportCouponContainer>
                  <CSVDownloader
                    data={this.props.coupons}
                    company_name={this.props.company_name}
                  />
                </ExportCouponContainer>
              </div>
            )}
          </FrontSide>
          <BackSide style={{ display: "inline-table" }}>
            {this.props.couponType === "generic" ? (
              this.props.coupons.map((v) => {
                return (
                  <>
                    <Card>
                      <Card.Header>Coupon Details</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          <b>Id: </b>
                          <p style={{ whiteSpace: "pre-wrap" }}>{v.id}</p>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>Coupon Code:</b>
                          {this.chipContainer(v.coupon_code)}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>Created At: </b>
                          {v.created}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>Updated At: </b>
                          {v.updated}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>Allocated: </b>
                          {v.is_allocated}
                        </ListGroup.Item>
                        {/* <ListGroup.Item><b>Refunded: </b>{v.is_refunded}</ListGroup.Item> */}
                      </ListGroup>
                    </Card>
                  </>
                );
              })
            ) : (
              <p style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                Click here to flip and Please Download the sheet to view Unique
                Coupons.
              </p>
            )}
          </BackSide>
        </Flippy>
      </>
    );
  }
}
