import React, { Component } from "react";
import { Button, Row, Col } from "reactstrap";
import DataTable from "../Tables/DataTable";
import { Link } from "react-router-dom";
import DataCard from "../Cards/DataCard";
import Header from "../Header";
import { ViewTypeContainer } from "../../Styles/base";
import { ToastsStore, ToastsContainer } from "react-toasts";
import { getItemFromLS } from "../../Utils/localStorage";
import jwt from "jwt-decode";
import Tooltip from "@material-ui/core/Tooltip";
import GridOnIcon from "@material-ui/icons/GridOn";
import ViewListIcon from "@material-ui/icons/ViewList";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import PartnerHome from "./PartnerHome";
import { APIS } from "../../Constants/endpoints";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { notPartner, getToken } from "../../Functions/userAuth";
import { ServiceRouter } from "../../Utils/ServiceRouter";
import HomeCard from "../Cards/HomeCard";
import "./Home.scss";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridView: true,
      searchText: "",
    };
  }

  handleListView = () => {
    this.setState({ gridView: false });
  };

  handleGridView = () => {
    this.setState({ gridView: true });
  };

  handleSearch = (e) => {
    this.setState({
      searchText: e.target.value.toLowerCase(),
    });
  };

  render() {
    let userTypes = jwt(getToken()).allowedServices;
    var services = ServiceRouter.filter((val) => userTypes.includes(val.value));
    return (
      <>
        <Header type="mainHeader" headerText="Admin Home" />
        <div className="admin-home-main-container">
          {services.map((v) => {
            return <HomeCard details={v} />;
          })}
        </div>
        {/* <div style={{ overflow: "hidden" }}>
          <Header headerText="Reward Partner Dashboard" type="mainHeader" />
          <Container>
            { notPartner() ?
            <>
            <Row>
              <Col style={{ margin: "2rem 0" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Grid container spacing={1} alignItems="flex-end">
                    <Grid item>
                      <SearchIcon />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        fullWidth
                        label="Search"
                        onChange={(e) => this.handleSearch(e)}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Col>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                }}
              >
                <ViewTypeContainer
                  gridView={this.state.gridView}
                  onClick={this.handleListView}
                >
                  <Tooltip title="List View">
                    <ViewListIcon />
                  </Tooltip>
                </ViewTypeContainer>
                <ViewTypeContainer
                  gridView={this.state.gridView}
                  onClick={this.handleGridView}
                >
                  <Tooltip title="Grid View">
                    <GridOnIcon />
                  </Tooltip>
                </ViewTypeContainer>

                {notPartner() ? (
                  <>
                  <Link to="/add-partner" target="_blank">
                    <Button
                      color="secondary"
                      style={{marginRight: "15px"}}
                    >
                      Add New Partner
                    </Button>
                  </Link>
                  <Link to="/redemption-report" target="_blank">
                    <Button
                      color="secondary"
                      style={{marginRight: "15px"}}
                    >
                      Redemption Report
                    </Button>
                  </Link>
                  </>
                ) : null}
              </Col>
            </Row>
            <Row>
                <div style={{'overflow': 'hidden'}}>
                <div className = "fadeIn" style={{display: `${this.state.gridView ? 'block': 'none'}`}}>
                  <DataCard searchText={this.state.searchText} />
                </div>
                <div className = "fadeIn" style={{display: `${!this.state.gridView ? 'block': 'none'}`}}>
                <DataTable searchText={this.state.searchText} />
                </div>
                </div>
            </Row>
            </>
            : <PartnerHome /> }
          </Container>
        </div>
        <ToastsContainer store={ToastsStore} /> */}
      </>
    );
  }
}

export default Home;
