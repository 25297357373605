import React, { useState } from "react";
import { ToastsContainer, ToastsStore } from "react-toasts";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Alert,
} from "reactstrap";
import jwt from "jwt-decode";
import { Redirect } from "react-router-dom";
import { APIS } from "../../Constants/endpoints";
import { useAuth } from "../../Contexts/Auth";
import Header from "../Header";
import { getItemFromLS } from "../../Utils/localStorage";

function Login(props) {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user_email, setUser_email] = useState("");
  const [user_password, setUser_password] = useState("");
  const [visible, setVisible] = useState(false);
  const { setAuthTokens } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(APIS._login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
      body: JSON.stringify({
        user_email,
        user_password,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.token !== undefined) {
          setAuthTokens(res.token);
          setLoggedIn(true);
          ToastsStore.success("Logged In successfully");
        } else {
          setVisible(true);
          ToastsStore.error("Log In Failed");
        }
      })
      .catch((err) => {
        setVisible(true);
        ToastsStore.error("Log In Failed");
      });
  };

  const onDismiss = () => setVisible(false);
  // props.location.state.referer ||
  const referer = "/";
  const userType =
    getItemFromLS("token") && jwt(getItemFromLS("token")).userType;
  if (isLoggedIn) {
    if (userType === "partner") {
      return <Redirect to="/" />;
    }
    return <Redirect to={referer} />;
  }

  return (
    <>
      <Header type="loginHeader" headerText="Admin Dashboard" />
      <Container className="themed-container">
        <Row style={{ marginTop: "20px" }}>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <Alert color="danger" isOpen={visible} toggle={onDismiss}>
              <h4 className="alert-heading">Log in Failed</h4>
              <p>Incorrect Email or Password</p>
            </Alert>
            <Form
              onSubmit={handleSubmit}
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <FormGroup row>
                <Label for="user_email" sm={4}>
                  Email Address:{" "}
                </Label>
                <Col sm={8}>
                  <Input
                    autoFocus
                    type="email"
                    name="user_email"
                    id="user_email"
                    required
                    placeholder="Enter Email Address"
                    onChange={(e) => setUser_email(e.target.value)}
                  />
                  {/* <FormFeedback>Oh! This is not a valid Email Address</FormFeedback> */}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="user_password" sm={4}>
                  Password:{" "}
                </Label>
                <Col sm={8}>
                  <Input
                    type="password"
                    name="user_password"
                    id="user_password"
                    required
                    placeholder="Enter Password"
                    onChange={(e) => setUser_password(e.target.value)}
                  />
                </Col>
              </FormGroup>
              <Button color="info">Login</Button>
            </Form>
            <Alert color="info">
              <b> Please Note: </b>
              <i>
                in case you forgot the credential or do not have an account,
                kindly please contact to admin.
              </i>
            </Alert>
          </Col>
        </Row>
      </Container>
      <ToastsContainer store={ToastsStore} />
    </>
  );
}

export default Login;
