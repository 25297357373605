import React, { Component } from "react";
import { SpaceEvenlyFlex } from "../../Styles/base";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { APIS } from "../../Constants/endpoints";
import { getItemFromLS } from "../../Utils/localStorage";

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      searchText: props.searchText,
    };
  }

  getItems() {
    let token = getItemFromLS("token");
    fetch(APIS._getBrands, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((items) => this.setState({ items }))
      .catch((err) => console.log(err));
  }

  addItemToState = (item) => {
    this.setState((prevState) => ({
      items: [...prevState.items, item],
    }));
  };

  updateState = (item) => {
    const itemIndex = this.state.items.findIndex((data) => data.id === item.id);
    const newArray = [
      // destructure all items from beginning to the indexed item
      ...this.state.items.slice(0, itemIndex),
      // add the updated item to the array
      item,
      // add the rest of the items to the array from the index after the replaced item
      ...this.state.items.slice(itemIndex + 1),
    ];
    this.setState({ items: newArray });
  };

  // deleteItemFromState = (id) => {
  //   const updatedItems = this.state.items.filter(item => item.id !== id)
  //   this.setState({ items: updatedItems })
  // }

  componentDidMount() {
    this.getItems();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchText !== this.props.searchText) {
      this.setState({ searchText: this.props.searchText });
    }
  }

  handleSearch = (e) => {
    this.setState({
      searchText: e.target.value(),
    });
  };

  columns = [
    {
      dataField: "id",
      text: "Brand Id",
      hidden: true,
    },
    {
      dataField: "company_logo",
      text: "Company Logo",
      formatter: (cell, row, rowIndex, extraData) => (
        <img
          style={{ height: "auto", maxWidth: "50%" }}
          alt={row.name}
          title={row.name}
          src={row.company_logo}
        />
      ),
    },
    {
      dataField: "name",
      text: "Company Name",
      sort: true,
    },
    {
      dataField: "active",
      text: "Live Offers",
    },
    {
      dataField: "inactive",
      text: "Inactive Offers",
    },

    // {
    //     dataField: 'status',
    //     text: 'Status',
    //     sort: true,
    //     style: (cell, row, rowIndex, colIndex) => {
    //         if (row.status === "Active") {
    //           return {
    //             color: '#00ff00'
    //           };
    //         }
    //         return {
    //           color: '#ff0000'
    //         };
    //       }
    // },
    {
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          <SpaceEvenlyFlex>
            <Link
              to={{
                pathname: "/edit-partner",
                state: { data: row },
              }}
              style={{ margin: "5px" }}
            >
              <button className="btn btn-warning">Edit Details</button>
            </Link>
            <Link
              to={{
                pathname: "/partner-details",
                state: { data: row },
              }}
              style={{ margin: "5px" }}
            >
              <button className="btn btn-info">More Details</button>
            </Link>
            <Link
              to={{
                pathname: "/view-offers",
                state: {
                  name: row.name,
                  company_logo: row.company_logo,
                  id: row.id,
                  country: row.country,
                },
              }}
              style={{ margin: "5px" }}
            >
              <button className="btn btn-primary">View Offers</button>
            </Link>
          </SpaceEvenlyFlex>
        </>
      ),
    },
  ];

  // expandRow = {
  //       showExpandColumn: true,
  //       renderer: row =>  <div>
  //                           <ViewOfferDetails offer={row.offer}/>
  //                         </div>,
  //         onExpand: (row, isExpand, rowIndex, e) => {
  //           // ...
  //         }
  //       };

  selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    clickToExpand: true,
    style: { backgroundColor: "#c8e6c9" },
  };

  render() {
    const { searchText } = this.state;
    const filtered = this.state.items.filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()),
    );

    return (
      <>
        <div style={{ margin: "0px 25px" }}>
          <ToolkitProvider
            keyField="id"
            data={filtered}
            columns={this.columns}
            search
          >
            {(props) => (
              <div>
                <BootstrapTable
                  {...props.baseProps}
                  // expandRow={this.expandRow}
                  bordered
                  noDataIndication="No Record Found"
                  headerClasses="headerPartnerList"
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </>
    );
  }
}

export default DataTable;
