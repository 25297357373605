import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  CustomInput,
  Input,
  Label,
  Button,
  FormText,
} from "reactstrap";
import ImageUploader from "react-images-upload";
import Header from "../Header";
import { getItemFromLS } from "../../Utils/localStorage";
import { APIS } from "../../Constants/endpoints";
import { ToastsStore, ToastsContainer } from "react-toasts";
import Caraousal from "../Common/Caraousal";
import { CompanyLogoContainer, UploadButtonContainer } from "../../Styles/base";
import jwt from "jwt-decode";
import { isAdmin, getToken } from "../../Functions/userAuth";

export default class EditPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      countries: [],
      is_deleted: "",
      country: "",
      user_emails: [],
      user_email: "",
      old_user_email: "",
      company_logo: "",
      brand_images: "",
      cLPictures: [],
      bIPictures: [],
      isCompanyLogoUploaded: false,
      isBrandImagesUploaded: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onCLDrop = (picture) => {
    this.state.cLPictures.splice(0, 1);
    this.setState({
      cLPictures: this.state.cLPictures.concat(picture),
    });
  };

  onBIDrop = (picture) => {
    this.state.bIPictures.splice(0, 1);
    this.setState({
      bIPictures: this.state.bIPictures.concat(picture),
    });
  };

  componentDidMount() {
    if (this.props.location.state.data) {
      const { id, name, is_deleted, country, company_logo, brand_images } =
        this.props.location.state.data;
      this.setState(
        { id, name, country, is_deleted, company_logo, brand_images },
        () => {
          this.getUserEmail(this.state.id);
        },
      );
    }

    this.getCountryList();
  }

  handleEditPartner = (e) => {
    e.preventDefault();
    let token = getItemFromLS("token");
    fetch(APIS._editBrand, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
      body: JSON.stringify({
        id: this.state.id,
        name: this.state.name,
        device_type: "All",
        country: this.state.country,
        is_deleted: this.state.is_deleted,
        old_user_email: this.state.old_user_email,
        new_user_email: this.state.user_email,
        company_logo: this.state.company_logo,
        brand_images: [this.state.brand_images],
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        ToastsStore.success("Brand Details Updated Successfully");
        this.props.history.push("/");
      })
      .catch((err) => {
        ToastsStore.error("Update Brand Details Failed");
      });
  };

  handleUploadCompanyLogo = () => {
    if (this.state.cLPictures[0]) {
      const data = new FormData();
      data.append("image", this.state.cLPictures[0]);
      const _URL = window.URL || window.webkitURL;
      var img = new Image();
      img.src = _URL.createObjectURL(this.state.cLPictures[0]);
      img.onload = () => {
        if (img.width === img.height) {
          fetch(APIS._uploadImage, {
            method: "POST",
            headers: {
              "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
            },
            body: data,
          })
            .then((response) => response.json())
            .then((res) => {
              this.setState({ company_logo: res.url });
              this.setState({ isCompanyLogoUploaded: true });
              ToastsStore.success("Images Uploaded successfully");
            })
            .catch((err) => {
              ToastsStore.error("Image Upload Failed");
            });
        } else {
          ToastsStore.error(`Image Aspect Ratio must be 1:1`);
        }
      };
    } else {
      ToastsStore.error("Please Upload an Image");
    }
  };

  handleUploadBrandImages = () => {
    if (this.state.bIPictures[0]) {
      const data = new FormData();
      data.append("image", this.state.bIPictures[0]);
      const _URL = window.URL || window.webkitURL;
      var img = new Image();
      img.src = _URL.createObjectURL(this.state.bIPictures[0]);
      img.onload = () => {
        if (img.width === img.height) {
          fetch(APIS._uploadImage, {
            method: "POST",
            headers: {
              "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
            },
            body: data,
          })
            .then((response) => response.json())
            .then((res) => {
              this.setState({ brand_images: [res.url] });
              this.setState({ isBrandImagesUploaded: true });
              ToastsStore.success("Images Uploaded successfully");
            })
            .catch((err) => {
              ToastsStore.error("Image Upload Failed");
            });
        } else {
          ToastsStore.error(`Image Aspect Ratio must be 1:1`);
        }
      };
    } else {
      ToastsStore.error("Please Upload an Image");
    }
  };

  //Get all user email addresses
  getUserEmail = (brand_id) => {
    let param = isAdmin() ? `?brand_id=${brand_id}` : "";
    fetch(`${APIS._getUserEmails}${param}`, {
      method: "GET",
      headers: {
        "Content-Type": "appliation/json",
        Authorization: `Bearer ${getToken()}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          this.setState({ user_emails: res.map((v) => v) });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCountryList = () => {
    fetch(APIS._getCountryList, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) this.setState({ countries: res });
      })
      .catch((err) => {
        ToastsStore.error(err);
      });
  };

  render() {
    return (
      <>
        <Header headerText="Edit Partner" type="mainHeader" />
        <Container>
          <Form
            onSubmit={this.handleEditPartner}
            style={{
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "20px",
              margin: "10px",
            }}
          >
            <FormGroup>
              <Label for="name">
                Company Name<span className="required">*</span>
              </Label>
              <Input
                type="text"
                name="name"
                id="name"
                required
                maxlength="25"
                onChange={this.onChange}
                value={this.state.name || ""}
              />
              <FormText>Limit the text to maximum 12 characters.</FormText>
            </FormGroup>
            <FormGroup>
              <Label for="country">
                Country<span className="required">*</span>
              </Label>
              <CustomInput
                type="select"
                name="country"
                id="country"
                required
                placeholder="Select Country"
                onChange={(e) => this.onChange(e)}
              >
                <option value="">Select Country</option>
                {this.state.countries.map((v) => {
                  return (
                    <option
                      selected={this.state.country === v.name}
                      value={v.name}
                    >
                      {v.name}
                    </option>
                  );
                })}
              </CustomInput>
            </FormGroup>
            <FormGroup>
              <Label for="is_deleted">
                Status<span className="required">*</span>
              </Label>
              <Input
                type="select"
                name="is_deleted"
                id="is_deleted"
                required
                onChange={this.onChange}
                disabled={isAdmin() ? false : true}
              >
                <option
                  selected={this.state.is_deleted === false}
                  value="false"
                >
                  Active
                </option>
                <option selected={this.state.is_deleted === true} value="true">
                  Disable
                </option>
              </Input>
            </FormGroup>
            <Row
              form
              style={{
                border: "1px solid gainsboro",
                marginBottom: "10px",
                padding: "10px",
              }}
            >
              <Col>
                <FormGroup>
                  <Label for="old_user_email">
                    Select Email Address To Edit
                  </Label>
                  <CustomInput
                    type="select"
                    name="old_user_email"
                    id="old_user_email"
                    onChange={this.onChange}
                  >
                    <option value="">Select Email Address</option>
                    {this.state.user_emails.map((v) => {
                      return <option value={v}>{v}</option>;
                    })}
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="user_email">New Email Address</Label>
                  <Input
                    type="email"
                    name="user_email"
                    id="user_email"
                    disabled={this.state.old_user_email === ""}
                    required={true}
                    onChange={this.onChange}
                    value={this.state.user_email}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup row>
              <Label for="company_logo">Company Logo</Label>
              <Col md={12}>
                <Input
                  type="text"
                  name="company_logo"
                  id="company_logo"
                  onChange={this.onChange}
                  value={this.state.company_logo}
                />
              </Col>
              <Col md={3}>
                <CompanyLogoContainer src={this.state.company_logo} />
              </Col>
              <Col md={7}>
                <ImageUploader
                  withIcon={true}
                  buttonText="Choose images"
                  onChange={this.onCLDrop}
                  withPreview={true}
                  singleImage={true}
                  imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                  maxFileSize={5242880}
                  fileSizeError="File size is too big"
                />
              </Col>
              <Col
                md={2}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  id="companyLogo"
                  color="warning"
                  onClick={this.handleUploadCompanyLogo}
                >
                  {this.state.isCompanyLogoUploaded ? "Upload Again" : "Upload"}
                </Button>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="brand_images">Brand Images</Label>
              <Input
                type="text"
                name="brand_images"
                id="brand_images"
                onChange={this.onChange}
                value={this.state.brand_images}
              />
              <Col md={4}>
                <Caraousal images={this.state} />
              </Col>
              <Col md={6}>
                <ImageUploader
                  withIcon={true}
                  buttonText="Choose images"
                  onChange={this.onBIDrop}
                  withPreview={true}
                  singleImage={true}
                  imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                  maxFileSize={5242880}
                  fileSizeError="File size is too big"
                />
              </Col>
              <Col
                md={2}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  id="brandImages"
                  color="warning"
                  onClick={this.handleUploadBrandImages}
                >
                  {this.state.isBrandImagesUploaded ? "Upload Again" : "Upload"}
                </Button>
              </Col>
            </FormGroup>
            <Button>Submit</Button>
          </Form>
        </Container>
        <ToastsContainer store={ToastsStore} />
      </>
    );
  }
}
