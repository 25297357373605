import React, { useState, useEffect } from "react";
import Header from "../../Header";
import { BIDDING_APIS } from "../../../Constants/endpoints";
import { getToken } from "../../../Functions/userAuth";
import BiddingHeader from "./BiddingHeader";
import BiddingCard from "../../Cards/BiddingCard";
import Loader from "../../Common/Loader";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { TextField } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import "../../Common/flex.scss";
import { URL } from "../../../config";
import useInfiniteScroll from "../../../Utils/hooks/useInfiniteScroll";
import axios from "axios";

let items = 10;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    background: "white",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  select: {
    minWidth: "15vw",
  },
}));

const filterTypes = [
  {
    value: "eventId",
    label: "Event Id",
  },
  {
    value: "eventName",
    label: "Event Name",
  },
  {
    value: "status",
    label: "Event Status",
  },
];

const Bidding = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const [isFilter, setFilter] = useState(true);
  const [removeFilter, setRemoveFilter] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [isFilterTypeError, setFilterTypeError] = useState(false);
  const [isFilterValueError, setFilterValueError] = useState(false);

  const onFilterValueChange = (e) => {
    setFilterValueError(false);
    setFilterValue(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (!filterType) return setFilterTypeError(true);

    if (!filterValue) return setFilterValueError(true);

    setRemoveFilter(true);
    setLoading(true);

    axios
      .get(
        `${BIDDING_APIS._getFilteredBiddingEvents}?filterType=${filterType}&filterValue=${filterValue}&page=1&items=1000`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
          },
        },
      )
      .then((res) => {
        let _data = res && res.data && res.data.data && res.data.data.data;
        if (_data) {
          setData(_data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const onFilterTypeChange = (e) => {
    setFilterTypeError(false);
    setFilterType(e.target.value);
  };

  const handleRemoveFilter = () => {
    setFilter(false);
    setRemoveFilter(false);
  };

  const getAllBiddingEvents = () => {
    setLoading(true);
    fetch(`${BIDDING_APIS._getAllBiddingEvents}?page=${page}&items=${items}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${getToken()}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        let _data = res && res.data && res.data.data;
        if (_data) {
          _data = data.concat(_data);
          setData(_data);
        } else {
          setLoading(false);
          setStopScroll(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setStopScroll(true);
        console.log(err);
      });
    setIsFetching(false);
  };

  useEffect(() => {
    getAllBiddingEvents();
    setPage(page + 1);
  }, [isFilter]);

  const [isFetching, setIsFetching, setStopScroll] = useInfiniteScroll(() => {
    getAllBiddingEvents();
    setPage(page + 1);
  });

  return (
    <>
      <Header headerText="Bidding Home" type="mainHeader" />
      <BiddingHeader />
      <hr />
      <div className="flex flex-justify-center flex-align-center">
        <div component="form" className={classes.root}>
          <TextField
            select
            // label="Select Filter Type"
            onChange={onFilterTypeChange}
            variant="outlined"
            className={classes.select}
            error={isFilterTypeError}
            SelectProps={{
              native: true,
            }}
            helperText={isFilterTypeError && "Please Select Filter Type"}
          >
            <option value="">Select Filter Type</option>
            {filterTypes.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>

          <TextField
            className={classes.input}
            placeholder="Enter Filter Value"
            variant="outlined"
            label="Filter Value"
            onChange={onFilterValueChange}
            error={isFilterValueError}
            helperText={isFilterValueError && "Please Provide Filter Value"}
          />
          <IconButton
            onClick={handleSearch}
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
          {removeFilter && (
            <>
              <IconButton
                onClick={handleRemoveFilter}
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <HighlightOffIcon />
              </IconButton>
              Remove Filter
            </>
          )}
        </div>
      </div>
      <BiddingCard items={data} />
      {(isLoading || isFetching) && (
        <div className="flex flex-column flex-justify-center flex-align-center">
          <Loader />
          <p>Please wait, loading events...</p>
        </div>
      )}
    </>
  );
};

export default Bidding;
