import React, { useEffect, useState } from "react";
import {
  TextField,
  FormGroup,
  Button,
  Backdrop,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { MZAALOUSER_APIS } from "../../../Constants/endpoints";
import { successToast, errorToast } from "../../../Containers/toastFunctions";
import { URL } from "../../../config";
import AnalyticCircle from "./AnalyticCircle";
import { Link } from "react-router-dom";
import CampusAmbassadorTableData from "./CampusAmbassadorTableData";
import { ToastContainer } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    overflow: "scroll",
  },
}));

const DownloadTracking = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [details, setDetails] = useState();
  const [csvFile, setCsvFile] = useState();
  const [backdropOpen, setBackdropOpen] = useState(false);

  const onFileUpload = (e) => {
    e.preventDefault();

    if (!e.target.files[0]) {
      return errorToast("Please upload a file");
    }

    if (e.target.files[0].name.split(".").pop() !== "csv") {
      return errorToast("Please upload csv file only");
    }

    setCsvFile(e.target.files[0]);
    setLoading(true);

    const data = new FormData();
    data.append("csv", e.target.files[0]);

    axios
      .post(MZAALOUSER_APIS._getDownloadTrackingCount, data, {
        headers: {
          Authorization: URL.user_token,
          "Ocp-Apim-Subscription-Key": URL.mzaaloAdmin_ocp_key,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res && res.data && res.data.success) {
          setShowData(true);
          successToast(res.message);
          setDetails(res?.data?.data?.details);
          setData(res?.data?.data?.data);
          setCsvFile();
        } else {
          errorToast(res.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        setCsvFile();
        errorToast(err);
      });
  };

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const handleBackdropToggle = () => {
    setBackdropOpen(true);
  };

  return (
    <div className="download-tracking flex flex-column">
      <div className="download-tracking-form">
        <input
          accept=".csv"
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          type="file"
          onChange={onFileUpload}
        />
        <label htmlFor="contained-button-file">
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            component="span"
          >
            Upload CSV File
          </Button>
        </label>
      </div>
      <div className="download-tracking-details">
        {showData && (
          <div className="flex flex-wrap">
            <AnalyticCircle>
              <div className="circle-title">Total Rows:</div>
              <div className="circle-value">{data.length}</div>
            </AnalyticCircle>
            <AnalyticCircle>
              <div className="circle-title">Total Count:</div>
              <div className="circle-value">{details?.totalCount}</div>
            </AnalyticCircle>
            <AnalyticCircle>
              <div className="circle-title">Referral With ZERO Count:</div>
              <div className="circle-value">{details?.zeroCount}</div>
            </AnalyticCircle>
            <AnalyticCircle>
              <div className="circle-title">Highest Download Count:</div>
              <div className="circle-value">{details?.highestCount}</div>
            </AnalyticCircle>
            <AnalyticCircle>
              <div className="circle-title">See Table Data</div>
              <div
                className="circle-value cursor-pointer"
                onClick={handleBackdropToggle}
              >
                Click Here
              </div>
            </AnalyticCircle>
            <AnalyticCircle>
              <div className="circle-title">Get CSV</div>
              <div className="circle-value">
                <a href={details?.csvUrl}>Click Here</a>
              </div>
            </AnalyticCircle>
          </div>
        )}
      </div>
      <Backdrop
        className={`${classes.backdrop} flexbox-center flex-column`}
        open={backdropOpen}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleBackdropClose}
        >
          Close
        </Button>
        <CampusAmbassadorTableData data={data} />
      </Backdrop>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </div>
  );
};

export default DownloadTracking;
