import React, { useEffect, useState } from "react";
import { PROMOCODE_APIS } from "../../../Constants/endpoints";
import { getToken } from "../../../Functions/userAuth";
import axios from "axios";
import MaterialTable from "material-table";
import { URL } from "../../../config";
import Header from "../../Header";
import PromoCodeCard from "./PromoCodeCard";
import useInfiniteScroll from "../../../Utils/hooks/useInfiniteScroll";
import Loader from "../../Common/Loader";
import PromoNavPanel from "./PromoNavPanel";

let items = 10;

const ViewPromoCode = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const getPromoCodeList = () => {
    setLoading(true);
    axios
      .get(`${PROMOCODE_APIS._getPromoCodeList}?page=${page}&items=${items}`, {
        headers: {
          authorization: `Bearer ${getToken("token")}`,
          "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
        },
      })
      .then((res) => {
        let _data = res && res.data && res.data.data && res.data.data.data;
        if (_data.length > 0) {
          _data = data.concat(_data);
          setData(_data);
        } else {
          setLoading(false);
          setStopScroll(true);
          setIsFetching(false);
        }
        _data = null;
      })
      .catch((err) => {
        setLoading(false);
        setStopScroll(true);
        console.log(err);
      });
    setIsFetching(false);
  };

  useEffect(() => {
    getPromoCodeList();
    setPage(page + 1);
  }, []);

  const [isFetching, setIsFetching, setStopScroll] = useInfiniteScroll(() => {
    getPromoCodeList();
    setPage(page + 1);
  });

  return (
    <>
      <Header type="mainHeader" headerText="View PromoCodes" />
      <PromoNavPanel size={30} />
      <PromoCodeCard items={data} />
      {(isLoading || isFetching) && (
        <div className="flex flex-column flex-justify-center flex-align-center">
          <Loader />
          <p>Please wait, loading events...</p>
        </div>
      )}
    </>
  );
};

export default ViewPromoCode;
