import Bidding from "../../Components/Services/Bidding";
import CreateBiddingEvent from "../../Components/Services/Bidding/BiddingEvent/CreateBiddingEvent";
import EditBiddingEvent from "../../Components/Services/Bidding/BiddingEvent/EditBiddingEvent";
import RewardPartner from "../../Components/Services/RewardPartner";
import ViewBiddingEvent from "../../Components/Services/Bidding/BiddingEvent/ViewBiddingEvent";
import LeaderBoard from "../../Components/Services/Bidding/LeaderBoard";
import Analytics from "../../Components/Services/Bidding/Analytics";
import MzaaloSupport from "../../Components/Services/MzaaloSupport";
import PromoCode from "../../Components/Services/PromoCode";
import EditBiddingVoucher from "../../Components/Services/Bidding/BiddingEvent/EditBiddingVoucher";
import MzaaloSubscriber from "../../Components/Services/MzaaloSubscriber";
import UpdateBiddingImages from "../../Components/Services/Bidding/BiddingEvent/UpdateBiddingImages";
import MzaaloSEO from "../../Components/Services/MzaaloSEO";
import CampusAmbassador from "../../Components/Services/CampusAmbassador";
import CampusAmbassadorTableData from "../../Components/Services/CampusAmbassador/CampusAmbassadorTableData";
import ViewPromoCode from "../../Components/Services/PromoCode/ViewPromoCode";
import CreatePromoCode from "../../Components/Services/PromoCode/CreatePromoCode";
import EditPromoCode from "../../Components/Services/PromoCode/EditPromoCode";
import CreatePromoPartner from "../../Components/Services/PromoCode/CreatePromoPartner";
import EditPromoPartner from "../../Components/Services/PromoCode/EditPromoPartner";
import ViewPromoCodeDetail from "../../Components/Services/PromoCode/ViewPromoCodeDetail";
import ViewPromoPartnerList from "../../Components/Services/PromoCode/ViewPromoPartnerList";
import GeneratePromoCodeReport from "../../Components/Services/PromoCode/GeneratePromoCodeReport";
import ViewCampaign from "../../Components/Services/PromoCode/ViewCampaign";
import CreateCampaign from "../../Components/Services/PromoCode/CreateCampaign";
import EditCampaign from "../../Components/Services/PromoCode/EditCampaign";
import AssociatePromoPartner from "../../Components/Services/PromoCode/AssociatePromoPartner";

export const ServiceRoutes = [
  // Reward Partner Routes
  {
    name: "Reward Partner",
    path: "/rewardpartner",
    component: RewardPartner,
  },

  //Bidding Routes
  {
    name: "Bidding",
    path: "/bidding",
    component: Bidding,
  },
  {
    path: "/bidding/create-event",
    component: CreateBiddingEvent,
  },
  {
    path: "/bidding/edit-event/:id",
    component: EditBiddingEvent,
  },
  {
    path: "/bidding/edit-voucher/:id",
    component: EditBiddingVoucher,
  },
  {
    path: "/bidding/update-images/:id",
    component: UpdateBiddingImages,
  },
  {
    path: "/bidding/view-event/:id",
    component: ViewBiddingEvent,
  },
  {
    path: "/bidding/leaderboard/:id",
    component: LeaderBoard,
  },
  {
    path: "/bidding/analytics",
    component: Analytics,
  },

  // Mzaalo Contact Us Queries
  {
    name: "Mzaalo Support",
    path: "/mzaalo/support",
    component: MzaaloSupport,
  },

  // Mzaalo Subscribers List
  {
    name: "Mzaalo Subscriber",
    path: "/mzaalo/subscriber",
    component: MzaaloSubscriber,
  },

  // PromoCode
  {
    name: "Promocode",
    path: "/promocode",
    component: PromoCode,
  },
  {
    path: "/promocode/view",
    component: ViewPromoCode,
  },
  {
    path: "/promocode/create",
    component: CreatePromoCode,
  },
  {
    path: "/promocode/edit/:id",
    component: EditPromoCode,
  },
  {
    path: "/promocode/view/:id",
    component: ViewPromoCodeDetail,
  },
  {
    path: "/promocode/partner/create",
    component: CreatePromoPartner,
  },
  {
    path: "/promocode/partner/view",
    component: ViewPromoPartnerList,
  },
  {
    path: "/promocode/partner/edit/:id",
    component: EditPromoPartner,
  },
  {
    path: "/promocode/partner/associate/:id",
    component: AssociatePromoPartner,
  },
  {
    path: "/promocode/report",
    component: GeneratePromoCodeReport,
  },
  {
    path: "/promocode/campaign/view",
    component: ViewCampaign,
  },
  {
    path: "/promocode/campaign/create",
    component: CreateCampaign,
  },
  {
    path: "/promocode/campaign/edit/:id",
    component: EditCampaign,
  },

  // Mzaalo SEO
  {
    name: "Mzaalo SEO",
    path: "/mzaalo/seo",
    component: MzaaloSEO,
  },

  // Mzaalo Campus Ambassador
  {
    name: "Mzaalo Campus Ambassador",
    path: "/mzaalo/campus-ambassador",
    component: CampusAmbassador,
  },

  {
    path: "/mzaalo/campus-ambassador/table-data",
    component: CampusAmbassadorTableData,
  },

  // Admin Controller
  {
    name: "Admin Controller",
    path: "/admin-controller",
  },
];
