import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { BIDDING_APIS } from "../../Constants/endpoints";
import { getToken } from "../../Functions/userAuth";
import { DesktopWindows } from "@material-ui/icons";
import { URL } from "../../config";

export default function DialogCard(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  const handleChangeStatus = () => {
    const body = {
      event_id: props.eventId,
      is_visible: props.isVisible,
    };
    axios
      .post(BIDDING_APIS._changeVisibilityStatus, body, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
        },
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will Show/Hide bidding event on App.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleChangeStatus} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
