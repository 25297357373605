import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import Header from "../../../Header";
import { Link } from "react-router-dom";
import BiddingHeader from "../BiddingHeader";
import axios from "axios";
import { APIS, BIDDING_APIS } from "../../../../Constants/endpoints";
import { userToken } from "../../../../Utils/helpers/links";
import "./LeaderBoard.scss";
import Loader from "../../../Common/Loader";
import MaterialTable from "material-table";
import { convertDateToString } from "../../../../Functions/dateFormats";
import { URL } from "../../../../config";
import { getToken } from "../../../../Functions/userAuth";
import LeaderBoardDetailData from "./LeaderBoardDetailData";

const LeaderBoard = (props) => {
  const [data, setData] = useState([]);
  const [eventId, setEventId] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (props.location && props.location.state && props.location.state.data) {
      setEventId(props.location.state.data.event.id);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${BIDDING_APIS._getLeaderboard}?page=1&items=1000&event_id=${eventId || props.location.state.data.event.id}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
          },
        },
      )
      .then((res) => {
        if (res) {
          const data = res && res.data && res.data.data;
          setLoading(false);
          setData(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      field: "user_id",
      title: "User Id",
      hidden: false,
      export: true,
      filtering: false,
    },
    {
      field: "username",
      title: "User Name",
      hidden: false,
      export: true,
      filtering: false,
    },
    {
      field: "bid_amount",
      title: "Bid Amount",
      hidden: false,
      export: true,
      filtering: false,
    },
    {
      field: "bid_time",
      title: "Bid Time",
      hidden: false,
      export: true,
      filtering: false,
      render: (rowData) => convertDateToString(rowData.bid_time),
    },
    {
      field: "has_user_won",
      title: "Winner Status",
      hidden: false,
      export: true,
      lookup: { true: "Winner", false: "Not Winner" },
    },
    {
      field: "isClaim",
      title: "Claim Status",
      hidden: false,
      export: true,
      lookup: { true: "Claimed", false: "Not Claimed" },
      render: (rowData) =>
        rowData.has_user_won && (
          <div
            className="leaderboard-main--claim flex flex-justify-center flex-align-center"
            style={{ background: `${rowData.has_claimed ? "green" : "red"}` }}
          >
            {rowData.has_claimed ? "Claimed" : "Not Claimed"}
          </div>
        ),
    },
    {
      field: "action",
      title: "Actions",
      hidden: false,
      export: true,
      filtering: false,
      render: (rowData) =>
        rowData.has_user_won && (
          <Button disabled={rowData.has_claimed} color="primary">
            Send Notification
          </Button>
        ),
    },
    // {
    //     field:'firstName',
    //     title:'First Name',
    //     hidden: false,
    //     export: true,
    //     filtering: false,
    // },
    // {
    //     field:'lastName',
    //     title:'Last Name',
    //     hidden: false,
    //     export: true,
    //     filtering: false,
    // },
    // {
    //     field:'email',
    //     title:'Email',
    //     hidden: false,
    //     export: true,
    //     filtering: false,
    // },
    // {
    //     field:'phone',
    //     title:'Phone Number',
    //     hidden: false,
    //     export: true,
    //     filtering: false,
    // },
  ];

  return (
    <>
      <Header headerText="LeaderBoard" type="mainHeader" />
      <BiddingHeader />
      {
        <MaterialTable
          title={"Users Leaderboard"}
          columns={columns}
          data={data}
          options={{
            headerStyle: {
              backgroundColor: "#245642",
              color: "#FFF",
            },

            rowStyle: (rowData) => ({
              backgroundColor:
                rowData.has_user_won === true ? "#93eb93" : "#ffffff",
            }),

            detailPanelColumnAlignment: "left",
            search: true,
            exportButton: true,
            filtering: true,
            // selection: true,
            detailPanelType: "multiple",
            sorting: true,
            paginationType: "stepped",
            actionsColumnIndex: -1,
          }}
          detailPanel={[
            {
              icon: "keyboard_arrow_right",
              openIcon: "keyboard_arrow_down",
              tooltip: "Show User Details",
              render: (rowData) => {
                return (
                  <>
                    <LeaderBoardDetailData userData={rowData?.meta} />
                  </>
                );
              },
            },
          ]}
          // actions={[
          //         rowData => ({
          //             icon: () => <Button color={`${rowData.has_user_won === true ? 'danger' : 'info'}`}>Send Notification</Button>,
          //             // onClick: (event, rowData) => ,
          //             disabled: rowData.has_user_won === true
          //         })
          // ]}
          localization={{
            body: {
              emptyDataSourceMessage:
                data.length <= 0 && !isLoading
                  ? "No Leaderboard Found"
                  : "Refreshing LeaderBoard...",
            },
          }}
          // detailPanel={[
          //     {
          //         icon: 'keyboard_arrow_right',
          //         openIcon: 'keyboard_arrow_down',
          //         tooltip: 'Show User Query',
          //         render: rowData => {
          //             return (
          //             <div style={{padding: '10px', backgroundColor: 'darkseagreen'}}>{rowData.message}</div>
          //             )
          //         },
          //     },
          // ]}
        />
      }
    </>
  );
};

export default LeaderBoard;
