import React, { Component } from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";

export default class OfferImgVidDetails extends Component {
  randomValue = () => {
    return Math.floor(Math.random() * 256);
  };

  componentDidMount() {}

  render() {
    return (
      <>
        <Flippy
          flipOnHover={false} // default false
          flipOnClick={true} // default false
          flipDirection="horizontal" // horizontal or vertical
          ref={(r) => (this.flippy = r)} // to use toggle method like this.flippy.toggle()
          // if you pass isFlipped prop component will be controlled component.
          // and other props, which will go to div
          style={{ height: "50vh" }} /// these are optional style, it is not necessary
        >
          {this.props.offer_image && (
            <FrontSide
              style={{
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundImage: `url(${this.props.offer_image[0]})`,
              }}
            >
              <b>Offer Image</b>
            </FrontSide>
          )}{" "}
          {this.props.offer_video && (
            <BackSide
              style={{
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundImage: `url(${this.props.offer_video[0]})`,
              }}
            >
              <b>Offer Video</b>
            </BackSide>
          )}
        </Flippy>
      </>
    );
  }
}
