import React, { Component } from "react";
import Header from "../Header";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  CustomInput,
  Input,
  Alert,
  Button,
} from "reactstrap";
import { ImageContainer } from "../../Styles/base";
import { APIS } from "../../Constants/endpoints";
import { getItemFromLS } from "../../Utils/localStorage";
import jwt from "jwt-decode";
import { Link } from "react-router-dom";
import { ToastsStore, ToastsContainer } from "react-toasts";
import Caraousal from "../Common/Caraousal";
import { notPartner, getToken } from "../../Functions/userAuth";

export default class PartnerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_email: [],
      partnerData: [],
      selected_email: "",
      isSentPassword: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  //Send Password on selected email address
  handleSendPassword = (e) => {
    e.preventDefault();
    if (this.state.selected_email) {
      let token = getItemFromLS("token");
      fetch(APIS._sendPassword, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
        },
        body: JSON.stringify({
          user_email: this.state.selected_email,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          ToastsStore.success("Password Sent Successfully");
          this.setState({ isSentPassword: true });
        })
        .catch((err) => {
          ToastsStore.error("Sending Password Failed");
        });
    } else {
      ToastsStore.warning("Please select an email Id or add new");
    }
  };

  //Get all user email addresses
  getUserEmail = (brand_id) => {
    let param = notPartner() ? `?brand_id=${brand_id}` : "";
    fetch(`${APIS._getUserEmails}${param}`, {
      method: "GET",
      headers: {
        "Content-Type": "appliation/json",
        Authorization: `Bearer ${getToken()}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          this.setState({ user_email: res.map((v) => v) });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount = () => {
    this.setState(
      {
        partnerData: this.props.location.state.data,
      },
      () => {
        this.getUserEmail(this.state.partnerData.id);
      },
    );
  };

  render() {
    return (
      <>
        <Header headerText="View Partner Details" type="mainHeader" />
        <Container className="themed-container">
          <Row style={{ marginTop: "20px" }}>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <Form
                style={{
                  border: "1px solid gainsboro",
                  borderRadius: "5px",
                  padding: "20px",
                  margin: "10px",
                }}
              >
                <FormGroup row>
                  <Label sm={4}>Partner Name: </Label>
                  <Col sm={8}>{this.state.partnerData.name}</Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                  <Label sm={4}>Created At: </Label>
                  <Col sm={8}>{this.state.partnerData.created}</Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                  <Label sm={4}>Updated At: </Label>
                  <Col sm={8}>{this.state.partnerData.updated}</Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                  <Label sm={4}>No. of Active Offers</Label>
                  <Col sm={8}>{this.state.partnerData.active}</Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                  <Label sm={4}>No. of InActive Offers</Label>
                  <Col sm={8}>{this.state.partnerData.inactive}</Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                  <Label sm={4}>Registered Email Address:</Label>
                  <Col sm={6}>
                    <CustomInput
                      type="select"
                      name="selected_email"
                      onChange={(e) => this.onChange(e)}
                    >
                      <option value="">Select Email Address</option>
                      {this.state.user_email &&
                        this.state.user_email.map((v) => {
                          return <option value={v}>{v}</option>;
                        })}
                    </CustomInput>
                  </Col>
                  <Col sm={2}>
                    <Button
                      color={this.state.isSentPassword ? "success" : "primary"}
                      onClick={this.handleSendPassword}
                    >
                      {this.state.isSentPassword ? "Resend" : "Send"}
                    </Button>
                  </Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                  <Label sm={4}>Company Logo</Label>
                  <Col sm={8}>
                    <ImageContainer src={this.state.partnerData.company_logo} />
                  </Col>
                </FormGroup>
                <hr />
                <FormGroup row>
                  <Label sm={4}>Brand Images</Label>
                  <Col sm={8}>
                    <Caraousal images={this.state.partnerData} />
                  </Col>
                </FormGroup>
                <hr />
                <Link
                  to={{
                    pathname: "/edit-partner",
                    state: { data: this.state.partnerData },
                  }}
                  style={{ color: "inherit" }}
                >
                  <Button color="primary" onClick={this.handleEdit}>
                    Edit Details
                  </Button>
                </Link>
              </Form>
            </Col>
          </Row>
        </Container>
        <ToastsContainer store={ToastsStore} />
      </>
    );
  }
}
