import { URL } from "../config";

const rewardURL = URL.rewardPartner;
const biddingURL = URL.bidding;
const rewardBiddingURL = URL.reward_bidding;
const mzaaloRewardsURL = URL.mzaaloRewards;
const voucherURL = URL.voucher;
const mzaaloUserURL = URL.mzaaloUser;
const platformCoreFeederURL = URL.platformCoreFeeder;

export const APIS = {
  _login: rewardURL + "/api/v1/login",
  _getBrands: rewardURL + "/api/v1/getBrandsDetails",
  _getBrandsOffers: rewardURL + "/api/v1/getOffers",
  _addBrand: rewardURL + "/api/v1/registerBrand",
  _editBrand: rewardURL + "/api/v1/editBrandDetails",
  _addOffer: rewardURL + "/api/v1/addOffer",
  _editOffer: rewardURL + "/api/v1/editOfferDetails",
  _deleteOffer: rewardURL + "/api/v1/deleteOffer",
  _getCategory: rewardURL + "/api/v1/getCategory",
  _getCoupon: rewardURL + "/api/v1/getCoupons",
  _getAllCoupons: rewardURL + "/api/v1/getAllCoupons",
  _addCoupon: rewardURL + "/api/v1/addCoupon",
  _addCouponFromCSV: rewardURL + "/api/v1/addCouponFromCSV",
  _editCoupon: rewardURL + "/api/v1/editCouponCode",
  _changeOfferStatus: rewardURL + "/api/v1/changeOfferStatus",
  _changePassword: rewardURL + "/api/v1/changePassword",
  _getUserEmails: rewardURL + "/api/v1/getUserEmails",
  _sendPassword: rewardURL + "/api/v1/sendPassword",
  _uploadImage: rewardURL + "/api/v1/uploadImage",
  _uploadMultipleImages: rewardURL + "/api/v1/uploadMultipleImages",
  _uploadOfferCSV: rewardURL + "/api/v1/addOfferFromCSV",
  _uploadBulkImageFolder: rewardURL + "/api/v1/bulkUploadImage",
  _getBrandIds: rewardURL + "/api/v1/getBrandIds",
  _registerUser: rewardURL + "/api/v1/registerUser",
  _mapPartner: rewardURL + "/api/v1/associateUserToBrands",
  _getCountryList: rewardURL + "/api/v1/getCountryList",
  _getCityListByName: rewardURL + "/api/v1/getCityListByName",
  _removeOffer: rewardURL + "/api/v1/removeOffer",
  _newBulkRewardsUpload: rewardURL + "/api/v1/addValidatedOfferFromCSV",
  _redemptionReportData: rewardURL + "/api/v1/redemptionData",
  _getRewardPartners: rewardURL + "/api/v1/partners",
  _getRewardPartnerCategory: rewardURL + "/api/v1/get/partnercategory",
  _updateRewardCategory: rewardURL + "/api/v1/category/mapping",
};

export const BIDDING_APIS = {
  _createBiddingEvent: biddingURL + "/api/v1/createBiddingEvent",
  _updateBiddingEvent: biddingURL + "/api/v1/updateBiddingEvent",
  _getAllBiddingEvents: biddingURL + "/api/v1/getAllBiddingEvents",
  _uploadImage: biddingURL + "/api/v1/uploadImage",
  _uploadMultipleImages: biddingURL + "/api/v1/uploadMultipleImages",
  _getBiddingEventTypes: biddingURL + "/api/v1/getBiddingEventTypes",
  _createVoucherData: voucherURL + "/api/v1/create/voucheradmin",
  _getAdminVoucherList: voucherURL + "/api/v1/admin/list/voucher",
  _getWinnerList: biddingURL + "/api/v1/getWinnerList",
  _changeVisibilityStatus: biddingURL + "/api/v1/changeBiddingVisibiltyStatus",
  _getSupportQueries: biddingURL + "/api/v1/supportList",
  _getSubscribersList: biddingURL + "/api/v1/subscriberList",
  _getFilteredBiddingEvents: biddingURL + "/api/v1/getFilteredBiddingEvents",
  _updateBiddingImages: biddingURL + "/api/v1/updateBiddingImages",
  _getBiddingImages: biddingURL + "/api/v1/getBiddingImages",
  _getLeaderboard: biddingURL + "/api/v1/getLeaderboard",
};

export const MZAALOREWARDS_APIS = {
  _getCoinTypes: mzaaloRewardsURL + "/v2/rewards/get/coinType",
};

export const PROMOCODE_APIS = {
  _getPromoCodeList: rewardURL + "/api/v1/listPromocode",
  _createPromoCode: rewardURL + "/api/v1/addPromo",
  _editPromoCode: rewardURL + "/api/v1/editPromo",
  _getPromoPartnerList: rewardURL + "/api/v1/listPromoPartners",
  _createPromoPartner: rewardURL + "/api/v1/addPromoPartner",
  _editPromoPartner: rewardURL + "/api/v1/editPromoPartner",
  _generatePromoCodeReport: mzaaloUserURL + "/admin/api/v1/userPromos",
  _associatePartner: rewardURL + "/api/v1/promocode/associate/partner",
  _createCampaign: rewardURL + "/api/v1/addCampaign",
  _editCampaign: rewardURL + "/api/v1/editCampaign",
  _getCampaignList: rewardURL + "/api/v1/listCampaigns",
};

export const MZAALOUSER_APIS = {
  _getDownloadTrackingCount:
    mzaaloUserURL + "/admin/api/v1/ca-download-tracking",
};

export const PLATFORMCOREFEEDER_APIS = {
  _getCsv: platformCoreFeederURL + "/get/csv/data",
  _uploadDescriptionCsv: platformCoreFeederURL + "/update/description",
};
