import React, { useEffect, useState } from "react";
import Header from "../../../Header";
import { Card, ListGroup } from "react-bootstrap";
import BiddingHeader from "../BiddingHeader";

import "./BiddingEvent.scss";
import { convertDateToString } from "../../../../Functions/dateFormats";
import BiddingFlippingCard from "./BiddingFlippingCard";
import { BIDDING_APIS, APIS } from "../../../../Constants/endpoints";
import { getToken } from "../../../../Functions/userAuth";
import { URL } from "../../../../config";
import axios from "axios";

const ViewBiddingEvent = (props) => {
  const [data, setData] = useState();
  const [voucherData, setVoucherData] = useState([]);
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [eventId, setEventId] = useState("");
  const [winnerData, setWinnerData] = useState([]);

  useEffect(() => {
    if (props.location.state && props.location.state.data) {
      setData(props.location.state.data);
      setEventId(props.location.state.data.event.id);
    } else {
      getEventId();
    }

    getVoucherData();
    getLeaderBoardData();
    getWinnerListData();
  }, [eventId]);

  const chipContainer = (val) => {
    return (
      <span
        className="mdl-chip"
        style={{ background: "darkgreen", color: "white" }}
      >
        <span className="mdl-chip__text"> {val}</span>
      </span>
    );
  };

  const getEventId = () => {
    setEventId(window.location.pathname.split("/").pop());
  };

  const getVoucherData = () => {
    eventId &&
      fetch(`${BIDDING_APIS._getAdminVoucherList}?type_id=${eventId}`, {
        method: "GET",
        headers: {
          Authorization: `${getToken()}`,
          "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          let data = res && res.data;
          setVoucherData(data);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const getLeaderBoardData = () => {
    eventId &&
      axios
        .get(
          `${BIDDING_APIS._getLeaderboard}?page=1&items=10&event_id=${eventId}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
              "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
            },
          },
        )
        .then((res) => {
          if (res) {
            const data = res && res.data && res.data.data;
            setLeaderBoardData(data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const getWinnerListData = () => {
    eventId &&
      axios
        .get(`${BIDDING_APIS._getWinnerList}?eventId=${eventId}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
          },
        })
        .then((res) => {
          const data = res && res.data && res.data.data;
          setWinnerData(data.winners);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  return (
    <>
      <Header headerText="Bidding Event Details" type="mainHeader" />
      <BiddingHeader />
      {data && (
        <div className="flex flex-row flex-wrap flex-justify-between bidding-flipping-card-container">
          <BiddingFlippingCard item="Product Details">
            <Card>
              <Card.Header>
                <b>Product Details</b>
              </Card.Header>
              <ListGroup className="flipping-card-listgroup" variant="flush">
                <ListGroup.Item>
                  <b>Id: </b>
                  <p style={{ whiteSpace: "pre-wrap" }}>{data.product.id}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Product Title: </b>
                  {chipContainer(data.product.product_details)}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Product Image: </b>
                  <img
                    height="100px"
                    width="100px"
                    src={data.product.product_images[0]}
                    alt={data.product.product_details}
                  />
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </BiddingFlippingCard>
          <BiddingFlippingCard item="Event Details">
            <Card>
              <Card.Header>
                <b>Event Details</b>
              </Card.Header>
              <ListGroup className="flipping-card-listgroup" variant="flush">
                <ListGroup.Item>
                  <b>Id: </b>
                  <p style={{ whiteSpace: "pre-wrap" }}>{data.event.id}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Product Title: </b>
                  {data.event.event_type}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Event Start Time: </b>
                  {convertDateToString(data.event.start_time)}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Event End Time: </b>
                  {convertDateToString(data.event.end_time)}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Event Withdraw End Time: </b>
                  {convertDateToString(data.event.withdraw_end_time)}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Starting Bid: </b>
                  {data.event.starting_bid}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Priority: </b>
                  {data.event.priority}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Number of Winners: </b>
                  {data.event.number_of_winners}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Status: </b>
                  {data.event.is_active ? "Active" : "Disabled"}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Event Description: </b>
                  <span>{data.event.event_description}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Is Address: </b>
                  {data.event.is_address ? "Yes" : "No"}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Is Deleted: </b>
                  {data.event.is_deleted ? "Yes" : "No"}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Show/Hide: </b>
                  {data.event.is_visible ? "Show" : "Hide"}
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </BiddingFlippingCard>
          <BiddingFlippingCard item="Event Config Details">
            <Card>
              <Card.Header>
                <b>Event Config Details</b>
              </Card.Header>
              <ListGroup className="flipping-card-listgroup" variant="flush">
                <ListGroup.Item>
                  <b>Id: </b>
                  <p style={{ whiteSpace: "pre-wrap" }}>{data.confs.id}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Bid Time Value: </b>
                  {data.confs.bid_time_value}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Bid Time Limit: </b>
                  {data.confs.bid_time_limit}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Bid Time Coin Before: </b>
                  {data.confs.bid_time_coin_before}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Bid Time Coin After: </b>
                  {data.confs.bid_time_coin_after}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Registration Deduction: </b>
                  {data.confs.deduct_registration}
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>is Withdrawable: </b>
                  {data.confs.is_withdrawable ? "Yes" : "No"}
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </BiddingFlippingCard>
          <BiddingFlippingCard item="Voucher Details">
            <Card>
              <Card.Header>
                <b>Voucher Details</b>
              </Card.Header>
              <ListGroup className="flipping-card-listgroup" variant="flush">
                {voucherData &&
                  voucherData.map((vd) => {
                    return (
                      <>
                        <ListGroup.Item>
                          <b>Voucher Id: </b>
                          {vd.id}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>Voucher Type: </b>
                          {vd.type}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>Is Active: </b>
                          {vd.is_active ? "Yes" : "No"}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>Voucher Type Data</b>
                          {vd?.VoucherTypes.map((vt) => {
                            return (
                              <>
                                <ListGroup.Item>
                                  <b>{vt.type}</b>
                                  <ListGroup.Item>
                                    <b>Id: </b>
                                    {vt.id}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <b>Times Per User: </b>
                                    {vt.times_per_user}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <b>Limit: </b>
                                    {vt.limit}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <b>Current Limit: </b>
                                    {vt.current_limit}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <b>Offer Values: </b>
                                    {vt.offer_values.map((ov) => {
                                      return ov + ", ";
                                    })}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <b>Give Values: </b>
                                    {vt.give_values.map((gv) => {
                                      return gv + ", ";
                                    })}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <b>Offer Type: </b>
                                    {vt.offer_type}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <b>Offer Type Id: </b>
                                    {vt.offer_type_id}
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <b>Is Active: </b>
                                    {vt.is_active ? "Yes" : "No"}
                                  </ListGroup.Item>
                                </ListGroup.Item>
                              </>
                            );
                          })}
                        </ListGroup.Item>
                      </>
                    );
                  })}
              </ListGroup>
            </Card>
          </BiddingFlippingCard>
          <BiddingFlippingCard item="LeaderBoard Details">
            <Card>
              <Card.Header>
                <b>LeaderBoard Details (Top 10)</b>
              </Card.Header>
              <ListGroup className="flipping-card-listgroup" variant="flush">
                {leaderBoardData &&
                  leaderBoardData.map((lbd) => {
                    return (
                      <ListGroup.Item>
                        <b>{lbd.username}</b> {chipContainer(lbd.bid_amount)}
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Card>
          </BiddingFlippingCard>
          <BiddingFlippingCard item="Winner Details">
            <Card>
              <Card.Header>
                <b>Winner Details</b>
              </Card.Header>
              <ListGroup className="flipping-card-listgroup" variant="flush">
                {winnerData.length > 0 ? (
                  winnerData.map((wd) => {
                    return (
                      <ListGroup.Item>
                        {wd.full_name}
                        <ListGroup.Item>
                          <b>Id: </b> {wd.id}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>Full Name: </b> {wd.full_name}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>Phone: </b> {wd.phone}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>Email: </b> {wd.email}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>Has Clamied: </b> {chipContainer(wd.has_claimed)}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>PinCode: </b> {wd.pincode}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>Address: </b> {wd.address}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>ProductId: </b> {wd.product_id}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <b>userId: </b> {wd.user_id}
                        </ListGroup.Item>
                      </ListGroup.Item>
                    );
                  })
                ) : (
                  <ListGroup.Item>
                    <b>No Winner Data</b>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card>
          </BiddingFlippingCard>
        </div>
      )}
    </>
  );
};

export default ViewBiddingEvent;
