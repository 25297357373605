import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormText,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Button,
  Alert,
} from "reactstrap";
import Header from "../Header";
import { APIS } from "../../Constants/endpoints";
import { getItemFromLS } from "../../Utils/localStorage";
import { ToastsStore, ToastsContainer } from "react-toasts";
import { getToken } from "../../Functions/userAuth";
import ReactSelect from "react-select";

export default class PartnerMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      user_email: "",
      selected_brand_ids: null,
      alertVisible: true,
      showBrandsDropdown: false,
    };
  }

  handleMapPartner = (e) => {
    e.preventDefault();
    fetch(APIS._mapPartner, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
      body: JSON.stringify({
        user_email: this.state.user_email,
        brand_ids: this.state.selected_brand_ids.map((s) => s.id),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        ToastsStore.success("Mapping Successful");
        this.props.history.push("/");
      })
      .catch((err) => {
        ToastsStore.error("Mapping failed");
      });
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onMultipleSelect = (selected_brand_ids) => {
    this.setState({ selected_brand_ids });
  };

  getBrandName = (id) => {
    return this.state.items.filter((i) => i.id === id)[0].value;
  };

  fetchBrands = () => {
    let token = getToken();
    fetch(APIS._getBrands, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((items) =>
        this.setState({
          items: (items || []).map(({ id, name }) => ({
            id,
            value: name,
            label: name,
          })),
        }),
      )
      .catch((err) => console.log(err));
  };

  fetchBrandIds = () => {
    if (
      this.state.user_email.length > 5 &&
      this.state.user_email.includes("@") &&
      this.state.user_email.includes(".")
    ) {
      this.setState({ showBrandsDropdown: true });
      fetch(`${APIS._getBrandIds}?user_email=${this.state.user_email}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
        },
      })
        .then((response) => response.json())
        .then((brand_ids) =>
          this.setState({
            selected_brand_ids: (brand_ids || []).map((id) => ({
              id,
              value: this.getBrandName(id),
              label: this.getBrandName(id),
            })),
          }),
        )
        .catch((err) => {
          ToastsStore.error(err || "Something went wrong");
        });
    } else {
      ToastsStore.error("Please enter correct email address");
    }
  };

  componentDidMount() {
    this.fetchBrands();
  }

  render() {
    return (
      <>
        <Header headerText="Partner Mapping" type="mainHeader" />
        <Container style={{ marginTop: "10px" }}>
          <Alert
            color="info"
            isOpen={this.state.alertVisible}
            toggle={this.onDismiss}
          >
            All <span className="required">*</span> fields are mandatory. To
            Register a New User, Please fill all the details.
          </Alert>
          <Form
            onSubmit={this.handleMapPartner}
            style={{
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "20px",
              margin: "10px",
            }}
          >
            <FormGroup>
              <Label for="user_email">
                User Email<span className="required">*</span>
              </Label>
              <Input
                type="email"
                name="user_email"
                id="user_email"
                required
                placeholder="Enter User Email"
                onChange={(e) => this.onChange(e)}
                onBlur={this.fetchBrandIds}
              />
            </FormGroup>
            {this.state.showBrandsDropdown ? (
              <FormGroup>
                <Label for="selected_brand_ids">
                  Partners<span className="required">*</span>
                </Label>
                <ReactSelect
                  className="selectionBox"
                  placeholder="Select Partner(s)"
                  options={this.state.items}
                  onChange={this.onMultipleSelect}
                  isMulti={true}
                  value={this.state.selected_brand_ids}
                />
              </FormGroup>
            ) : null}
            <Button>Submit</Button>
          </Form>
        </Container>
        <ToastsContainer store={ToastsStore} />
      </>
    );
  }
}
