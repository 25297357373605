import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormText,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Button,
  Alert,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import Header from "../Header";
import { APIS } from "../../Constants/endpoints";
import { getItemFromLS } from "../../Utils/localStorage";
import { ToastsStore, ToastsContainer } from "react-toasts";
import ImageUploader from "react-images-upload";
import { isAdmin, getToken } from "../../Functions/userAuth";

class AddPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      country: "",
      status: "",
      user_email: "",
      user_type: "partner",
      company_logo: "",
      brand_images: "",
      countries: [],
      registered_address: "",
      alertVisible: "true",
      cLPictures: [],
      bIPictures: [],
      isCompanyLogoUploaded: false,
      isBrandImagesUploaded: false,
    };
  }

  onCLDrop = (picture) => {
    this.state.cLPictures.splice(0, 1);
    this.setState({
      cLPictures: this.state.cLPictures.concat(picture),
    });
  };

  onBIDrop = (picture) => {
    this.state.bIPictures.splice(0, 1);
    this.setState({
      bIPictures: this.state.bIPictures.concat(picture),
    });
  };

  handleAddPartner = (e) => {
    let token = getItemFromLS("token");
    e.preventDefault();
    fetch(APIS._addBrand, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
      body: JSON.stringify({
        name: this.state.name,
        device_type: "All",
        country: this.state.country,
        user_type: this.state.user_type,
        is_deleted: this.state.status === "Active" ? "false" : "true",
        user_email: this.state.user_email,
        company_logo: this.state.company_logo,
        brand_images: [this.state.brand_images],
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          ToastsStore.success("Partner Added Successfully");

          // Clear the form fields
          this.setState({
            name: "",
            country: "",
            status: "",
            user_email: "",
            company_logo: "",
            brand_images: "",
            cLPictures: [],
            bIPictures: [],
            isCompanyLogoUploaded: false,
            isBrandImagesUploaded: false,
          });

          // Add a delay before navigating
          setTimeout(() => {
            this.props.history.push("/rewardpartner");
          }, 2000); // Adjust the delay (2000 milliseconds = 2 seconds) as needed
        } else {
          ToastsStore.error(res.message || "Error adding partner");
        }
      })
      .catch((err) => {
        ToastsStore.error("Registration failed");
      });
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUploadCompanyLogo = () => {
    if (this.state.cLPictures[0]) {
      const data = new FormData();
      data.append("image", this.state.cLPictures[0]);
      const _URL = window.URL || window.webkitURL;
      var img = new Image();
      img.src = _URL.createObjectURL(this.state.cLPictures[0]);
      img.onload = () => {
        if (img.width === img.height) {
          fetch(APIS._uploadImage, {
            method: "POST",
            headers: {
              "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
            },
            body: data,
          })
            .then((response) => response.json())
            .then((res) => {
              this.setState({ company_logo: res.url });
              this.setState({ isCompanyLogoUploaded: true });
              ToastsStore.success("Images Uploaded successfully");
            })
            .catch((err) => {
              ToastsStore.error("Image Upload Failed");
            });
        } else {
          ToastsStore.error(`Image Aspect Ratio must be 1:1`);
        }
      };
    } else {
      ToastsStore.error("Please Upload an Image");
    }
  };

  handleUploadBrandImages = () => {
    if (this.state.bIPictures[0]) {
      const data = new FormData();
      data.append("image", this.state.bIPictures[0]);
      const _URL = window.URL || window.webkitURL;
      var img = new Image();
      img.src = _URL.createObjectURL(this.state.bIPictures[0]);
      img.onload = () => {
        if (img.width === img.height) {
          fetch(APIS._uploadImage, {
            method: "POST",
            headers: {
              "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
            },
            body: data,
          })
            .then((response) => response.json())
            .then((res) => {
              this.setState({ brand_images: res.url });
              this.setState({ isBrandImagesUploaded: true });
              ToastsStore.success("Images Uploaded successfully");
            })
            .catch((err) => {
              ToastsStore.error("Image Upload Failed");
            });
        } else {
          ToastsStore.error(`Image Aspect Ratio must be 1:1`);
        }
      };
    } else {
      ToastsStore.error("Please Upload an Image");
    }
  };

  getCountryList = () => {
    fetch(APIS._getCountryList, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) this.setState({ countries: res });
      })
      .catch((err) => {
        ToastsStore.error(err);
      });
  };

  componentDidMount() {
    this.getCountryList();
  }

  render() {
    return (
      <>
        <Header headerText="Add New Partner" type="mainHeader" />
        <Container style={{ marginTop: "10px" }}>
          <Alert
            color="info"
            isOpen={this.state.alertVisible}
            toggle={this.onDismiss}
          >
            All <span className="required">*</span> fields are mandatory. To
            Register a New Partner, Please fill all the details.
          </Alert>
          <Form
            onSubmit={this.handleAddPartner}
            style={{
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "20px",
              margin: "10px",
            }}
          >
            <FormGroup>
              <Label for="name">
                Company Name<span className="required">*</span>
              </Label>
              <Input
                type="text"
                name="name"
                id="name"
                required
                placeholder="Enter Company Name"
                maxLength="25"
                onChange={(e) => this.onChange(e)}
              />
              <FormText>Limit the text to maximum 12 characters.</FormText>
            </FormGroup>
            {isAdmin() ? (
              <FormGroup>
                <Label for="user_type">
                  User Type<span className="required">*</span>
                </Label>
                <CustomInput
                  type="select"
                  name="user_type"
                  id="user_type"
                  required
                  placeholder="Select User Type"
                  onChange={(e) => this.onChange(e)}
                >
                  <option value="">Select User Type</option>
                  <option value="partner">Partner</option>
                  <option value="partneradmin">Partner Admin</option>
                </CustomInput>
              </FormGroup>
            ) : null}
            <FormGroup>
              <Label for="country">
                Country<span className="required">*</span>
              </Label>
              <CustomInput
                type="select"
                name="country"
                id="country"
                required
                placeholder="Select Country"
                onChange={(e) => this.onChange(e)}
              >
                <option value="">Select Country</option>
                {this.state.countries &&
                  this.state.countries.map((v) => {
                    return <option value={v.name}>{v.name}</option>;
                  })}
              </CustomInput>
            </FormGroup>
            <FormGroup>
              <Label for="status">
                Status<span className="required">*</span>
              </Label>
              <CustomInput
                type="select"
                name="status"
                id="status"
                required
                placeholder="Select Partner Status"
                onChange={(e) => this.onChange(e)}
              >
                <option value="">Select Status</option>
                <option value="Disable">Disable</option>
                <option value="Active">Active</option>
              </CustomInput>
            </FormGroup>
            <FormGroup>
              <Label for="user_email">
                Email Address<span className="required">*</span>
              </Label>
              <Input
                type="email"
                name="user_email"
                id="user_email"
                required
                placeholder="Enter Partner Email Address"
                onChange={(e) => this.onChange(e)}
              />
            </FormGroup>
            <FormGroup row>
              <Label for="company_logo">
                Company Logo<span className="required">*</span>
              </Label>
              <Col md={12}>
                <Input
                  type="text"
                  name="company_logo"
                  id="company_logo"
                  required
                  placeholder="Upload Company Logo (Aspect Ratio 1:1)"
                  onChange={this.onChange}
                  value={this.state.company_logo}
                />
              </Col>
              <Col md={10}>
                <ImageUploader
                  withIcon={true}
                  buttonText="Choose images"
                  onChange={this.onCLDrop}
                  withPreview={true}
                  singleImage={true}
                  imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                  maxFileSize={5242880}
                  fileSizeError="File size is too big"
                />
              </Col>
              <Col
                md={2}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  id="companyLogo"
                  color="warning"
                  onClick={this.handleUploadCompanyLogo}
                >
                  {this.state.isCompanyLogoUploaded ? "Upload Again" : "Upload"}
                </Button>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="brand_images">Brand Images</Label>
              <Col md={12}>
                <Input
                  type="text"
                  name="brand_images"
                  id="brand_images"
                  placeholder="Upload Brand Images (Aspect Ratio 1:1)"
                  onChange={(e) => this.onChange(e)}
                  value={this.state.brand_images}
                />
              </Col>
              <Col md={10}>
                <ImageUploader
                  withIcon={true}
                  buttonText="Choose images"
                  onChange={this.onBIDrop}
                  withPreview={true}
                  singleImage={true}
                  imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                  maxFileSize={5242880}
                  fileSizeError="File size is too big"
                />
              </Col>
              <Col
                md={2}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  id="brandImages"
                  color="warning"
                  onClick={this.handleUploadBrandImages}
                >
                  {this.state.isBrandImagesUploaded ? "Upload Again" : "Upload"}
                </Button>
              </Col>
            </FormGroup>

            {/* TO DO remove comment for Partner documents details and some functionality */}
            {/* <FormGroup>
            <Label for="coupon_details"><b>Other Details (Optional)</b></Label>
        </FormGroup>
        <Row form style={{border: "1px solid gainsboro", marginBottom: "10px", padding: "10px"}}>
          <Col md={12}>
            <FormGroup>
            <Label for="registered_address">Registered Address</Label>
            <Input type="text" name="registered_address" id="registered_address" placeholder="Enter Partner's Registered Address" onChange={this.onChange} />
            </FormGroup>
          </Col>
          <Form>
            <Row>
          <Col md={3}>
            <FormGroup>
            <Label for="coupon_header">Document Type</Label>
            <CustomInput type="select" name="coupon_header" id="coupon_header" placeholder="Enter Coupon Header" onChange={this.onChange}>
             <option value="">Select Document Type</option>
              <option value="gst">GST</option>
              <option value="panCard">Pan Card</option>
              <option value="adharCard">Adhar Card</option>
            </CustomInput>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
            <Label for="coupon_header">Number</Label>
            <Input type="text" name="coupon_header" id="coupon_header" placeholder="Enter Coupon Header" onChange={this.onChange} />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
            <Label for="coupon_header">Choose Document</Label>
            <Input type="file" name="coupon_header" id="coupon_header" placeholder="Enter Coupon Header" onChange={this.onChange} />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
            <Label for="coupon_header">Upload</Label>
            <Input type="submit" value="Upload" name="coupon_header" id="coupon_header" placeholder="Enter Coupon Header" onChange={this.onChange} />
            </FormGroup>
          </Col>
          </Row>
          </Form>
        </Row>*/}
            <Button>Submit</Button>
          </Form>
        </Container>
        <ToastsContainer store={ToastsStore} />
      </>
    );
  }
}

export default withRouter(AddPartner);
