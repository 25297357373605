import React, { useState } from "react";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";

export default function Page1(props) {
  return (
    <>
      <Card className="cardRedeem">
        <CardActionArea>
          <CardMedia
            component="img"
            alt={props.company_name}
            height="140px"
            width="80px"
            style={{ objectFit: "contain", padding: "1rem" }}
            image={props.company_logo}
            title={props.company_name}
          />
          <CardContent style={{ background: "white", textAlign: "center" }}>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.company_name}
              <br />
              <h5>{props.title}</h5>
              <h4>{props.value}</h4>
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className="cardActions">
          <b>{props.coins} Coins</b>
        </CardActions>
      </Card>
    </>
  );
}
