import { Backdrop, makeStyles } from "@material-ui/core";
import React, { useReducer } from "react";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  FormText,
  FormGroup,
  Label,
  CustomInput,
  Button,
  Alert,
} from "reactstrap";
import { URL } from "../../../config";
import { PROMOCODE_APIS } from "../../../Constants/endpoints";
import { errorToast, successToast } from "../../../Containers/toastFunctions";
import { getToken } from "../../../Functions/userAuth";
import Header from "../../Header";
import { PromoCodeReportData } from "./PromoCodeReportData";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    overflow: "scroll",
  },

  fileLink: {
    padding: "15px",
    margin: "10px",
    background: "white",
    border: "1px dashed black",
  },
}));

const GeneratePromoCodeReport = () => {
  const classes = useStyles();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [promoCodeData, setPromoCodeData] = useReducer(reducer, {
    startDate: "",
    endDate: "",
    isCSVRequired: true,
  });
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);

  const onChange = (e) => {
    let { name, value } = e.target;

    setPromoCodeData({ [name]: value });
  };

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const handleBackdropToggle = () => {
    setBackdropOpen(true);
  };

  const handleReport = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(
      `${PROMOCODE_APIS._generatePromoCodeReport}?startDate=${promoCodeData.startDate}&endDate=${promoCodeData.endDate}&isCSVRequired=${promoCodeData.isCSVRequired}&page=1&items=10000`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${getToken()}`,
          "Ocp-Apim-Subscription-Key": URL.mzaalo_ocp_key,
        },
      },
    )
      .then((response) => response.json())
      .then(async (res) => {
        setLoading(false);
        if (res && res.success) {
          let data = res && res.data && res.data.data;
          setData(data);
          successToast(res?.message);
        } else {
          errorToast("Something went wrong");
        }
      })
      .catch((err) => {
        setLoading(false);
        errorToast("Something went wrong!");
      });
  };

  return (
    <div>
      <Header type="mainHeader" headerText="Promocode Report" />
      <div>
        <Form
          onSubmit={handleReport}
          style={{
            border: "1px solid gainsboro",
            borderRadius: "5px",
            padding: "20px",
            margin: "10px",
          }}
        >
          <FormGroup>
            <Label for="startDate">
              Start Date<span className="required">*</span>
            </Label>
            <Input
              type="date"
              name="startDate"
              id="startDate"
              required
              placeholder="Enter Start Date"
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="endDate">
              End Date<span className="required">*</span>
            </Label>
            <Input
              type="date"
              name="endDate"
              id="endDate"
              required
              placeholder="Enter End Date"
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="isCSVRequired">
              Is CSV Required?<span className="required">*</span>
            </Label>
            <CustomInput
              type="select"
              name="isCSVRequired"
              id="isCSVRequired"
              required
              placeholder="Select If CSV required"
              onChange={onChange}
            >
              <option value="">Select Option</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </CustomInput>
          </FormGroup>
          <Button color="info">Submit</Button>
        </Form>
      </div>
      {data && (
        <div>
          {data?.fileLink && (
            <div className={classes.fileLink}>
              <a href={data?.fileLink}>{data?.fileLink}</a>
            </div>
          )}

          <div className="cursor-pointer" onClick={handleBackdropToggle}>
            <Button color="success">Click Here to See Table Data </Button>
          </div>
        </div>
      )}
      <Backdrop
        className={`${classes.backdrop} flexbox-center flex-column`}
        open={backdropOpen}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleBackdropClose}
        >
          Close
        </Button>
        <PromoCodeReportData data={data?.userPromos} />
      </Backdrop>
    </div>
  );
};

export default GeneratePromoCodeReport;
