import React, { useEffect, useReducer, useState } from "react";
import Header from "../../Header";
import PromoNavPanel from "./PromoNavPanel";
import {
  Container,
  Alert,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  CustomInput,
  Button,
} from "reactstrap";
import { PROMOCODE_APIS } from "../../../Constants/endpoints";
import { getToken } from "../../../Functions/userAuth";
import { errorToast, successToast } from "../../../Containers/toastFunctions";
import { ToastContainer } from "react-toastify";
import { ISOtoDateTimeLocal } from "../../../Functions/dateFormats";
import { URL } from "../../../config";

const EditPromoCode = (props) => {
  const [alertVisible, setAlertVisible] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [partnerData, setPartnerData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [promocodeData, setPromocodeData] = useReducer(reducer, {
    promoCodeId: "",
    name: "",
    description: "",
    promoCode: "",
    count: 0,
    types: "",
    partnerId: "",
    event: "",
    startDate: "",
    endDate: "",
    isLimited: "",
    isOncePerUser: "",
    isVisible: "",
    isActive: "",
    isExpirable: "",
  });

  const onDismiss = () => {
    setAlertVisible(false);
  };

  const handleEditPromoCode = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(PROMOCODE_APIS._editPromoCode, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${getToken("token")}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
      body: JSON.stringify(promocodeData),
    })
      .then((response) => response.json())
      .then(async (res) => {
        setLoading(false);
        if (res && res.success) {
          successToast(res?.message);
        } else {
          errorToast(res?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorToast("Something went wrong!");
      });
  };

  const onChange = (e) => {
    let { name, value } = e.target;

    if (name === "event") {
      let val = `{"tokens": ${value}}`;
      setPromocodeData({ event: JSON.parse(val) });
    } else setPromocodeData({ [name]: value });
  };

  const getPartnerList = () => {
    fetch(PROMOCODE_APIS._getPromoPartnerList, {
      method: "GET",
      headers: {
        authorization: `Bearer ${getToken("token")}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.data && res.data.data) {
          setPartnerData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCampaignList = () => {
    fetch(PROMOCODE_APIS._getCampaignList, {
      method: "GET",
      headers: {
        authorization: `Bearer ${getToken("token")}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.data && res.data.data) {
          setCampaignData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (props.location && props.location.state && props.location.state.data) {
      setPromocodeData({ promoCodeId: props.location.state.data.id });
      setPromocodeData({ partnerId: props.location.state.data.promoPartnerId });
      setPromocodeData(props.location.state.data);
    }

    getPartnerList();
    getCampaignList();
  }, []);

  return (
    <>
      <Header type="mainHeader" headerText="Edit Promo Code" />
      <div className="promocode-create">
        <PromoNavPanel />
        <Container style={{ marginTop: "10px" }}>
          <Alert color="info" isOpen={alertVisible} toggle={onDismiss}>
            All <span className="required">*</span> fields are mandatory. To
            Edit PromoCode, Please fill all the details.
          </Alert>
          <Form
            onSubmit={handleEditPromoCode}
            style={{
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="promoCode">
                    Promo Code<span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="promoCode"
                    id="promoCode"
                    placeholder="Enter Promo Code"
                    required
                    maxlength="25"
                    onChange={onChange}
                    value={promocodeData?.promoCode || ""}
                  />
                  <FormText>Limit the text to maximum 25 characters.</FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="name">
                    Name<span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter Name"
                    required
                    maxlength="100"
                    onChange={onChange}
                    value={promocodeData?.name || ""}
                  />
                  <FormText>Limit the text to maximum 100 characters.</FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    type="text"
                    name="description"
                    id="description"
                    placeholder="Enter Description"
                    maxlength="100"
                    onChange={onChange}
                    value={promocodeData?.description || ""}
                  />
                  <FormText>Limit the text to maximum 100 characters.</FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="event">Tokens</Label>
                  <Input
                    type="number"
                    name="event"
                    id="event"
                    placeholder="Enter Tokens"
                    onChange={onChange}
                    value={promocodeData?.event?.tokens || ""}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="count">Count</Label>
                  <Input
                    type="number"
                    name="count"
                    id="count"
                    placeholder="Enter Count"
                    onChange={onChange}
                    value={promocodeData?.count || 0}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="startDate">
                    Start Date<span className="required">*</span>
                  </Label>
                  <Input
                    type="datetime-local"
                    name="startDate"
                    id="startDate"
                    placeholder="Enter Start Date"
                    required
                    onChange={onChange}
                    value={ISOtoDateTimeLocal(promocodeData?.startDate) || ""}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="endDate">
                    End Date<span className="required">*</span>
                  </Label>
                  <Input
                    type="datetime-local"
                    name="endDate"
                    id="endDate"
                    placeholder="Enter End Date"
                    required
                    onChange={onChange}
                    value={ISOtoDateTimeLocal(promocodeData?.endDate) || ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="partnerId">Select Partner</Label>
                  <CustomInput
                    type="select"
                    name="partnerId"
                    id="partnerId"
                    placeholder="Select Partner"
                    onChange={onChange}
                  >
                    <option value="">Select Partner</option>
                    {partnerData &&
                      partnerData.map((pd) => {
                        return (
                          <option
                            selected={pd?.id === promocodeData?.partnerId}
                            key={pd?.id}
                            value={pd?.id}
                          >
                            {pd?.partnerName}
                          </option>
                        );
                      })}
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="campaignId">
                    Select Campaign<span className="required">*</span>
                  </Label>
                  <CustomInput
                    type="select"
                    name="campaignId"
                    id="campaignId"
                    placeholder="Select Campaign"
                    required
                    onChange={onChange}
                  >
                    <option value="">Select Campaign</option>
                    {campaignData &&
                      campaignData.map((cd) => {
                        return (
                          <option
                            selected={cd?.id === promocodeData?.campaignId}
                            key={cd?.id}
                            value={cd?.id}
                          >
                            {cd?.name}
                          </option>
                        );
                      })}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="types">Types</Label>
                  <CustomInput
                    type="select"
                    name="types"
                    id="types"
                    placeholder="Select Types"
                    onChange={onChange}
                  >
                    <option value="">Select Types</option>
                    <option
                      selected={
                        promocodeData?.types.toLowerCase() === "generic"
                      }
                      value="generic"
                    >
                      Generic
                    </option>
                    <option
                      selected={promocodeData?.types.toLowerCase() === "unique"}
                      value="unique"
                    >
                      Unique
                    </option>
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="isLimited">Select Limited Status</Label>
                  <CustomInput
                    type="select"
                    name="isLimited"
                    id="isLimited"
                    placeholder="Select Limited Status"
                    onChange={onChange}
                  >
                    <option value="">Select Limited Status</option>
                    <option
                      selected={promocodeData?.isLimited === true}
                      value="true"
                    >
                      Limited
                    </option>
                    <option
                      selected={promocodeData?.isLimited === false}
                      value="false"
                    >
                      Not Limited
                    </option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="isOncePerUser">Select Once Per User Status</Label>
                  <CustomInput
                    type="select"
                    name="isOncePerUser"
                    id="isOncePerUser"
                    placeholder="Select Once Per User Status"
                    onChange={onChange}
                  >
                    <option value="">Select Once Per User Status</option>
                    <option
                      selected={promocodeData?.isOncePerUser === true}
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      selected={promocodeData?.isOncePerUser === false}
                      value="false"
                    >
                      No
                    </option>
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="isVisible">Select Visible Status</Label>
                  <CustomInput
                    type="select"
                    name="isVisible"
                    id="isVisible"
                    placeholder="Select Visible Status"
                    onChange={onChange}
                  >
                    <option value="">Select Visible Status</option>
                    <option
                      selected={promocodeData?.isVisible === true}
                      value="true"
                    >
                      Visible
                    </option>
                    <option
                      selected={promocodeData?.isVisible === false}
                      value="false"
                    >
                      Not Visible
                    </option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="isActive">Select Active Status</Label>
                  <CustomInput
                    type="select"
                    name="isActive"
                    id="isActive"
                    placeholder="Select Visible Status"
                    onChange={onChange}
                  >
                    <option value="">Select Active Status</option>
                    <option
                      selected={promocodeData?.isActive === true}
                      value="true"
                    >
                      Active
                    </option>
                    <option
                      selected={promocodeData?.isActive === false}
                      value="false"
                    >
                      Disabled
                    </option>
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="isExpirable">Select Expirable Status</Label>
                  <CustomInput
                    type="select"
                    name="isExpirable"
                    id="isExpirable"
                    placeholder="Select Expirable Status"
                    onChange={onChange}
                  >
                    <option value="">Select Expirable Status</option>
                    <option
                      selected={promocodeData?.isExpirable === true}
                      value="true"
                    >
                      Expirable
                    </option>
                    <option
                      selected={promocodeData?.isExpirable === false}
                      value="false"
                    >
                      Not Expirable
                    </option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" disabled={isLoading}>
              Submit
            </Button>
          </Form>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
};

export default EditPromoCode;
