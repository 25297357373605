import React, { Component } from "react";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
} from "reactstrap";
import Header from "./Header";
import { APIS } from "../Constants/endpoints";
import { getItemFromLS } from "../Utils/localStorage";
import { ToastsStore } from "react-toasts";
import jwt from "jwt-decode";

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_password: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangePassword = (e) => {
    let token = getItemFromLS("token");
    e.preventDefault();
    fetch(APIS._changePassword, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
      body: JSON.stringify({
        user_password: this.state.user_password,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        ToastsStore.success("Password Changed Successfully");
        const referTo =
          jwt(getItemFromLS("token")).userType === "partner"
            ? "/view-offers"
            : "/";
        this.props.history.push(referTo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <Header headerText="Change Password" type="mainHeader" />
        <Container style={{ marginTop: "10px" }}>
          <Alert color="warning">
            <i> To change the password, Please enter new password and submit</i>
          </Alert>
          <Form
            onSubmit={this.handleChangePassword}
            style={{
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "20px",
              margin: "10px",
            }}
          >
            <FormGroup>
              <Label for="user_password">
                New Password <span className="required">*</span>
              </Label>
              <Input
                type="password"
                name="user_password"
                id="user_password"
                required
                placeholder="Enter New Password"
                onChange={(e) => this.onChange(e)}
              />
            </FormGroup>
            <Button>Submit</Button>
          </Form>
        </Container>
      </>
    );
  }
}
