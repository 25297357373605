import React, { useState } from "react";
import { Link } from "react-router-dom";
import { convertDateToString } from "../../../Functions/dateFormats";

const PromoCodeCard = ({ items }) => {
  return (
    <div className="flexbox-center flex-wrap">
      {items.map((item) => {
        return (
          <div className="promocode-card" key={item?.id}>
            <Link
              className="linkDecoration"
              to={{
                pathname: `/promocode/view/${item?.id}`,
                state: { data: item },
              }}
            >
              <div className="promocode-card-main flex flex-column cursor-pointer">
                <div
                  className="promocode-card-main-top"
                  style={{
                    backgroundColor: !item?.isVisible
                      ? "gray"
                      : !item?.isActive
                        ? "#adfdad"
                        : "#2deb2d",
                  }}
                >
                  <div className="promocode-card-main-top-inner flex flex-column flex-justify-evenly">
                    <h4>{item?.promoCode}</h4>
                    <div style={{ color: "brown", fontSize: "1em" }}>
                      {item?.name}
                    </div>
                  </div>
                </div>
                <div className="promocode-card-main-bottom">
                  <div className="promocode-card-main-bottom-inner flex flex-column flex-justify-between">
                    <div className="promocode-card-main-bottom-inner-content flex flex-column">
                      <div style={{ color: "grey", fontSize: "1em" }}>
                        {item?.description}
                      </div>
                      <div style={{ color: "green", fontSize: "1em" }}>
                        <b>Start Date: </b>{" "}
                        {convertDateToString(item?.startDate)}
                      </div>
                      <div style={{ color: "red", fontSize: "1em" }}>
                        <b>End Date: </b> {convertDateToString(item?.endDate)}
                      </div>
                      <div className="promotype">{item?.types}</div>
                      <div>
                        <b>Used Count: </b>
                        {item?.usedCount}
                      </div>
                    </div>
                    <div className="promocode-card-main-bottom-inner-links flex flex-justify-between ">
                      <div className="promocode-card-main-bottom-inner-button">
                        <Link
                          to={{
                            pathname: `/promocode/edit/${item?.id}`,
                            state: { data: item },
                          }}
                        >
                          Edit
                        </Link>
                      </div>
                      <div className="promocode-card-main-bottom-inner-button">
                        {item?.PromoPartner ? (
                          <div className="highlight2">
                            {item?.PromoPartner?.partnerName}
                          </div>
                        ) : (
                          <div className="highlight2">
                            <Link
                              to={{
                                pathname: `/promocode/partner/associate/${item?.id}`,
                                state: { data: item },
                              }}
                            >
                              Add Partner
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="promocode-card-main-bottom-inner-button">
                        {item?.Campaign !== null ? (
                          <div className="highlight3">
                            {item?.Campaign?.name}
                          </div>
                        ) : (
                          <div className="highlight3">No Campaign</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default PromoCodeCard;
