import React, { Component } from "react";
import Header from "../Header";
import { Alert, Container } from "reactstrap";

export default class Error404 extends Component {
  render() {
    return (
      <>
        <Header headerText="Error 404 (Page Not Found)" type="errorHeader" />
        <Container>
          <Alert color="danger" style={{ margin: "10px" }}>
            This page does not exist. Please visit to correct URLs.
          </Alert>
        </Container>
      </>
    );
  }
}
