import React, { useReducer, useState } from "react";
import Header from "../../Header";
import PromoNavPanel from "./PromoNavPanel";
import {
  Container,
  Alert,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  CustomInput,
  Button,
} from "reactstrap";
import { PROMOCODE_APIS } from "../../../Constants/endpoints";
import { getToken } from "../../../Functions/userAuth";
import { errorToast, successToast } from "../../../Containers/toastFunctions";
import { ToastContainer } from "react-toastify";
import { URL } from "../../../config";

const CreatePromoPartner = () => {
  const [alertVisible, setAlertVisible] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [promoPartnerData, setPromoPartnerData] = useReducer(reducer, {
    partnerName: "",
    isDeleted: false,
  });

  const onDismiss = () => {
    setAlertVisible(false);
  };

  const onChange = (e) => {
    let { name, value } = e.target;

    setPromoPartnerData({ [name]: value });
  };

  const handleCreatePromoPartner = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(PROMOCODE_APIS._createPromoPartner, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${getToken("token")}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
      body: JSON.stringify(promoPartnerData),
    })
      .then((response) => response.json())
      .then(async (res) => {
        setLoading(false);
        if (res && res.success) {
          successToast(res?.message);
        } else {
          errorToast(res?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorToast("Something went wrong!");
      });
  };

  return (
    <>
      <Header type="mainHeader" headerText="Create New Promo Partner" />
      <div className="promocode-partner">
        <PromoNavPanel />
        <Container style={{ marginTop: "10px" }}>
          <Alert color="info" isOpen={alertVisible} toggle={onDismiss}>
            All <span className="required">*</span> fields are mandatory. To
            Create a New Promo Partner, Please fill all the details.
          </Alert>
          <Form
            onSubmit={handleCreatePromoPartner}
            style={{
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="partnerName">
                    Promo Partner Name<span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="partnerName"
                    id="partnerName"
                    placeholder="Enter Promo Partner Name"
                    required
                    maxlength="25"
                    onChange={onChange}
                  />
                  <FormText>Limit the text to maximum 25 characters.</FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="isDeleted">
                    Status<span className="required">*</span>
                  </Label>
                  <CustomInput
                    type="select"
                    name="isDeleted"
                    id="isDeleted"
                    placeholder="Select Status"
                    required
                    onChange={onChange}
                  >
                    <option value="">Select Status</option>
                    <option value="false">Active</option>
                    <option value="true">Disabled</option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" disabled={isLoading}>
              Submit
            </Button>
          </Form>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
};

export default CreatePromoPartner;
