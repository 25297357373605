import { useEffect, useState } from "react";
import { debounce } from "../../Functions/commonUtils";

const padding = 500;

const useInfiniteScroll = (cb) => {
  const [isFetching, setIsFetching] = useState(false);
  const [stopScroll, setStopScroll] = useState(false);

  const handleScroll = debounce(function handleScroll(e) {
    if (
      window.innerHeight + window.pageYOffset >=
      document.body.offsetHeight - padding
    ) {
      stopScroll
        ? window.removeEventListener("scroll", handleScroll)
        : setIsFetching(true);
    }
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    if (stopScroll) {
      setIsFetching(false);
      return;
    }
    cb();
  }, [isFetching, stopScroll]);

  return [isFetching, setIsFetching, (param) => setStopScroll(param)];
};

export default useInfiniteScroll;
