import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  CustomInput,
  Input,
  Label,
  FormText,
  Button,
} from "reactstrap";
import Header from "../Header";
import { APIS } from "../../Constants/endpoints";
import { getItemFromLS } from "../../Utils/localStorage";
import jwt from "jwt-decode";

export default class EditCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupon_code: "",
      coupon_code_id: "",
      coupons: [],
      coupon_id_id: "",
    };
  }

  componentDidMount() {
    this.setState({ coupon_id_id: this.props.location.state.id }, () => {
      this.getAllCoupons();
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleEditCoupon = (e) => {
    e.preventDefault();
    let token = getItemFromLS("token");
    fetch(APIS._editCoupon, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
      body: JSON.stringify({
        id: this.state.coupon_code_id,
        coupon_code: this.state.coupon_code,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        jwt(getItemFromLS("token")).userType === "admin"
          ? this.props.history.push("/")
          : this.props.history.push("/view-offers");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllCoupons = () => {
    let token = getItemFromLS("token");
    fetch(`${APIS._getCoupon}?id=${this.state.coupon_id_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        this.setState({ coupons: res });
      });
  };

  render() {
    return (
      <>
        <Header headerText="Edit Coupon" type="mainHeader" />
        <Container style={{ marginTop: "10px" }}>
          <Form
            onSubmit={this.handleEditCoupon}
            style={{
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="coupon_code_id">Select Coupon Code</Label>
                  <CustomInput
                    type="select"
                    name="coupon_code_id"
                    id="coupon_code_id"
                    placeholder="Enter Coupon Code"
                    required
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    {this.state.coupons
                      .filter((v) => v.is_allocated === false)
                      .map((v) => {
                        return <option value={v.id}>{v.coupon_code}</option>;
                      })}
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <Label for="coupon_code">New Coupon Code</Label>
                  <Input
                    type="text"
                    name="coupon_code"
                    id="coupon_code"
                    required
                    placeholder="Enter Coupon Code"
                    onChange={this.onChange}
                  />
                  <FormText>Example: MZAALO1234</FormText>
                </FormGroup>
                <Button>Sumbit</Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </>
    );
  }
}
