import React, { useRef } from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import { Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { convertDateToString } from "../../../Functions/dateFormats";

const PromoCampaignFlippingCard = ({ items }) => {
  const ref = useRef();

  const randomValue = () => {
    return Math.floor(Math.random() * 256);
  };

  const chipContainer = (val) => {
    return (
      <span
        className="mdl-chip"
        style={{ background: "darkgreen", color: "white" }}
      >
        <span className="mdl-chip__text"> {val}</span>
      </span>
    );
  };

  return (
    <>
      <div className="flex flex-wrap">
        {items &&
          items.map((item) => {
            return (
              <Flippy
                flipOnHover={false} // default false
                flipOnClick={true} // default false
                flipDirection="horizontal" // horizontal or vertical
                ref={ref} // to use toggle method like ref.curret.toggle()
                // if you pass isFlipped prop component will be controlled component.
                // and other props, which will go to div
                style={{
                  cursor: "pointer",
                  margin: "20px",
                  minWidth: "280px",
                  maxWidth: "280px",
                  minHeight: "280px",
                }} /// these are optional style, it is not necessary
              >
                <FrontSide
                  style={{
                    background: `rgb(${randomValue()}, ${randomValue()}, ${randomValue()})`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1000,
                  }}
                >
                  <h3>
                    <b>{item?.name}</b>
                  </h3>
                </FrontSide>
                <BackSide style={{ overflow: "hidden" }}>
                  <Card>
                    <Card.Header>
                      <b>Promo Campaign Detail</b>
                    </Card.Header>
                    <ListGroup
                      style={{ overflow: "scroll", maxHeight: "180px" }}
                      variant="flush"
                    >
                      <ListGroup.Item>
                        <b>Campaign Name: </b>
                        {chipContainer(item?.name)}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Description: </b>
                        {item?.description}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Id: </b>
                        <p style={{ whiteSpace: "pre-wrap" }}>{item?.id}</p>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Expiry: </b>
                        {convertDateToString(item?.expiry)}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                  <Link
                    to={{
                      pathname: `/promocode/campaign/edit/${item?.id}`,
                      state: { data: item },
                    }}
                  >
                    Edit Campaign Details
                  </Link>
                </BackSide>
              </Flippy>
            );
          })}
      </div>
    </>
  );
};

export default PromoCampaignFlippingCard;
