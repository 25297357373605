import MaterialTable from "material-table";
import React from "react";

export const RedemptionReportData = (props) => {
  const columns = [
    {
      field: "id",
      title: "Id",
      export: true,
    },
    {
      field: "coupon_parent",
      title: "Coupon Parent",
      export: true,
    },
    {
      field: "coupon_company",
      title: "Coupon Company",
      export: true,
    },
    {
      field: "coupon_code",
      title: "Coupon Code",
      export: true,
    },
    {
      field: "amount",
      title: "Amount",
      export: true,
      render: (rowData) => (
        <div className="download-tracking-count flex flex-justify-center">
          {rowData?.amount}
        </div>
      ),
    },
    {
      field: "coupon_id_id",
      title: "Coupon Id",
      export: true,
    },
    {
      field: "is_deleted",
      title: "Is Deleted?",
      export: true,
    },
    {
      field: "is_redeemed",
      title: "Is Redeemed?",
      export: true,
    },
    {
      field: "is_used",
      title: "Is Used?",
      export: true,
    },
    {
      field: "reward_transaction_id",
      title: "Reward Transaction Id",
      export: true,
    },
    {
      field: "usage_id",
      title: "Usage Id",
      export: true,
    },
    {
      field: "user_id",
      title: "User ID",
      export: true,
    },
  ];

  return (
    <div className="table">
      <MaterialTable
        title={`Redemption Report Data`}
        columns={columns}
        data={props?.data}
        options={{
          headerStyle: {
            backgroundColor: "#245642",
            color: "#FFF",
          },

          search: true,
          exportButton: true,
          sorting: true,
          paginationType: "stepped",
        }}
      />
    </div>
  );
};
