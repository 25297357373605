import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Alert,
  FormText,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Header from "../Header";
import { APIS } from "../../Constants/endpoints";
import { getItemFromLS } from "../../Utils/localStorage";
import { ToastsStore, ToastsContainer } from "react-toasts";
import jwt from "jwt-decode";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Page1 from "../RedeemPages/Page1";
import Page2 from "../RedeemPages/Page2";
import ImageUploader from "react-images-upload";
import { notPartner, getToken } from "../../Functions/userAuth";
import ReactSelect from "react-select";

export default class AddOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      coupon_company: "",
      coupon_parent: "",
      coupon_expiry: "",
      coupon_rate: "",
      coupon_how_to_redeem: "",
      coupon_tnc: "",
      coupon_discount: "",
      coupon_code_type: "",
      coupon_details: "",
      category_name: "",
      coupon_header: "",
      coupon_value: "",
      coupon_body: "",
      coupon_footer: "",
      coupon_data: "",
      coupon_strike: "",
      coupon_url: "",
      alertVisible: true,
      categories: [],
      coupon_code: "",
      discount_type: "%",
      company_logo: "",
      offer_country: "",
      cities: [],
      offer_city: [],
      offer_type: "",
      offer_priority: "",
      isShowImgVidBox: false,
      offer_image: [],
      offer_video: [],
      isOfferImageUploaded: false,
      isOfferVideoUploaded: false,
      oIPictures: [],
      oVPictures: [],
    };
  }

  getCityListByName = (country) => {
    fetch(`${APIS._getCityListByName}?country_name=${country}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.dataExists)
          this.setState({
            cities: (res || []).map(({ id, name }) => ({
              id,
              value: name,
              label: name,
            })),
          });
        else ToastsStore.error("No City Found");
      })
      .catch((err) => {
        ToastsStore.error(err);
      });
  };

  getCategory = () => {
    let token = getItemFromLS("token");
    fetch(APIS._getCategory, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        this.setState({ categories: res });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleShowImgVidBox = () => {
    this.setState({ isShowImgVidBox: !this.state.isShowImgVidBox });
  };

  onOIDrop = (picture) => {
    this.state.oIPictures.splice(0, this.state.oIPictures.length);
    this.setState({
      oIPictures: this.state.oIPictures.concat(picture),
    });
  };

  onOVDrop = (e) => {
    // this.state.oVPictures.splice(0,this.state.oVPictures.length)
    var files = e.target.files;
    if (this.checkFileSize(e) && this.checkVideoFileType(e)) {
      this.setState(
        {
          oVPictures: files,
        },
        () => {},
      );
    }
  };

  checkVideoFileType = (event) => {
    let files = event.target.files;
    let err = "";
    const types = ["video/mp4"];

    for (var x = 0; x < files.length; x++) {
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + " is not a supported format. Only mp4 supported";
      }
    }

    if (err !== "") {
      event.target.value = null;
      ToastsStore.error(err);
      return false;
    }
    return true;
  };

  checkFileSize = (event) => {
    let files = event.target.files;
    let size = 10485000;
    let err = "";

    for (var x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err +=
          files[x].name +
          " is too large, please upload file size less than 10 Mb";
      }
    }

    if (err !== "") {
      event.target.value = null;
      ToastsStore.error(err);
      return false;
    }

    return true;
  };

  handleOfferImageUpload = () => {
    if (this.state.oIPictures[0]) {
      const data = new FormData();
      data.append("multipleImages", this.state.oIPictures[0]);
      const _URL = window.URL || window.webkitURL;
      var img = new Image();
      img.src = _URL.createObjectURL(this.state.oIPictures[0]);
      img.onload = () => {
        if (img.width === img.height) {
          fetch(APIS._uploadMultipleImages, {
            method: "POST",
            headers: {
              "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
            },
            body: data,
          })
            .then((response) => response.json())
            .then((res) => {
              console.log("here res", res);
              this.setState({
                offer_image: this.state.offer_image.concat(res),
              });
              this.setState({ isOfferImageUploaded: true });
              ToastsStore.success("Images Uploaded successfully");
            })
            .catch((err) => {
              console.log("here error", err);
              ToastsStore.error("Image Upload Failed");
            });
        } else {
          ToastsStore.error(
            `Image size is ${img.width} x ${img.height}. Aspect ratio must be 1:1`,
          );
        }
      };
    } else {
      ToastsStore.error("Please Upload an Image with Aspect Ratio 1:1");
    }
  };

  handleOfferVideoUpload = () => {
    if (this.state.oVPictures[0]) {
      const data = new FormData();
      for (var i = 0; i < this.state.oVPictures.length; i++) {
        data.append("multipleImages", this.state.oVPictures[i]);
      }
      fetch(APIS._uploadMultipleImages, {
        method: "POST",
        headers: {
          "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
        },

        body: data,
      })
        .then((response) => response.json())
        .then((res) => {
          this.setState(
            { offer_video: this.state.offer_video.concat(res) },
            () => {},
          );
          this.setState({ isOfferVideoUploaded: true });
          ToastsStore.success("Video Uploaded successfully");
        })
        .catch((err) => {
          ToastsStore.error("Video Upload Failed");
        });
    } else {
      ToastsStore.error("Please Upload an Video");
    }
  };

  handleCouponCodeChange = (e) => {
    this.setState({ coupon_code: e.target.value });
  };

  handleCouponClose = () => {
    this.props.history.push({
      pathname: "/view-offers",
      state: { id: this.state.id, country: this.state.offer_country },
    });
    window.location.reload();
  };

  handleAddCoupon = (val) => {
    let token = getItemFromLS("token");
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Container>
            <div className="couponCodeModal">
              <Form>
                <FormGroup row>
                  <Col md={4}>
                    <Label>Coupon Type: </Label>
                  </Col>
                  <Col md={8}>
                    <Label>{val.coupon_code_type}</Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md={4}>
                    <Label>Coupon Code</Label>
                  </Col>
                  <Col md={8}>
                    {val.coupon_code_type === "generic" ? (
                      <Input
                        type="text"
                        placeholder="Enter Coupon Code"
                        onChange={(e) => this.handleCouponCodeChange(e)}
                      />
                    ) : null}
                  </Col>
                </FormGroup>
              </Form>
              <button
                onClick={() => {
                  if (this.state.coupon_code) {
                    fetch(APIS._addCoupon, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                        "Ocp-Apim-Subscription-Key":
                          "018226f84da242f498a008ae1022430b",
                      },
                      body: JSON.stringify({
                        coupon_id_id: val.id,
                        coupon_code: this.state.coupon_code,
                      }),
                    })
                      .then((response) => response.json())
                      .then((res) => {
                        ToastsStore.success("Coupon Added Successfully");
                        this.props.history.push({
                          pathname: "/view-offers",
                          state: {
                            id: this.state.id,
                            country: this.state.offer_country,
                          },
                        });
                        window.location.reload();
                      })
                      .catch((err) => {
                        ToastsStore.error("Add Coupon Failed");
                      });
                  } else {
                    ToastsStore.error("Please Enter Coupon Code");
                  }
                }}
              >
                Add Coupon
              </button>
              <button onClick={this.handleCouponClose}>Cancel</button>
            </div>
          </Container>
        );
      },
    });
  };

  handleAddOffer = (e) => {
    e.preventDefault();
    let token = getItemFromLS("token");
    let brand_id = notPartner() ? this.state.id : jwt(token).brand;
    fetch(APIS._addOffer, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
      body: JSON.stringify({
        brand_id,
        coupon_company: this.state.coupon_company,
        coupon_parent: this.state.coupon_parent,
        coupon_code_type: this.state.coupon_code_type,
        coupon_discount: this.state.coupon_discount,
        coupon_expiry: this.state.coupon_expiry,
        coupon_rate: this.state.coupon_rate,
        coupon_tnc: this.state.coupon_tnc,
        offer_priority: this.state.offer_priority,
        company_logo: this.state.company_logo,
        coupon_images: this.state.offer_image
          ? this.state.offer_image
          : [this.state.company_logo],
        coupon_videos: this.state.offer_video
          ? this.state.offer_video
          : [this.state.company_logo],
        coupon_how_to_redeem: this.state.coupon_how_to_redeem,
        category_name: this.state.category_name,
        offer_country: this.state.offer_country,
        offer_city: this.state.offer_city.map((s) => s.value),
        coupon_details: {
          header: this.state.coupon_header.toUpperCase(),
          value: this.state.coupon_value,
          url: this.state.coupon_url,
          strike: this.state.coupon_strike,
          body: this.state.coupon_body.toUpperCase(),
          footer: this.state.coupon_footer.toUpperCase(),
          data: this.state.coupon_data,
        },
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        ToastsStore.success("Offer Added Successfully");
        res[0].coupon_code_type === "generic"
          ? this.handleAddCoupon(res[0])
          : this.props.history.push({
              pathname: "/view-offers",
              state: { id: res[0].brand_id, country: this.state.offer_country },
            });
      })
      .catch((err) => {
        ToastsStore.error("Offer Not Added");
        console.log(err);
      });
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDiscountType = (e) => {
    this.setState({ discount_type: e.target.id });
  };

  onCitiesSelect = (offer_city) => {
    this.setState({ offer_city });
  };

  componentDidMount() {
    this.getCategory();
    if (this.props.location.state) {
      const { id, coupon_company, coupon_parent, company_logo, country } =
        this.props.location.state;
      this.setState(
        {
          id,
          coupon_company,
          coupon_parent,
          company_logo,
          offer_country: country,
        },
        () => {
          this.getCityListByName(this.state.offer_country);
        },
      );
    }
  }

  render() {
    return (
      <>
        <Header headerText="Add New Offer" type="mainHeader" />
        <Container style={{ marginTop: "10px" }}>
          <Alert
            color="info"
            isOpen={this.state.alertVisible}
            toggle={this.onDismiss}
          >
            All <span className="required">*</span> fields are mandatory. To
            Register a New Offer, Please fill all the details.
          </Alert>
          <Form
            onSubmit={this.handleAddOffer}
            style={{
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="coupon_company">
                    Brand Name<span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="coupon_company"
                    id="coupon_company"
                    placeholder="Enter Brand Name"
                    required
                    maxlength="25"
                    onChange={this.onChange}
                    value={this.state.coupon_company || ""}
                  />
                  <FormText>Limit the text to maximum 12 characters.</FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="coupon_parent">
                    Company Name<span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="coupon_parent"
                    id="coupon_parent"
                    placeholder="Enter Company Name"
                    required
                    onChange={this.onChange}
                    value={this.state.coupon_parent || ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="coupon_header">
                    Offer Title<span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="upperCase"
                    name="coupon_header"
                    id="coupon_header"
                    placeholder="Enter Offer Title"
                    required
                    onChange={this.onChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="coupon_body">
                    Offer Description<span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="upperCase"
                    name="coupon_body"
                    id="coupon_body"
                    placeholder="Enter Offer Description"
                    required
                    onChange={this.onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="coupon_url">Offer URL</Label>
                  <Input
                    type="text"
                    name="coupon_url"
                    id="coupon_url"
                    placeholder="Enter Offer URL"
                    onChange={this.onChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="coupon_expiry">
                    Offer Expiry<span className="required">*</span>
                  </Label>
                  <Input
                    type="date"
                    name="coupon_expiry"
                    id="coupon_expiry"
                    min="2019-01-01"
                    max="2025-01-01"
                    placeholder="Enter Offer Expiry"
                    required
                    onChange={this.onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="coupon_value">
                    Offer Value<span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="coupon_value"
                    id="coupon_value"
                    placeholder="Enter Offer Value"
                    required
                    onChange={this.onChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="coupon_discount">
                    Offer Discount<span className="required">*</span>
                  </Label>
                  <InputGroupAddon addonType="append">
                    <Input
                      type="number"
                      name="coupon_discount"
                      id="coupon_discount"
                      min={0}
                      max={100}
                      placeholder="Enter Offer Discount"
                      required
                      onChange={this.onChange}
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroupAddon>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="coupon_code_type">
                    Coupon Code Type<span className="required">*</span>
                  </Label>
                  <CustomInput
                    type="select"
                    name="coupon_code_type"
                    id="coupon_code_type"
                    placeholder="Select Coupon Code Status"
                    required
                    onChange={this.onChange}
                  >
                    <option value="">Select Coupon Code Type</option>
                    <option value="generic">Generic</option>
                    <option value="unique">Unique</option>
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="category_name">
                    Offer Category<span className="required">*</span>
                  </Label>
                  <CustomInput
                    type="select"
                    name="category_name"
                    id="category_name"
                    placeholder="Select Offer Category"
                    required
                    onChange={this.onChange}
                  >
                    <option value="">Select Offer Category</option>
                    {this.state.categories.map((c) => {
                      return (
                        <option value={c.category_name}>
                          {c.category_name}
                        </option>
                      );
                    })}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="offer_country">
                    Offer Country<span className="required">*</span>
                  </Label>
                  <CustomInput
                    type="select"
                    disabled
                    name="offer_country"
                    id="offer_country"
                    placeholder="Select Offer Country"
                    required
                    onChange={this.onChange}
                  >
                    <option value={this.state.offer_country}>
                      {this.state.offer_country || "Select Offer Country"}
                    </option>
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="offer_city">
                    Offer City<span className="required">*</span>
                  </Label>
                  {/* <CustomInput type="select" name="offer_city" id="offer_city" placeholder="Select Offer Cities" required onChange={this.onChange} >
            <option value="">Select Offer Cities</option>
            <option value="All">All</option>
            {
              this.state.cities.map(c => {
                return (
                  <option value={c.name}>{c.name}</option>
                )
              })
            }
          </CustomInput> */}
                  <ReactSelect
                    className="selectionBox"
                    placeholder="Select Cities"
                    options={this.state.cities}
                    onChange={this.onCitiesSelect}
                    isMulti={true}
                    value={this.state.offer_city}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="coupon_how_to_redeem">How To Redeem</Label>
                  <Input
                    type="textarea"
                    name="coupon_how_to_redeem"
                    id="coupon_how_to_redeem"
                    placeholder="Enter Offer - How To Redeem"
                    onChange={this.onChange}
                  />
                  <FormText>Example: First Line \n Second Line</FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="coupon_tnc">Terms & Condition</Label>
                  <Input
                    type="textarea"
                    name="coupon_tnc"
                    id="coupon_tnc"
                    placeholder="Enter Offer Terms & Condition"
                    onChange={this.onChange}
                  />
                  <FormText>Example: First Line \n Second Line</FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="coupon_data">More Offer Details</Label>
                  <Input
                    type="textarea"
                    name="coupon_data"
                    id="coupon_data"
                    placeholder="Enter More Offer Details"
                    onChange={this.onChange}
                  />
                  <FormText>Example: First Line \n Second Line</FormText>
                </FormGroup>
              </Col>
            </Row>
            {jwt(getItemFromLS("token")).userType === "admin" ? (
              <>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="offer_priority">Offer Priority</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ cursor: "pointer" }}>
                            <Tooltip
                              placement="top"
                              title="Priority should be a number. Default is 0.
                  To make an offer show prior to another offer it should be provided a priority greater than others. i.e. 1
                  There could be more offer with the same priority order.
                  One level of sorting of offer is on the priority field and second level of sorting is based on discount rate.
                  "
                            >
                              <InfoIcon />
                            </Tooltip>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="number"
                          name="offer_priority"
                          id="offer_priority"
                          placeholder="Enter Offer Priority in Number"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="coupon_footer">Footer</Label>
                      <Input
                        type="text"
                        name="coupon_footer"
                        className="upperCase"
                        id="coupon_footer"
                        placeholder="Enter Coupon Footer"
                        onChange={this.onChange}
                      />
                      <FormText>Example: OFFER PRICE INR 199</FormText>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="coupon_rate">
                        Coins to Redeem<span className="required">*</span>
                      </Label>
                      <InputGroup addonType="append">
                        <Input
                          type="text"
                          name="coupon_rate"
                          id="coupon_rate"
                          placeholder="Enter Coupon Body"
                          required
                          onChange={this.onChange}
                        />
                        <InputGroupText>Coins</InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : null}
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <CustomInput
                    type="checkbox"
                    id="isShowImgVidBox"
                    onChange={this.handleShowImgVidBox}
                    label="Want to Add Offer Image and Offer Video"
                  />
                </FormGroup>
              </Col>
            </Row>
            {this.state.isShowImgVidBox ? (
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="offer_image">Offer Image</Label>
                    <Input
                      type="text"
                      name="offer_image"
                      id="offer_image"
                      placeholder="Upload Offer Image"
                      onChange={this.onChange}
                      disabled={
                        this.state.offer_image.length > 0 ? false : true
                      }
                      value={this.state.offer_image}
                    />
                    <Row>
                      <Col md={12}>
                        <ImageUploader
                          withIcon={false}
                          buttonText="Choose images"
                          onChange={this.onOIDrop}
                          withPreview={true}
                          singleImage={true}
                          imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                          maxFileSize={5242880}
                          fileSizeError="File size is too big"
                        />
                      </Col>
                      <Col
                        md={12}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          id="oIPictures"
                          color="info"
                          onClick={this.handleOfferImageUpload}
                        >
                          {this.state.isOfferImageUploaded
                            ? "Upload Again"
                            : "Upload Offer Image"}
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="offer_video">Offer Video</Label>
                    <Input
                      type="text"
                      name="offer_video"
                      id="offer_video"
                      placeholder="Upload Offer Video"
                      onChange={this.onChange}
                      disabled={
                        this.state.offer_video.length > 0 ? false : true
                      }
                      value={this.state.offer_video}
                    />
                    <Row>
                      <Col md={12}>
                        <Input
                          type="file"
                          className="offerVideoUpload"
                          onChange={this.onOVDrop}
                        />
                      </Col>
                      <Col
                        md={12}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          id="oVPictures"
                          color="info"
                          onClick={this.handleOfferVideoUpload}
                        >
                          {this.state.isOfferVideoUploaded
                            ? "Upload Again"
                            : "Upload Offer Video"}
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
            {jwt(getItemFromLS("token")).userType === "admin" ? (
              <>
                <Row form>
                  <Col md={6}>
                    {this.state.coupon_company &&
                    this.state.coupon_header &&
                    this.state.coupon_value &&
                    this.state.coupon_body &&
                    this.state.coupon_rate ? (
                      <Page1
                        company_logo={this.state.company_logo}
                        company_name={this.state.coupon_company}
                        title={this.state.coupon_header}
                        value={this.state.coupon_value}
                        description={this.state.coupon_body}
                        coins={this.state.coupon_rate}
                      />
                    ) : null}
                  </Col>
                  <Col md={6}>
                    {this.state.coupon_company &&
                    this.state.coupon_company &&
                    this.state.coupon_rate &&
                    this.state.coupon_data ? (
                      <Page2
                        company_logo={this.state.company_logo}
                        company_name={this.state.coupon_company}
                        details={this.state.coupon_data}
                        tnc={this.state.coupon_tnc}
                        htr={this.state.coupon_how_to_redeem}
                        coins={this.state.coupon_rate}
                      />
                    ) : null}
                  </Col>
                  {this.state.coupon_header &&
                  this.state.coupon_value &&
                  this.state.coupon_body &&
                  this.state.coupon_rate ? (
                    <Col md={12}>
                      <Alert color="warning">
                        <i>
                          <b>Note:</b> These images are for representation only
                          and is not live until the offer is in active state.
                        </i>
                      </Alert>
                    </Col>
                  ) : null}
                </Row>
              </>
            ) : null}
            <Button>Submit</Button>
          </Form>
        </Container>
        <ToastsContainer store={ToastsStore} />
      </>
    );
  }
}
