import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { ImageContainer } from "../../Styles/base";

export default class Caraousal extends Component {
  render() {
    return (
      <>
        <Carousel showThumbs={false} autoPlay={true} centerMode>
          {this.props.images.brand_images &&
            this.props.images.brand_images.map((v, i) => {
              return (
                <div style={{ maxWidth: "200px", maxHeight: "200px" }}>
                  <img alt={this.props.images.name} src={v} />
                  <p className={`Image ${i + 1}`}>Image {i + 1}</p>
                </div>
              );
            })}
        </Carousel>
      </>
    );
  }
}
