import React from "react";
import CsvDownloader from "react-csv-downloader";
import { Button } from "reactstrap";
import { SystemUpdateAlt } from "@material-ui/icons";

export default function UniqueCouponDownloadFormat(props) {
  const columns = [
    {
      id: "coupon_code",
      displayName: "Coupon_Code",
    },
    {
      id: "id",
      displayName: "Offer_id",
    },
  ];

  const datas = [
    {
      coupon_code: "ENTER YOUR CODE",
      id: props.data,
    },
  ];

  return (
    <CsvDownloader
      filename="Unique Coupon CSV Format"
      columns={columns}
      datas={datas}
      separator=","
    >
      <div className="uniqueCsvFormat">
        Download CSV Format <SystemUpdateAlt />
      </div>
    </CsvDownloader>
  );
}
