import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import Header from "../../../Header";
import { Link } from "react-router-dom";
import BiddingHeader from "../BiddingHeader";

const Analytics = (props) => {
  return (
    <>
      <Header headerText="Analytics" type="mainHeader" />
      <BiddingHeader />
    </>
  );
};

export default Analytics;
