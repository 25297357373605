// this func do not cover all the cases
export function debounce(func, wait = 100) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

export const returnTrue = (item) => {
  return item === true || item === "true";
};
