import React, { Component } from "react";
import { OfferContainer } from "../Styles/base";
import { Card, ListGroup } from "react-bootstrap";
import jwt from "jwt-decode";
import { Row, Col } from "reactstrap";
import { APIS } from "../Constants/endpoints";
import FlippingCard from "./FlippingCard";
import { getItemFromLS } from "../Utils/localStorage";
import OfferImgVidDetails from "./Offers/OfferImgVidDetails";

class ViewOfferDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
    };
  }

  chipContainer = (val) => {
    return (
      <span className="mdl-chip">
        <span className="mdl-chip__text"> {val}</span>
      </span>
    );
  };

  getCoupondata = () => {
    let token = getItemFromLS("token");
    fetch(`${APIS._getCoupon}?id=${this.props.offer.id}`, {
      mathod: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        this.setState({ coupons: res });
      });
  };

  componentDidMount() {
    this.getCoupondata();
  }

  render() {
    let offer = this.props.offer;
    let couponDetails = JSON.parse(offer.coupon_details);
    return (
      <>
        <OfferContainer>
          <Row>
            <Col md={3}>
              <Card>
                <Card.Header>Offer Details</Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <b>Offer Id: </b>
                    {offer.id}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Offer Discount: </b>
                    {offer.coupon_discount}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Offer Expiry: </b>
                    {offer.coupon_expiry}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Coupon Code Type: </b>
                    {offer.coupon_code_type}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Category Name: </b>
                    {offer.category_name}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Offer Title: </b>
                    {couponDetails.header}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Offer Value: </b>
                    {couponDetails.value}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Offer URL: </b>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {couponDetails.url}
                    </p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Offer Description: </b>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {couponDetails.body}
                    </p>
                  </ListGroup.Item>
                  {jwt(getItemFromLS("token")).userType === "admin" ? (
                    <>
                      <ListGroup.Item>
                        <b>Coins To Redeem: </b>
                        {offer.coupon_rate}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b>Footer: </b>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {couponDetails.footer}
                        </p>
                      </ListGroup.Item>
                    </>
                  ) : null}
                  <ListGroup.Item>
                    <b>More Offer Details: </b>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {couponDetails.data}
                    </p>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Header>Offer - How To Redeem</Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {offer.coupon_how_to_redeem}
                    </p>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <Card.Header>Offer - Terms & Condition</Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <p style={{ whiteSpace: "pre-wrap" }}>{offer.coupon_tnc}</p>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Col md={12}>
                <FlippingCard
                  couponType={this.props.offer.coupon_code_type}
                  company_logo={this.props.offer.company_logo}
                  company_name={this.props.offer.coupon_company}
                  coupons={this.state.coupons}
                />
              </Col>
              <br />
              <br />
              <Col md={12}>
                <OfferImgVidDetails
                  offer_image={this.props.offer.coupon_images}
                  offer_video={this.props.offer.coupon_videos}
                />
              </Col>
            </Col>
          </Row>
        </OfferContainer>
      </>
    );
  }
}

export default ViewOfferDetails;
