import MaterialTable from "material-table";
import React from "react";
import { convertDateToString } from "../../../Functions/dateFormats";

export const PromoCodeReportData = (props) => {
  const columns = [
    {
      field: "id",
      title: "Id",
      export: true,
      hidden: true,
    },
    {
      field: "PromoCode",
      title: "Promo Code",
      export: true,
      render: (event) => (
        <>
          <div className="highlight">{event?.PromoCode?.promoCode}</div>
        </>
      ),
    },

    {
      field: "User",
      title: "User Email",
      export: true,
      render: (event) => (
        <>
          <div className="highlight2">{event?.User?.email}</div>
        </>
      ),
    },
    {
      field: "User",
      title: "User Phone",
      export: true,
      render: (event) => (
        <>
          <div className="highlight3">{event?.User?.phone}</div>
        </>
      ),
    },
    {
      field: "createdAt",
      title: "Created At",
      export: true,
      render: (event) => (
        <>
          <div>{convertDateToString(event?.createdAt)}</div>
        </>
      ),
    },
  ];

  return (
    <div className="table">
      <MaterialTable
        title={`Promocode Report Data`}
        columns={columns}
        data={props?.data}
        options={{
          headerStyle: {
            backgroundColor: "#245642",
            color: "#FFF",
          },

          search: true,
          exportButton: true,
          sorting: true,
          paginationType: "stepped",
        }}
      />
    </div>
  );
};
