import React from "react";
import { Card, ListGroup } from "react-bootstrap";

const LeaderBoardDetailData = (props) => {
  return (
    <div>
      {Object.keys(props?.userData).length !== 0 ? (
        <Card>
          <Card.Header>User Details</Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <b>First Name: </b>
              {props.userData?.firstName}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Last Name: </b>
              {props.userData?.lastName}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Email: </b>
              {props.userData?.email}
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Mobile Number: </b>
              {props.userData?.country_code} - {props.userData?.phone}
            </ListGroup.Item>
          </ListGroup>
        </Card>
      ) : (
        <p>User details are shown only for Non-Clamied Winners</p>
      )}
    </div>
  );
};

export default LeaderBoardDetailData;
