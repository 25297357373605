import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { convertDateToString } from "../../Functions/dateFormats";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./Cards.scss";
import { truncateText } from "../../Functions/textUtils";
import { Dialog, DialogTitle, Switch } from "@material-ui/core";
import DialogCard from "./DialogCard";

const useStyles = makeStyles({
  root: {
    maxWidth: 310,
    margin: 10,
  },

  cardAction: {
    flexWrap: "wrap",
  },
});

export default function BiddingCard({ items }) {
  const classes = useStyles();

  const [visibilityModal, setVisibilityModal] = useState(false);
  const [eventId, setEventId] = useState("");
  const [isVisible, setVisible] = useState(true);

  const handleVisibilityConfirm = (event) => {
    setEventId(event.id);
    setVisible(event.is_visible);
    setVisibilityModal(!visibilityModal);
  };

  return (
    <div className="flex flex-wrap">
      {items.map((item) => {
        return (
          item && (
            <Card className={classes.root}>
              <Link
                to={{
                  pathname: item.event.is_deleted
                    ? ""
                    : `/bidding/view-event/${item.event.id}`,
                  state: { data: item },
                }}
                className="linkDecoration"
              >
                <CardActionArea
                  className={item.event.is_deleted ? "inactiveCard" : ""}
                >
                  <CardMedia
                    component="img"
                    alt={item.product.product_details}
                    height="140"
                    image={item.product.product_images[0]}
                    title={item.product.product_details}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography
                      className="flexContent"
                      variant="body2"
                      component="p"
                    >
                      <h6 style={{ fontSize: "10px", color: "green" }}>
                        {convertDateToString(item.event.start_time)}{" "}
                      </h6>
                      <h6 style={{ fontSize: "10px", color: "red" }}>
                        {convertDateToString(item.event.end_time)}
                      </h6>
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2">
                      {item.product.product_details}
                    </Typography>
                    <Typography variant="body2" component="p">
                      <div style={{ color: "orange" }}>{item.event.id}</div>
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {truncateText(item.event.event_description, 100)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link>
              <CardActions className={classes.cardAction}>
                <Link
                  to={{
                    pathname: `/bidding/view-event/${item.event.id}`,
                    state: { data: item },
                  }}
                >
                  <Button size="small" color="primary">
                    View Details
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: `/bidding/edit-event/${item.event.id}`,
                    state: { data: item },
                  }}
                >
                  <Button size="small" color="primary">
                    Edit
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: `/bidding/leaderboard/${item.event.id}`,
                    state: { data: item },
                  }}
                >
                  <Button size="small" color="primary">
                    Leaderboard
                  </Button>
                </Link>
                <Switch
                  checked={item?.event?.is_visible}
                  disbaled={!item?.event?.is_visible}
                  onChange={() => handleVisibilityConfirm(item.event)}
                />
                <Link
                  to={{
                    pathname: `/bidding/edit-voucher/${item.event.id}`,
                    state: { data: item },
                  }}
                >
                  <Button size="small" color="primary">
                    Edit Voucher
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: `/bidding/update-images/${item.event.id}`,
                    state: { data: item },
                  }}
                >
                  <Button size="small" color="primary">
                    Update Images
                  </Button>
                </Link>
              </CardActions>
            </Card>
          )
        );
      })}
      <DialogCard
        open={visibilityModal}
        onClose={() => setVisibilityModal(false)}
        eventId={eventId}
        isVisible={isVisible}
      />
    </div>
  );
}
