import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import "./BiddingHeader.scss";

var selected = "viewEvents";
const BiddingHeader = () => {
  const history = useHistory();

  const handleSelected = (val) => {
    selected = val;
  };

  useEffect(() => {
    if (history.location.pathname === "/bidding") selected = "viewEvents";
  });

  return (
    <>
      <div className="bidding-header-main-container">
        <Link to="/bidding" className="link-decoration">
          <div
            className={`default ${selected === "viewEvents" ? "selected" : "not-selected"}`}
            onClick={() => handleSelected("viewEvents")}
          >
            View All Bidding Event
          </div>
        </Link>
        <Link to="/bidding/create-event" className="link-decoration">
          <div
            className={`default ${selected === "createEvents" ? "selected" : "not-selected"}`}
            onClick={() => handleSelected("createEvents")}
          >
            Create New Bidding Event
          </div>
        </Link>
      </div>
    </>
  );
};

export default BiddingHeader;
