const _env = process.env.REACT_APP_ENV;
const DEV_HOST = {
  rewardPartner: "https://rewardpartner-service-staging.mzaalo.com",
  bidding: "https://staging.mzaalo.com/bidding-cms",
  bidding_ocp_key: "bb7d7afb51d94df09cdfed9e9579e685",
  reward_bidding: "https://staging.mzaalo.com/reward-bidding",
  mzaaloRewards: "https://staging.mzaalo.com/api",
  platformCoreFeeder: "https://staging.mzaalo.com/platform-core-feeder",
  voucher: "https://staging.mzaalo.com/voucher",
  mzaalo_ocp_key: "bb7d7afb51d94df09cdfed9e9579e685",
  mzaaloAdmin_ocp_key: "bb7d7afb51d94df09cdfed9e9579e685",
  mzaaloUser: "https://staging.mzaalo.com",
  user_token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMDAwMDE2MWItMThhNS00YzgyLThlOWYtNjhmYTMyOTE4YjNhIiwiZW1haWwiOiJxd2VydHlAbWFpbGluYXRvci5jb20iLCJpYXQiOjE1NjAzMzQzMDIsImV4cCI6MzMwOTYzMzQzMDIwfQ.WcPOoes_IJoqNNPbApDKNR6HtoDAtYd9zTbD2iCWn1E",
};

const STAGING_HOST = {
  rewardPartner: "https://rewardpartner-service-staging.mzaalo.com",
  bidding: "https://staging.mzaalo.com/bidding-cms",
  bidding_ocp_key: "bb7d7afb51d94df09cdfed9e9579e685",
  reward_bidding: "https://staging.mzaalo.com/reward-bidding",
  mzaaloRewards: "https://staging.mzaalo.com/api",
  platformCoreFeeder: "https://staging.mzaalo.com/platform-core-feeder",
  voucher: "https://staging.mzaalo.com/voucher",
  mzaalo_ocp_key: "ff2ad2c0e2b046909ccd2797d53ae49f",
  mzaaloAdmin_ocp_key: "bb7d7afb51d94df09cdfed9e9579e685",
  mzaaloUser: "https://staging.mzaalo.com",
  user_token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMDAwMDE2MWItMThhNS00YzgyLThlOWYtNjhmYTMyOTE4YjNhIiwiZW1haWwiOiJxd2VydHlAbWFpbGluYXRvci5jb20iLCJpYXQiOjE1NjAzMzQzMDIsImV4cCI6MzMwOTYzMzQzMDIwfQ.WcPOoes_IJoqNNPbApDKNR6HtoDAtYd9zTbD2iCWn1E",
};

const PRODUCTION_HOST = {
  rewardPartner: "https://production-api.mzaalo.com/reward-backend",
  bidding: "https://production-api.mzaalo.com/bidding-cms",
  bidding_ocp_key: "018226f84da242f498a008ae1022430b",
  reward_bidding: "https://production-api.mzaalo.com/reward-bidding",
  mzaaloRewards: "https://production-api.mzaalo.com/api",
  platformCoreFeeder: "https://production-api.mzaalo.com/platform-core-feeder",
  voucher: "https://production-api.mzaalo.com/voucher",
  mzaalo_ocp_key: "018226f84da242f498a008ae1022430b",
  mzaaloAdmin_ocp_key: "018226f84da242f498a008ae1022430b",
  mzaaloUser: "https://production-api.mzaalo.com",
  user_token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMDAwMDE2MWItMThhNS00YzgyLThlOWYtNjhmYTMyOTE4YjNhIiwiZW1haWwiOiJxd2VydHlAbWFpbGluYXRvci5jb20iLCJpYXQiOjE1NjAzMzQzMDIsImV4cCI6MzMwOTYzMzQzMDIwfQ.WcPOoes_IJoqNNPbApDKNR6HtoDAtYd9zTbD2iCWn1E",
};

const getURL = function () {
  let _url = PRODUCTION_HOST;
  switch (_env) {
    case "dev":
      _url = DEV_HOST;
      break;
    case "staging":
      _url = STAGING_HOST;
      break;
    case "production":
      _url = PRODUCTION_HOST;
      break;
    default:
      break;
  }
  return _url;
};

export const URL = getURL();

export const voucherEventTypes = [
  {
    value: "bid_registration",
    label: "Add Bid Registration",
  },
  {
    value: "bid_low_balance",
    label: "Add Bid Low Balance",
  },
];
