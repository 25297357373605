import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../Contexts/Auth";
import jwt from "jwt-decode";
import { getItemFromLS } from "../Utils/localStorage";

function PrivateRoute({ component: Component, ...rest }) {
  const { authTokens } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        authTokens && jwt(getItemFromLS("token")).exp * 1000 > Date.now() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { referer: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
