import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { getToken } from "../../Functions/userAuth";
import { APIS } from "../../Constants/endpoints";
import { CircularProgress } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const AddBulkUpload = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [offer_file, setOfferFile] = useState("");
  const [image_folder, setImageFolder] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const steps = getSteps();

  function getSteps() {
    return ["Upload Image Folder", "Upload Offer CSV"];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            <p>
              Upload Bulk Images Folder:
              <input
                type="file"
                name="image-folder"
                id="image-folder"
                className="offerFile"
                onChange={handleUploadImageFolder}
              />
              <label for="image-folder">
                <CloudUploadIcon /> Upload Images
              </label>
              {isLoading && <CircularProgress />}
            </p>
          </>
        );
      case 1:
        return (
          <>
            <p>
              Upload Bulk Offer CSV:
              <input
                type="file"
                name="offer_file"
                id="offer_file"
                className="offerFile"
                onChange={handleBulkUploadOffer}
              />
              <label for="offer_file">
                <CloudUploadIcon /> Upload Offer CSV
              </label>
              {isLoading && <CircularProgress />}
              {showResponse && <div></div>}
            </p>
          </>
        );
      default:
        return "Unknown step";
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleUploadImageFolder = (e) => {
    e.preventDefault();
    setImageFolder(e.target.files[0]);
    handleUploadFolder(e.target.files[0]);
  };

  const handleBulkUploadOffer = (e) => {
    e.preventDefault();
    setOfferFile(e.target.files[0]);
    handleUploadCSV(e.target.files[0]);
  };

  const handleUploadCSV = (offer_file) => {
    setLoading(true);
    let token = getToken();
    const data = new FormData();
    data.append("uploadOfferCSV", offer_file);
    if (offer_file) {
      fetch(APIS._newBulkRewardsUpload, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
        },
        body: data,
      })
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          if (res.success) {
            toast.success(`(${res?.data?.rowsCount}) ${res.message}`, {
              position: "top-right",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            res &&
              res.message &&
              res.message.map((msg) => {
                return toast.error(
                  `Row: ${msg?.row}, ErrorMessage: ${msg?.message}`,
                  {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  },
                );
              });
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err && err.message, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // ToastsStore.error(err && err.message && err.message)
        });
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // ToastsStore.error("Please Upload Offers File (CSV File)")
    }
  };

  const handleUploadFolder = (val) => {
    setLoading(true);
    let token = getToken();
    console.log("image", val);
    const data = new FormData();
    data.append("zipFile", val);
    if (val) {
      fetch(APIS._uploadBulkImageFolder, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
        },
        body: data,
      })
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          if (res.status) {
            ToastsStore.success(res.message);
          } else {
            ToastsStore.error(res.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          ToastsStore.error(err && err.message && err.message);
        });
    } else {
      ToastsStore.error("Please Upload Image Folder (zipped)");
    }
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All Rows Processesed. Reset To Upload Again</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </div>
  );
};

export default AddBulkUpload;
