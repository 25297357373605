import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import Switch from "react-switch";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ViewOfferDetails from "../ViewOfferDetails";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import Header from "../Header";
import jwt from "jwt-decode";
import { getItemFromLS } from "../../Utils/localStorage";
import { APIS } from "../../Constants/endpoints";
import { ImageContainer } from "../../Styles/base";
import UniqueCouponDownloadFormat from "./UniqueCouponDownloadFormat";
import { notPartner, getToken } from "../../Functions/userAuth";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
// const {ExportCSVButton} = CSVExport;

class ViewOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerName: null,
      offers: [],
      coupons: [],
      selectedCoupon: "",
      coupon_code: "",
      coupon_file: "",
      company_logo: "",
      confirm_offer_rate: "",
      anchorEl: null,
    };
  }

  columns = [
    {
      field: "id",
      title: "Offer Id",
      hidden: true,
      export: true,
    },
    {
      field: "header",
      title: "Offer Title",
      // hidden: true
    },
    {
      field: "body",
      title: "Offer Description",
      // hidden: true
    },
    {
      field: "footer",
      title: "Offer Description 2",
      hidden: true,
    },
    {
      field: "value",
      title: "Offer Value",
    },
    {
      field: "coupon_parent",
      title: "Coupon Parent",
      hidden: true,
    },
    {
      field: "coupon_company",
      title: "Coupon Company",
      hidden: true,
    },
    {
      field: "created",
      title: "Created At",
      hidden: true,
    },
    {
      field: "updated",
      title: "Updated At",
      hidden: true,
    },
    {
      field: "coupon_how_to_redeem",
      title: "How To Redeem",
      hidden: true,
    },
    {
      field: "coupon_tnc",
      title: "T&C",
      hidden: true,
    },
    {
      field: "coupon_details",
      title: "Coupon Details",
      hidden: true,
    },
    {
      field: "coupon_rate",
      title: "MRP",
      hidden: true,
    },
    {
      field: "offer_priority",
      title: "Offer Priority",
      hidden: true,
    },
    {
      field: "offer_image",
      title: "Offer Image",
      hidden: true,
    },
    {
      field: "offer_video",
      title: "Offer Video",
      hidden: true,
    },
    {
      field: "coupon_discount",
      title: "Discount",
      // sort: true
    },
    {
      field: "coupon_expiry",
      title: "Expiry",
      // hidden: true,
    },
    {
      field: "coupon_code_type",
      title: "Coupon Type",
      // sort: true
    },
    {
      field: "offer_country",
      title: "Offer Country",
      hidden: true,
    },
    {
      field: "offer_city",
      title: "Offer City",
      render: (event) => (
        <>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={this.handleCityButtonClick}
          >
            <LocationCityRoundedIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleCityButtonClose}
          >
            {event.offer_city.map((v) => {
              return <MenuItem>{v}</MenuItem>;
            })}
          </Menu>
        </>
      ),
    },
    {
      field: "status",
      title: "Status",
      render: (event) => (
        <Switch
          checked={!event.status}
          disabled={
            jwt(getItemFromLS("token")).userType === "admin" ? false : true
          }
          onChange={() => this.handleStatusChange(event)}
        />
      ),
    },
  ];

  handleCityButtonClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCityButtonClose = () => {
    this.setState({ anchorEl: null });
  };

  handleStatusChange = (val) => {
    let token = getItemFromLS("token");
    if (val.status === true) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Container>
              <div className="couponCodeModal">
                <h1>Are you sure?</h1>
                <p>To make offer as active, Please enter Coins to Redeem</p>
                <Form>
                  <FormGroup row>
                    <Col md={4}>
                      <Label>Coins</Label>
                    </Col>
                    <Col md={8}>
                      <Label>{val.coupon_rate}</Label>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md={4}>
                      <Label>Coins To Redeem</Label>
                    </Col>
                    <Col md={8}>
                      <Input
                        type="text"
                        placeholder="Enter Coins To Redeem"
                        required
                        onChange={(e) =>
                          this.setState({ confirm_offer_rate: e.target.value })
                        }
                      />
                    </Col>
                  </FormGroup>
                </Form>
                <button
                  onClick={() => {
                    if (this.state.confirm_offer_rate) {
                      fetch(APIS._changeOfferStatus, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${token}`,
                          "Ocp-Apim-Subscription-Key":
                            "018226f84da242f498a008ae1022430b",
                        },
                        body: JSON.stringify({
                          id: val.id,
                          is_deleted: !val.status,
                          coupon_rate: this.state.confirm_offer_rate,
                        }),
                      })
                        .then((response) => response.json())
                        .then((res) => {
                          if (res === "Not possible") {
                            ToastsStore.error(
                              "Please add a coupon to make offer active",
                            );
                          } else
                            ToastsStore.success("status Upated Successfully");
                          setTimeout(function () {
                            window.location.reload();
                          }, 2000);
                        })
                        .catch((err) => {
                          ToastsStore.error("Status Not Updated");
                        });
                      onClose();
                    } else {
                      ToastsStore.error("Please Enter Coins To Redeem");
                    }
                  }}
                >
                  {" "}
                  Change Status
                </button>
                <button onClick={onClose}>Cancel</button>
              </div>
            </Container>
          );
        },
      });
    } else {
      fetch(APIS._changeOfferStatus, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
        },
        body: JSON.stringify({
          id: val.id,
          is_deleted: !val.status,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          ToastsStore.success("status Upated Successfully");
          window.location.reload();
        })
        .catch((err) => {
          ToastsStore.error("Status Not Updated");
        });
    }
  };

  getBrandLogo = (id) => {
    let token = getItemFromLS("token");
    fetch(APIS._getBrands, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((items) =>
        this.setState({
          company_logo: items
            .filter((v) => v.id === id)
            .map((v) => v.company_logo),
        }),
      )
      .catch((err) => console.log(err));
  };

  getBrandData = () => {
    let token = getItemFromLS("token");
    let brandid =
      jwt(token).userType === "partner"
        ? jwt(token).brand
        : this.props.location.state.id;
    fetch(`${APIS._getBrandsOffers}?id=${brandid}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((items) => {
        this.setState({ partnerName: items[0].coupon_company });
        this.setState({
          offers: items.map((offer) => ({
            id: offer.id,
            coupon_parent: offer.coupon_parent,
            coupon_company: offer.coupon_company,
            coupon_rate: offer.coupon_rate,
            coupon_discount: offer.coupon_discount,
            coupon_expiry: offer.coupon_expiry,
            coupon_code_type: offer.coupon_code_type,
            category_name: offer.category_name,
            created: offer.created,
            updated: offer.updated,
            offer_country: offer.offer_country,
            offer_city: offer.offer_city,
            status: offer.is_deleted,
            coupon_how_to_redeem: offer.coupon_how_to_redeem,
            coupon_tnc: offer.coupon_tnc,
            coupon_details: offer.coupon_details,
            header: JSON.parse(offer.coupon_details).header,
            body: JSON.parse(offer.coupon_details).body,
            footer: JSON.parse(offer.coupon_details).footer,
            value: JSON.parse(offer.coupon_details).value,
            company_logo: offer.company_logo,
            offer_priority: offer.offer_priority,
            offer_image: offer.coupon_images,
            offer_video: offer.coupon_videos,
            offer: offer,
          })),
        });
        ToastsStore.success("Offers Fetched");
      })
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    let id =
      jwt(getItemFromLS("token")).userType === "admin"
        ? this.props.location.state.id
        : jwt(getItemFromLS("token")).brand;
    this.getBrandData();
    this.getBrandLogo(id);
    if (!this.state.partnerName) {
      let partnerName =
        jwt(getItemFromLS("token")).userType === "partner"
          ? jwt(getItemFromLS("token")).brandName
          : this.props.location.state.name;
      this.setState({ partnerName });
    }
  }

  handleAddOfferChanges = (val) => {
    let type = jwt(getItemFromLS("token"));
    var id, coupon_company, coupon_parent, company_logo;
    if (notPartner()) {
      id = val ? val.offer.brand_id : this.props.location.state.id;
      coupon_company = val.coupon_company
        ? val.coupon_company
        : this.props.location.state.name;
      coupon_parent = val.coupon_parent
        ? val.coupon_parent
        : this.props.location.state.name;
      company_logo = val.company_logo
        ? val.company_logo
        : this.props.location.state.company_logo;
    } else {
      id = type.id;
      coupon_company = type.brandName;
      coupon_parent = type.brandName;
    }

    let country = val.offer_country
      ? val.offer_country
      : this.props.location.state.country;

    this.props.history.push({
      pathname: "/add-offer",
      state: { id, coupon_company, coupon_parent, company_logo, country },
    });
  };

  handleCouponCodeChange = (e) => {
    this.setState({ coupon_code: e.target.value });
  };

  handleCouponCSVChange = (e) => {
    e.preventDefault();
    this.setState({ coupon_file: e.target.files[0] }, () => {});
  };

  handleUploadCSV = () => {
    let token = getItemFromLS("token");
    const data = new FormData();
    data.append("uploadCSV", this.state.coupon_file);
    if (this.state.coupon_file) {
      fetch(APIS._addCouponFromCSV, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
        },
        body: data,
      })
        .then((response) => response.json())
        .then((res) => {
          ToastsStore.success("Coupon Uploaded Successfully");
          window.location.reload();
        })
        .catch((err) => {
          ToastsStore.error("Uploading Coupon Failed");
          console.log(err);
        });
    } else {
      ToastsStore.error("Please Upload Unique Coupon Codes (CSV File)");
    }
  };

  handleAddCoupon = (val) => {
    let token = getItemFromLS("token");

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Container>
            <div className="couponCodeModal">
              <Form>
                {val.coupon_code_type === "unique" ? (
                  <FormGroup row>
                    <Col md={12}>
                      <Label>
                        <UniqueCouponDownloadFormat data={val.id} />
                      </Label>
                    </Col>
                  </FormGroup>
                ) : null}
                <FormGroup row>
                  <Col md={4}>
                    <Label>Coupon Type: </Label>
                  </Col>
                  <Col md={8}>
                    <Label>{val.coupon_code_type}</Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md={4}>
                    <Label>Coupon Code</Label>
                  </Col>
                  <Col md={8}>
                    {val.coupon_code_type === "generic" ? (
                      <Input
                        type="text"
                        placeholder="Enter Coupon Code"
                        onChange={(e) => this.handleCouponCodeChange(e)}
                      />
                    ) : (
                      <>
                        <Input
                          type="file"
                          name="uploadCSV"
                          onChange={(e) => this.handleCouponCSVChange(e)}
                        />
                      </>
                    )}
                  </Col>
                </FormGroup>
              </Form>
              <button
                onClick={() => {
                  if (val.coupon_code_type !== "generic") {
                    this.handleUploadCSV();
                  } else {
                    if (this.state.coupon_code) {
                      fetch(APIS._addCoupon, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${token}`,
                          "Ocp-Apim-Subscription-Key":
                            "018226f84da242f498a008ae1022430b",
                        },
                        body: JSON.stringify({
                          coupon_id_id: val.id,
                          coupon_code: this.state.coupon_code,
                        }),
                      })
                        .then((response) => response.json())
                        .then((res) => {
                          ToastsStore.success("Coupon Added Successfully");
                          window.location.reload();
                        })
                        .catch((err) => {
                          ToastsStore.error("Add Coupon Failed");
                        });
                    } else {
                      ToastsStore.error("Please Enter Generic Coupon Code");
                    }
                  }
                }}
              >
                {val.coupon_code_type === "generic"
                  ? "Add Coupon"
                  : "Upload CSV"}
              </button>
              <button onClick={onClose}>Cancel</button>
            </div>
          </Container>
        );
      },
    });
  };

  handleDeleteOffer = (coupon_id) => {
    let token = getItemFromLS("token");
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirmAlert">
            <h1>Are you sure?</h1>
            <p>You want to remove this offer?</p>
            <button onClick={onClose}>No</button>
            <button
              onClick={() => {
                fetch(APIS._removeOffer, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Ocp-Apim-Subscription-Key":
                      "018226f84da242f498a008ae1022430b",
                  },
                  body: JSON.stringify({
                    coupon_id,
                  }),
                })
                  .then((response) => response.json())
                  .then((res) => {
                    if (res.Success) {
                      ToastsStore.success("Offer Removed Successfully");
                      window.location.reload();
                    } else {
                      ToastsStore.error("Offer Remove Failed");
                    }
                  })
                  .catch((err) => {
                    ToastsStore.error(err);
                  });
                onClose();
              }}
            >
              Yes, Remove it!
            </button>
          </div>
        );
      },
    });
  };

  render() {
    return (
      <>
        <Header headerText="View Offer" type="mainHeader" />
        <div style={{ margin: "10px" }}>
          <MaterialTable
            title={
              (this.state.partnerName || "") +
              ` Offers (${this.props.location.state.country || this.state.offfer_country})`
            }
            columns={this.columns}
            data={this.state.offers}
            options={{
              headerStyle: {
                backgroundColor: "#245642",
                color: "#FFF",
                textAlign: "center",
              },
              // detailPanelColumnAlignment: 'right',
              search: true,
              // exportButton: true,
              // selection: true,
              detailPanelType: "multiple",
              sorting: true,
              paginationType: "stepped",
              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                icon: "keyboard_arrow_right",
                openIcon: "keyboard_arrow_down",
                tooltip: "Show Offer Details",
                render: (rowData) => {
                  return (
                    <>
                      <ViewOfferDetails offer={rowData.offer} />
                    </>
                  );
                },
              },
            ]}
            actions={[
              {
                icon: "add",
                isFreeAction: true,
                onClick: () => {
                  this.handleAddOfferChanges(this.state.offers[0] || "");
                },
              },
              {
                icon: "edit",
                tooltip: "Edit Offer",
                onClick: (event, rowData) =>
                  this.props.history.push({
                    pathname: "/edit-offer",
                    state: { data: rowData },
                  }),
              },
              {
                icon: "note_add",
                tooltip: "Add Coupon",
                onClick: (event, rowData) => {
                  this.handleAddCoupon(rowData);
                },
              },
              {
                icon: "copyright",
                tooltip: "Edit Coupon",
                onClick: (event, rowData) => {
                  this.props.history.push({
                    pathname: "/edit-coupon",
                    state: { id: rowData.id },
                  });
                },
              },
              jwt(getItemFromLS("token")).userType === "admin"
                ? {
                    icon: "delete",
                    tooltip: "Remove Offer",
                    onClick: (event, rowData) =>
                      this.handleDeleteOffer(rowData.id),
                  }
                : null,
            ]}
          />
        </div>
      </>
    );
  }
}

export default ViewOffer;
