import React, { useState, useReducer, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Alert,
  FormText,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Header from "../../../Header";
import {
  APIS,
  BIDDING_APIS,
  MZAALOREWARDS_APIS,
} from "../../../../Constants/endpoints";
import { getItemFromLS } from "../../../../Utils/localStorage";
import { ToastsStore, ToastsContainer } from "react-toasts";
import jwt from "jwt-decode";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getToken } from "../../../../Functions/userAuth";
import ImageUploader from "react-images-upload";
import BiddingHeader from "../BiddingHeader";
import { useHistory } from "react-router-dom";
import { ISOtoDateTimeLocal } from "../../../../Functions/dateFormats";
import moment from "moment";
import { URL } from "../../../../config";
import { errorToast } from "../../../../Containers/toastFunctions";
import { returnTrue } from "../../../../Functions/commonUtils";
import { ToastContainer } from "react-toastify";

const EditBiddingEvent = (props) => {
  const [alertVisible, setAlertVisible] = useState(true);
  const [isProductImageUploaded, setProductImageUploaded] = useState(false);
  const history = useHistory();
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [biddingEvent, setBiddingEvent] = useReducer(reducer, {
    id: "",
    product_id: "",
    product_details: "",
    start_time: "",
    end_time: "",
    number_of_winners: "",
    winner_rewards: [],
    withdraw_end_time: "",
    priority: 0,
    starting_bid: "",
    event_type: "",
    event_description: "",
    bid_time_value: "",
    bid_time_limit: "",
    deduct_registration: "",
    bid_time_coin_before: "",
    bid_time_coin_after: "",
    is_withdrawable: "",
    coin_id: "",
    is_address: "",
  });

  const [productImages, setProductImages] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [coinTypes, setCoinTypes] = useState();

  const onDismiss = () => {
    setAlertVisible(false);
  };

  const handleUpdateBiddingEvent = (e) => {
    e.preventDefault();

    console.log("herer");

    // Validations
    if (biddingEvent.end_time < biddingEvent.start_time)
      return errorToast("End time should be greater than start time");

    if (
      returnTrue(biddingEvent.is_withdrawable) &&
      (biddingEvent.withdraw_end_time > biddingEvent.end_time ||
        biddingEvent.withdraw_end_time < biddingEvent.start_time)
    )
      return errorToast(
        "Withdraw end time should be in between start time and end time",
      );

    if (!biddingEvent.product_details)
      return errorToast("Product Title cannot be empty");

    if (!biddingEvent.start_time)
      return errorToast("Start time cannot be empty");

    if (!biddingEvent.end_time) return errorToast("End Time cannot be empty");

    if (!biddingEvent.number_of_winners)
      return errorToast("Number of Winners cannot be empty");

    if (!biddingEvent.starting_bid)
      return errorToast("Starting Bid cannot be empty");

    if (!biddingEvent.bid_time_value)
      return errorToast("Bid Time Value cannot be empty");

    if (!biddingEvent.bid_time_limit)
      return errorToast("Bid Time Limit cannot be empty");

    if (!biddingEvent.bid_time_coin_before)
      return errorToast("Bid Time Coin Before cannot be empty");

    if (!biddingEvent.bid_time_coin_after)
      return errorToast("Bid Time Coin After cannot be empty");

    if (!biddingEvent.deduct_registration)
      return errorToast("Deduct Registration cannot be empty");

    if (!biddingEvent.coin_id) return errorToast("Coin Id cannot be empty");

    setLoading(true);

    fetch(BIDDING_APIS._updateBiddingEvent, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${getToken()}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
      body: JSON.stringify(biddingEvent),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res && res.success) {
          ToastsStore.success(res.message);
          // window.location.reload();
        }
      })
      .catch((err) => {
        setLoading(false);
        errorToast(err);
      });
  };

  const onChange = (e) => {
    let { name, value } = e.target;

    if (name === "winner_rewards") value = value.split(",");

    if (
      name === "start_time" ||
      name === "end_time" ||
      name === "withdraw_end_time"
    ) {
      value = value && moment(value, "YYYY-MM-DD HH:mm:ss+000").toISOString();
    }

    if (name === "starting_bid" || name === "number_of_winners")
      value = value && value.replace(/[^0-9]*/g, "");

    setBiddingEvent({ [name]: value });
  };

  const onPIDrop = (picture) => {
    productImages.splice(0, 1);
    setProductImages((productImages) => productImages.concat(picture));
  };

  const handleProductImage = () => {
    if (productImages.length > 0) {
      let data = new FormData();
      for (const image of productImages) {
        data.append("images", image);
      }
      const _URL = window.URL || window.webkitURL;
      var img = new Image();
      img.src = _URL.createObjectURL(productImages[0]);
      img.onload = () => {
        fetch(BIDDING_APIS._uploadMultipleImages, {
          method: "POST",
          headers: {
            "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
          },
          body: data,
        })
          .then((response) => response.json())
          .then((res) => {
            if (res && res.data && res.data.urlArray) {
              setBiddingEvent({ product_images: res.data.urlArray });
              setProductImageUploaded(true);
              ToastsStore.success("Images Uploaded successfully");
            } else {
            }
          })
          .catch((err) => {
            errorToast("Image Upload Failed");
          });
      };
    } else {
      errorToast("Please Upload an Image");
    }
  };

  // Get Rewards Coin Types
  const getCoinTypes = () => {
    fetch(MZAALOREWARDS_APIS._getCoinTypes, {
      method: "GET",
      headers: {
        authorization: URL.user_token,
        "Ocp-Apim-Subscription-Key": URL.mzaalo_ocp_key,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        let coinTypes = res && res.data && res.data.coinData;
        setCoinTypes(coinTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (props.location && props.location.state && props.location.state.data) {
      setBiddingEvent(props.location.state.data.product);
      setBiddingEvent(props.location.state.data.event);
      setBiddingEvent(props.location.state.data.confs);
    }

    getCoinTypes();
  }, []);

  return (
    <>
      <Header headerText="Edit Bidding Event" type="mainHeader" />
      <BiddingHeader />
      <Container style={{ marginTop: "10px" }}>
        <Alert color="info" isOpen={alertVisible} toggle={onDismiss}>
          All <span className="required">*</span> fields are mandatory. To
          Update Bidding Event, Please fill all the details.
        </Alert>
        <Form
          onSubmit={handleUpdateBiddingEvent}
          style={{
            border: "1px solid gainsboro",
            borderRadius: "5px",
            padding: "20px",
            margin: "10px",
          }}
        >
          <Container className="bidding-outer-container">
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="product_details">
                    Product Title<span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="product_details"
                    id="product_details"
                    placeholder="Enter Product Title"
                    required
                    maxlength="25"
                    onChange={onChange}
                    value={biddingEvent.product_details || ""}
                  />
                  <FormText>Limit the text to maximum 25 characters.</FormText>
                </FormGroup>
              </Col>
            </Row>
            {/* <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="product_images">Product Image<span className="required">*</span></Label>
                    <Input type="text" name="product_images" id="product_images" required placeholder="Upload Product Image (Aspect Ratio 1:1)" onChange={onChange} value={biddingEvent.product_images || ''} />
                  </FormGroup>
                </Col>
                <Col md={10}>
                <ImageUploader
                    withIcon={true}
                    buttonText='Choose images'
                    onChange={onPIDrop}
                    withPreview={true}
                    singleImage={false}
                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                    maxFileSize={5242880}
                    fileSizeError="File size is too big"
                />
                </Col>
                <Col md={2} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <Button id="companyLogo" color="warning" onClick={handleProductImage}>
                    { isProductImageUploaded ? 'Upload Again' : 'Upload'}
                    </Button>
                </Col>
              </Row> */}
          </Container>
          <Container className="bidding-outer-container">
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="start_time">
                    Start Time<span className="required">*</span>
                  </Label>
                  <Input
                    type="datetime-local"
                    name="start_time"
                    id="start_time"
                    min="2021-01-01"
                    max="2025-01-01"
                    placeholder="Enter Start Time"
                    required
                    onChange={onChange}
                    value={ISOtoDateTimeLocal(biddingEvent.start_time) || ""}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="end_time">
                    End Time<span className="required">*</span>
                  </Label>
                  <Input
                    type="datetime-local"
                    name="end_time"
                    id="end_time"
                    min="2021-01-01"
                    max="2025-01-01"
                    placeholder="Enter End Time"
                    required
                    onChange={onChange}
                    value={ISOtoDateTimeLocal(biddingEvent.end_time) || ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label for="is_address">
                    Require Address?<span className="required">*</span>
                  </Label>
                  <CustomInput
                    type="select"
                    name="is_address"
                    id="is_address"
                    placeholder="Require Address?"
                    required
                    onChange={onChange}
                  >
                    <option value="">Event Requires Address?</option>
                    <option
                      selected={biddingEvent.is_address === true}
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      selected={biddingEvent.is_address === false}
                      value="false"
                    >
                      No
                    </option>
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="starting_bid">
                    Starting Bid<span className="required">*</span>
                  </Label>
                  <Input
                    type="number"
                    onKeyDown={(event) =>
                      event.key === "." ? event.preventDefault() : ""
                    }
                    name="starting_bid"
                    id="starting_bid"
                    placeholder="Enter Starting Bid"
                    required
                    onChange={onChange}
                    value={biddingEvent.starting_bid || ""}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="priority">Priority</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{ cursor: "pointer" }}>
                        <Tooltip
                          placement="top"
                          title="Priority should be a number. Default is 0.
                          To make an offer show prior to another offer it should be provided a priority greater than others. i.e. 1
                          There could be more offer with the same priority order.
                          One level of sorting of offer is on the priority field and second level of sorting is based on discount rate.
                          "
                        >
                          <InfoIcon />
                        </Tooltip>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      name="priority"
                      id="priority"
                      placeholder="Enter Priority"
                      onChange={onChange}
                      value={biddingEvent.priority || ""}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="number_of_winners">
                    Number of Winners<span className="required">*</span>
                  </Label>
                  <Input
                    type="number"
                    onKeyDown={(event) =>
                      event.key === "." ? event.preventDefault() : ""
                    }
                    name="number_of_winners"
                    id="number_of_winners"
                    required
                    placeholder="Enter Number of Winners"
                    onChange={onChange}
                    value={biddingEvent.number_of_winners || ""}
                  />
                </FormGroup>
              </Col>
              {/* <Col md={4}>
                  <FormGroup>
                    <Label for="is_active">Status<span className="required">*</span></Label>
                    <CustomInput type="select" name="is_active" id="is_active" placeholder="Select Status" required onChange={onChange} >
                      <option value="">Select Status</option>
                      <option selected={biddingEvent.is_active === true} value="true">Active</option>
                      <option selected={biddingEvent.is_active === false} value="false">Disable</option>
                    </CustomInput>
                  </FormGroup>
                </Col> */}
              <Col md={6}>
                <FormGroup>
                  <Label for="winner_rewards">Winner Rewards</Label>
                  <Input
                    type="text"
                    name="winner_rewards"
                    id="winner_rewards"
                    placeholder="Enter Winner Rewards"
                    onChange={onChange}
                  />
                  <FormText>Example: 100, 10, 5</FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="event_description">Event Description</Label>
                  <Input
                    type="textarea"
                    name="event_description"
                    id="event_description"
                    placeholder="Enter Event Details"
                    onChange={onChange}
                    value={biddingEvent.event_description || ""}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
          <Container className="bidding-outer-container">
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label for="bid_time_value">
                    Bid Time Value<span className="required">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="bid_time_value"
                    id="bid_time_value"
                    placeholder="Enter Bid Time Value"
                    required
                    onChange={onChange}
                    value={biddingEvent.bid_time_value || ""}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="bid_time_limit">
                    Bid Time Limit<span className="required">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="bid_time_limit"
                    id="bid_time_limit"
                    required
                    placeholder="Enter Bid Time Limit"
                    onChange={onChange}
                    value={biddingEvent.bid_time_limit || ""}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="deduct_registration">
                    Deduct Registration Value<span className="required">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="deduct_registration"
                    id="deduct_registration"
                    required
                    placeholder="Enter Deduct Registration Value"
                    onChange={onChange}
                    value={biddingEvent.deduct_registration || ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label for="bid_time_coin_before">
                    Bid Time Coin Before<span className="required">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="bid_time_coin_before"
                    id="bid_time_coin_before"
                    placeholder="Enter Bid Time Coin Before"
                    required
                    onChange={onChange}
                    value={biddingEvent.bid_time_coin_before || ""}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="bid_time_coin_after">
                    Bid Time Coin After<span className="required">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="bid_time_coin_after"
                    id="bid_time_coin_after"
                    required
                    placeholder="Enter Bid Time Coin After"
                    onChange={onChange}
                    value={biddingEvent.bid_time_coin_after || ""}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="coin_id">
                    Coin Type<span className="required">*</span>
                  </Label>
                  <CustomInput
                    type="select"
                    name="coin_id"
                    id="coin_id"
                    placeholder="Select Coin Type"
                    required
                    onChange={onChange}
                  >
                    <option value="">Select Coin type</option>
                    {coinTypes &&
                      coinTypes.map((ct) => {
                        return (
                          <option
                            selected={biddingEvent.coin_id === ct.id}
                            value={ct.id}
                          >{`${ct.name} - ${ct.coin_type}`}</option>
                        );
                      })}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={4}>
                <FormGroup>
                  <Label for="is_withdrawable">
                    Withdraw Status<span className="required">*</span>
                  </Label>
                  <CustomInput
                    type="select"
                    name="is_withdrawable"
                    id="is_withdrawable"
                    placeholder="Select Withdraw Status"
                    required
                    onChange={onChange}
                  >
                    <option value="">Select Withdraw Status</option>
                    <option
                      selected={biddingEvent.is_withdrawable === true}
                      value={"true"}
                    >
                      Yes
                    </option>
                    <option
                      selected={biddingEvent.is_withdrawable === false}
                      value={"false"}
                    >
                      No
                    </option>
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="withdraw_end_time">
                    Withdraw End Time
                    <span className="required">{`${returnTrue(biddingEvent.is_withdrawable) ? "*" : ""}`}</span>
                  </Label>
                  <Input
                    type="datetime-local"
                    disabled={!returnTrue(biddingEvent.is_withdrawable)}
                    required
                    name="withdraw_end_time"
                    id="withdraw_end_time"
                    placeholder="Enter Withdraw End Time"
                    onChange={onChange}
                    value={
                      ISOtoDateTimeLocal(biddingEvent.withdraw_end_time) || ""
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>
          <Button color="primary" disabled={isLoading}>
            Edit Changes
          </Button>
        </Form>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <ToastsContainer store={ToastsStore} />
    </>
  );
};

export default EditBiddingEvent;
