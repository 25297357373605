import React from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastsStore, ToastsContainer } from "react-toasts";
import { Link } from "react-router-dom";
import jwt from "jwt-decode";
import Popup from "reactjs-popup";
import { Avatar } from "@material-ui/core";
import { useAuth } from "../Contexts/Auth";
import { ImageContainer } from "../Styles/base";
import Logo from "../Images/Logo.png";
import { getItemFromLS } from "../Utils/localStorage";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { isAdmin, notPartner } from "../Functions/userAuth";

export default function Header(props) {
  const { setAuthTokens } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleLogout = () => {
    setAuthTokens();
    localStorage.clear();
    ToastsStore.success("Logged Out");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* pathname: jwt(getItemFromLS("token")).userType === "admin" ? '/' : '/view-offers' */}
      <div
        style={{
          overflow: props.type === "loginHeader" ? "hidden" : "visible",
        }}
      >
        <Row style={{ background: "#2c4b95" }}>
          {props.type === "mainHeader" ? (
            <>
              <Col style={{ display: "flex" }}>
                <Link to="/">
                  <ImageContainer style={{ margin: "10px" }} src={Logo} />
                </Link>
                <h2 style={{ margin: "20px", color: "#ffffff" }}>
                  {props.headerText}
                </h2>
              </Col>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <Avatar
                    aria-label="profile"
                    src="https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/10_avatar-512.png"
                  >
                    A
                  </Avatar>
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    Hi,{" "}
                    {jwt(getItemFromLS("token")).userType === "partner"
                      ? "Partner"
                      : "Admin"}
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleClose}>
                    <Link to="/settings" className="linkDecoration">
                      Change Password
                    </Link>
                  </MenuItem>
                  {isAdmin() ? (
                    <div>
                      <MenuItem onClick={handleClose}>
                        <Link to="/partner-mapping" className="linkDecoration">
                          Map Partner
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Link to="/generate-report" className="linkDecoration">
                          Generate Report
                        </Link>
                      </MenuItem>
                    </div>
                  ) : null}
                  {notPartner() ? (
                    <MenuItem onClick={handleClose}>
                      <Link to="/bulk-upload" className="linkDecoration">
                        Bulk Upload
                      </Link>
                    </MenuItem>
                  ) : null}
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Col>
            </>
          ) : (
            <Col style={{ display: "flex" }}>
              <Link to="/">
                <ImageContainer style={{ margin: "10px" }} src={Logo} />
              </Link>
              <h2 style={{ margin: "20px", color: "#ffffff" }}>
                {props.headerText}
              </h2>
            </Col>
          )}
        </Row>
        <ToastsContainer store={ToastsStore} />
      </div>
    </>
  );
}
