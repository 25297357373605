import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import "./Cards.scss";

const useStyles = makeStyles({
  root: {
    maxWidth: 360,
    minWidth: 360,
    margin: 20,
  },
  media: {
    height: 160,
  },
});

const HomeCard = (props) => {
  const classes = useStyles();

  const { name, path, description, imageUrl } = props.details;

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <Link to={path} className="link-decoration">
          <CardMedia className={classes.media} image={imageUrl} title={name} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          <Link to={path}>Explore {name}</Link>
        </Button>
      </CardActions>
    </Card>
  );
};

export default HomeCard;
