import React, { Component } from "react";
import CsvDownloader from "react-csv-downloader";
import { Button } from "reactstrap";

export default class CSVDownloader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  columns = [
    {
      id: "id",
      displayName: "Coupon Id",
    },
    {
      id: "coupon_code",
      displayName: "Coupon Code",
    },
    {
      id: "created",
      displayName: "Created At",
    },
    {
      id: "updated",
      displayName: "Updated At",
    },
  ];

  render() {
    return (
      <>
        <CsvDownloader
          filename={`${this.props.company_name} Unique Coupon Codes`}
          columns={this.columns}
          datas={this.props.data}
        >
          <Button color="warning">Download CSV</Button>
        </CsvDownloader>
      </>
    );
  }
}
