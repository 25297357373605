import React, { Component } from "react";
import { ToastsContainer, ToastsStore } from "react-toasts";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  GridListTile,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { APIS } from "../../Constants/endpoints";
import { getItemFromLS } from "../../Utils/localStorage";
import "./Cards.scss";

class DataCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      searchText: props.searchText,
    };
  }

  getItems() {
    let token = getItemFromLS("token");
    fetch(APIS._getBrands, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((items) => this.setState({ items }))
      .catch((err) => console.log(err));
  }

  componentDidMount() {
    this.getItems();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchText !== this.props.searchText) {
      this.setState({ searchText: this.props.searchText });
    }
  }

  render() {
    const { searchText } = this.state;
    const filtered = this.state.items.filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase()),
    );

    return (
      <>
        <div className="flex flex-wrap">
          {filtered.length === 0 ? (
            <Typography variant="h6" color="textSecondary">
              No Records Found
            </Typography>
          ) : (
            filtered.map((v) => (
              <GridListTile
                key={v.id}
                style={{ height: "auto", listStyleType: "none" }}
              >
                <Card className="cardRoot">
                  <Link
                    to={{
                      pathname: v.is_deleted ? "" : "/view-offers",
                      state: {
                        name: v.name,
                        company_logo: v.company_logo,
                        id: v.id,
                        country: v.country,
                      },
                    }}
                    className="linkDecoration"
                  >
                    <CardActionArea
                      className={v.is_deleted === true ? "inactiveCard" : ""}
                    >
                      <CardMedia
                        component="img"
                        alt={v.name}
                        height="140px"
                        width="180px"
                        style={{ objectFit: "contain", padding: "1rem" }}
                        image={v.company_logo}
                        title={v.name}
                      />
                      <CardContent
                        style={{
                          background:
                            "linear-gradient(135deg, #fafafa 0%, #eee 100%)",
                        }}
                      >
                        <Typography gutterBottom variant="h5" component="h2">
                          {v.name}
                        </Typography>
                        <Typography
                          className="flexContent"
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          <h6 style={{ fontSize: "0.9rem", color: "green" }}>
                            {" "}
                            Live Offers: {v.active}{" "}
                          </h6>
                          <h6 style={{ fontSize: "0.9rem", color: "red" }}>
                            {" "}
                            Inactive Offers: {v.inactive}{" "}
                          </h6>
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Link>
                  <CardActions className="cardActions">
                    <Link
                      to={{ pathname: "/edit-partner", state: { data: v } }}
                      style={{ textDecoration: "none" }}
                    >
                      Edit
                    </Link>
                    <Link
                      to={{ pathname: "/partner-details", state: { data: v } }}
                      style={{ textDecoration: "none" }}
                    >
                      Details
                    </Link>
                    <Link
                      to={{
                        pathname: "/view-offers",
                        state: {
                          name: v.name,
                          company_logo: v.company_logo,
                          id: v.id,
                          country: v.country,
                        },
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      View Offers
                    </Link>
                  </CardActions>
                </Card>
              </GridListTile>
            ))
          )}
        </div>
        <ToastsContainer store={ToastsStore} />
      </>
    );
  }
}

export default DataCard;
