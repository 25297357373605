import { getItemFromLS } from "../Utils/localStorage";
import jwt from "jwt-decode";

export const isAdmin = () => {
  return jwt(getItemFromLS("token")).userType === "admin";
};

export const notPartner = () => {
  return jwt(getItemFromLS("token")).userType !== "partner";
};

export const getToken = () => {
  return getItemFromLS("token");
};
