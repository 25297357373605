import React, { useState, useReducer, useEffect } from "react";
import Header from "../../../Header";
import {
  Container,
  Alert,
  FormGroup,
  Col,
  Label,
  Input,
  FormText,
  Row,
  CustomInput,
  Form,
  Button,
} from "reactstrap";
import BiddingHeader from "../BiddingHeader";
import { getToken } from "../../../../Functions/userAuth";
import {
  BIDDING_APIS,
  MZAALOREWARDS_APIS,
} from "../../../../Constants/endpoints";
import axios from "axios";
import { URL } from "../../../../config";
import {
  errorToast,
  successToast,
} from "../../../../Containers/toastFunctions";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const EditBiddingVoucher = (props) => {
  const [alertVisible, setAlertVisible] = useState(true);
  const [coinTypes, setCoinTypes] = useState();
  const [isBidRegistration, setBidRegistration] = useState(false);
  const [isBidLowBalance, setBidLowBalance] = useState(false);
  const [eventId, setEventId] = useState("");
  const [voucherId, setVoucherId] = useState("");
  const [isLoading, setLoading] = useState(false);

  const history = useHistory();

  const reducer = (state, newState) => ({ ...state, ...newState });

  const [bidRegistrationData, setBidRegistrationData] = useReducer(reducer, {
    voucher_type_id: "",
    voucher_id: "",
    type: "bid_registration",
    offer_type: "",
    offer_type_id: "",
    offer_values: [],
    times_per_user: 0,
    limit: 0,
    give_values: [],
    is_active: true,
  });

  const [bidLowBalanceData, setBidLowBalanceData] = useReducer(reducer, {
    voucher_type_id: "",
    voucher_id: "",
    type: "bid_low_balance",
    offer_type: "",
    offer_type_id: "",
    offer_values: [],
    times_per_user: 0,
    limit: 0,
    give_values: [],
    is_active: true,
  });

  const onDismiss = () => {
    setAlertVisible(false);
  };

  const onBidRegistrationDataChange = (e) => {
    let { name, value } = e.target;

    if (name === "offer_values" || name === "give_values")
      value = value.split(",");

    if (name === "limit" || name === "times_per_user")
      value = value && value.replace(/[^0-9]*/g, "");

    setBidRegistrationData({ [name]: value });

    if (name === "offer_type_id") setOfferType(value);
  };

  const onBidLowBalanceDataChange = (e) => {
    let { name, value } = e.target;

    if (name === "offer_values" || name === "give_values")
      value = value.split(",");

    if (name === "limit" || name === "times_per_user")
      value = value && value.replace(/[^0-9]*/g, "");

    setBidLowBalanceData({ [name]: value });

    if (name === "offer_type_id") setOfferType(value);
  };

  const setOfferType = (id) => {
    setBidRegistrationData({
      offer_type: coinTypes
        .filter((ct) => ct.id === id)
        .map((v) => v.coin_type)[0],
    });
    setBidLowBalanceData({
      offer_type: coinTypes
        .filter((ct) => ct.id === id)
        .map((v) => v.coin_type)[0],
    });
  };

  // create/edit voucher data
  const handleVoucherData = (e) => {
    e.preventDefault();

    if (!isBidRegistration && !isBidLowBalance)
      return errorToast("Please select any one Voucher Type");

    setLoading(true);
    let voucherTypeData = [];

    if (isBidRegistration)
      voucherTypeData.push({
        voucher_type_id: bidRegistrationData.voucher_type_id,
        voucher_id: bidRegistrationData.voucher_id,
        type: bidRegistrationData.type,
        offer_type: bidRegistrationData.offer_type,
        offer_type_id: bidRegistrationData.offer_type_id,
        offer_values: bidRegistrationData.offer_values,
        times_per_user: parseInt(bidRegistrationData.times_per_user),
        limit: parseInt(bidRegistrationData.limit),
        give_values: bidRegistrationData.give_values,
        is_active: true,
      });

    if (isBidLowBalance)
      voucherTypeData.push({
        voucher_type_id: bidLowBalanceData.voucher_type_id,
        voucher_id: bidLowBalanceData.voucher_id,
        type: bidLowBalanceData.type,
        offer_type: bidLowBalanceData.offer_type,
        offer_type_id: bidLowBalanceData.offer_type_id,
        offer_values: bidLowBalanceData.offer_values,
        times_per_user: parseInt(bidLowBalanceData.times_per_user),
        limit: parseInt(bidLowBalanceData.limit),
        give_values: bidLowBalanceData.give_values,
        is_active: true,
      });

    const voucherData = {
      voucher_id: voucherId,
      type: "bidding_event",
      type_id: eventId,
      voucherTypeData,
    };

    fetch(BIDDING_APIS._createVoucherData, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `${getToken("token")}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
      body: JSON.stringify(voucherData),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res && res.success) {
          setLoading(false);
          successToast(res.message);
          window.location.reload();
        } else {
          errorToast(res.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorToast(err);
      });
  };

  // Get Voucher List
  const getVoucherList = () => {
    eventId &&
      axios
        .get(`${BIDDING_APIS._getAdminVoucherList}?type_id=${eventId}`, {
          headers: {
            Authorization: `${getToken()}`,
            "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
          },
        })
        .then((res) => {
          if (res) {
            let data = res && res.data && res.data.data[0];
            setVoucherId(data.id);
            data.VoucherTypes.map((dt) => {
              return dt.type === "bid_registration" ? (
                <>
                  {setBidRegistration(true)}
                  {setBidRegistrationData(dt)}
                  {setBidRegistrationData({ voucher_type_id: dt.id })}
                </>
              ) : dt.type === "bid_low_balance" ? (
                <>
                  {setBidLowBalance(true)}
                  {setBidLowBalanceData(dt)}
                  {setBidLowBalanceData({ voucher_type_id: dt.id })}
                </>
              ) : (
                ""
              );
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const handleBidRegistration = () => {
    setBidRegistration(!isBidRegistration);
  };

  const handleBidLowBalance = () => {
    setBidLowBalance(!isBidLowBalance);
  };

  // Get Rewards Coin Types
  const getCoinTypes = () => {
    eventId &&
      fetch(MZAALOREWARDS_APIS._getCoinTypes, {
        method: "GET",
        headers: {
          authorization: URL.user_token,
          "Ocp-Apim-Subscription-Key": URL.mzaalo_ocp_key,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          let coinTypes = res && res.data && res.data.coinData;
          setCoinTypes(coinTypes);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  useEffect(() => {
    setEventId(props.location.pathname.split("/").pop());
    getVoucherList();
    getCoinTypes();
  }, [eventId]);

  return (
    <div>
      <Header type="mainHeader" headerText="Create/Edit Bidding Voucher Data" />
      <BiddingHeader />
      <Container style={{ marginTop: "10px" }}>
        <Alert color="info" isOpen={alertVisible} toggle={onDismiss}>
          All <span className="required">*</span> fields are mandatory. To
          Create/Update Bidding Voucher Data, Please fill all the details.
        </Alert>
        <Form
          onSubmit={handleVoucherData}
          style={{
            border: "1px solid gainsboro",
            borderRadius: "5px",
            padding: "20px",
            margin: "10px",
          }}
        >
          <Container className="bidding-outer-container">
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <CustomInput
                    type="checkbox"
                    id={"bid_registration"}
                    onChange={handleBidRegistration}
                    label={"Bidding Registration Voucher"}
                    checked={isBidRegistration}
                  />
                </FormGroup>
              </Col>
            </Row>
            {isBidRegistration && (
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="limit">
                      Bidding Registration Voucher Limit
                      <span className="required">*</span>
                    </Label>
                    <Input
                      type="number"
                      onKeyDown={(event) =>
                        event.key === "." ? event.preventDefault() : ""
                      }
                      name="limit"
                      id="limit"
                      required
                      placeholder="Enter Voucher Limit"
                      onChange={onBidRegistrationDataChange}
                      value={bidRegistrationData.limit || ""}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="times_per_user">
                      Voucher Times per User<span className="required">*</span>
                    </Label>
                    <Input
                      type="number"
                      onKeyDown={(event) =>
                        event.key === "." ? event.preventDefault() : ""
                      }
                      name="times_per_user"
                      id="times_per_user"
                      required
                      placeholder="Enter Times Per User"
                      onChange={onBidRegistrationDataChange}
                      value={bidRegistrationData.times_per_user || ""}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="offer_type_id">
                      Voucher Offer Type<span className="required">*</span>
                    </Label>
                    <CustomInput
                      type="select"
                      name="offer_type_id"
                      id="offer_type_id"
                      placeholder="Select Offer Type"
                      required
                      onChange={onBidRegistrationDataChange}
                    >
                      <option value="">Select Offer type</option>
                      {coinTypes &&
                        coinTypes.map((ct) => {
                          return (
                            <option
                              selected={
                                ct.id === bidRegistrationData.offer_type_id
                              }
                              value={ct.id}
                            >{`${ct.name} - ${ct.coin_type}`}</option>
                          );
                        })}
                    </CustomInput>
                  </FormGroup>
                  <FormGroup>
                    <Label for="offer_values">
                      Bidding Registration Voucher Offer Values
                      <span className="required">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="offer_values"
                      id="offer_values"
                      placeholder="Enter Offer Values"
                      required
                      onChange={onBidRegistrationDataChange}
                      value={bidRegistrationData.offer_values || ""}
                    />
                    <FormText>
                      Comma Separated Values. Example: 1,2,3,4,5
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for="give_values">
                      Bidding Registration Voucher Give Values
                      <span className="required">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="give_values"
                      id="give_values"
                      placeholder="Enter Give Values"
                      required
                      onChange={onBidRegistrationDataChange}
                      value={bidRegistrationData.give_values || ""}
                    />
                    <FormText>
                      Comma Separated Values. Example: 2,4 (For Registration :
                      0)
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </Container>
          <Container className="bidding-outer-container">
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <CustomInput
                    type="checkbox"
                    id={"bid_low_balance"}
                    onChange={handleBidLowBalance}
                    label={"Bidding Low Balance Voucher"}
                    checked={isBidLowBalance}
                  />
                </FormGroup>
              </Col>
            </Row>
            {isBidLowBalance && (
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Label for="limit">
                      Bidding Low Balance Voucher Limit
                      <span className="required">*</span>
                    </Label>
                    <Input
                      type="number"
                      onKeyDown={(event) =>
                        event.key === "." ? event.preventDefault() : ""
                      }
                      name="limit"
                      id="limit"
                      required
                      placeholder="Enter Voucher Limit"
                      onChange={onBidLowBalanceDataChange}
                      value={bidLowBalanceData.limit || ""}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="times_per_user">
                      Voucher Times per User<span className="required">*</span>
                    </Label>
                    <Input
                      type="number"
                      onKeyDown={(event) =>
                        event.key === "." ? event.preventDefault() : ""
                      }
                      name="times_per_user"
                      id="times_per_user"
                      required
                      placeholder="Enter Times Per User"
                      onChange={onBidLowBalanceDataChange}
                      value={bidLowBalanceData.times_per_user || ""}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="offer_type_id">
                      Voucher Offer Type<span className="required">*</span>
                    </Label>
                    <CustomInput
                      type="select"
                      name="offer_type_id"
                      id="offer_type_id"
                      placeholder="Select Offer Type"
                      required
                      onChange={onBidLowBalanceDataChange}
                    >
                      <option value="">Select Offer type</option>
                      {coinTypes &&
                        coinTypes.map((ct) => {
                          return (
                            <option
                              selected={
                                ct.id === bidLowBalanceData.offer_type_id
                              }
                              value={ct.id}
                            >{`${ct.name} - ${ct.coin_type}`}</option>
                          );
                        })}
                    </CustomInput>
                  </FormGroup>
                  <FormGroup>
                    <Label for="offer_values">
                      Bidding Low Balance Voucher Offer Values
                      <span className="required">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="offer_values"
                      id="offer_values"
                      placeholder="Enter Offer Values"
                      required
                      onChange={onBidLowBalanceDataChange}
                      value={bidLowBalanceData.offer_values || ""}
                    />
                    <FormText>
                      Comma Separated Values. Example: 1,2,3,4,5
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for="give_values">
                      Bidding Low Balance Voucher Give Values
                      <span className="required">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="give_values"
                      id="give_values"
                      placeholder="Enter Give Values"
                      required
                      onChange={onBidLowBalanceDataChange}
                      value={bidLowBalanceData.give_values || ""}
                    />
                    <FormText>Comma Separated Values. Example: 2,4</FormText>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </Container>
          <Button color="primary" disabled={isLoading}>
            Submit
          </Button>
        </Form>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </div>
  );
};

export default EditBiddingVoucher;
