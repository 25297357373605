import React from "react";
import PromoCodeMenuCard from "./PromoCodeMenuCard";
import Header from "../../Header";
import "./PromoCode.scss";
import { Link } from "react-router-dom";

const PromoCode = () => {
  return (
    <>
      <Header type="mainHeader" headerText="PromoCode Home" />
      <div className="promocode-main flex flex-wrap justify-center align-center">
        <Link target="_blank" className="linkDecoration" to="/promocode/view">
          <PromoCodeMenuCard>
            <div className="promocode-main-title"> View PromoCodes </div>
          </PromoCodeMenuCard>
        </Link>
        <Link target="_blank" className="linkDecoration" to="/promocode/create">
          <PromoCodeMenuCard>
            <div className="promocode-main-title"> Create New PromoCode </div>
          </PromoCodeMenuCard>
        </Link>
        <Link
          target="_blank"
          className="linkDecoration"
          to="/promocode/partner/view"
        >
          <PromoCodeMenuCard>
            <div className="promocode-main-title"> View Partner List </div>
          </PromoCodeMenuCard>
        </Link>
        <Link
          target="_blank"
          className="linkDecoration"
          to="/promocode/partner/create"
        >
          <PromoCodeMenuCard>
            <div className="promocode-main-title"> Create New Partner </div>
          </PromoCodeMenuCard>
        </Link>
        <Link
          target="_blank"
          className="linkDecoration"
          to="/promocode/campaign/view"
        >
          <PromoCodeMenuCard>
            <div className="promocode-main-title"> View Campaigns </div>
          </PromoCodeMenuCard>
        </Link>
        <Link
          target="_blank"
          className="linkDecoration"
          to="/promocode/campaign/create"
        >
          <PromoCodeMenuCard>
            <div className="promocode-main-title"> Create New Campaign </div>
          </PromoCodeMenuCard>
        </Link>
        <Link target="_blank" className="linkDecoration" to="/promocode/report">
          <PromoCodeMenuCard>
            <div className="promocode-main-title">
              {" "}
              Generate Promocode Report{" "}
            </div>
          </PromoCodeMenuCard>
        </Link>
      </div>
    </>
  );
};

export default PromoCode;
