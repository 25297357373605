import React, { useState } from "react";
import "./Styles/base.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
// import Login from './Components/Login';
import Home from "./Components/Home";
import ViewOffer from "./Components/Offers/ViewOffer";
import PrivateRoute from "./Components/PrivateRoute";
import Login from "./Components/Login";
import { AuthContext } from "./Contexts/Auth";
import { getItemFromLS, setItemInLS } from "./Utils/localStorage";
import jwt from "jwt-decode";
import AddPartner from "./Components/Partners/AddPartner";
import EditPartner from "./Components/Partners/EditPartner";
import AddOffer from "./Components/Offers/AddOffer";
import EditOffer from "./Components/Offers/EditOffer";
import Error403 from "./Components/Error Page/Error403";
import Error404 from "./Components/Error Page/Error404";
import ChangePassword from "./Components/ChangePassword";
import PartnerDetails from "./Components/Partners/PartnerDetails";
import EditCoupon from "./Components/Coupons/EditCoupon";
import RegisterUser from "./Components/RegisterUser";
import PartnerMapping from "./Components/Partners/PartnerMapping";
import GenerateReport from "./Components/Report";
import { notPartner } from "./Functions/userAuth";
import BulkUpload from "./Components/BulkUpload";
import BiddingHome from "./Components/Services/Bidding";
import CreateBiddingEvent from "./Components/Services/Bidding/BiddingEvent/CreateBiddingEvent";
import EditBiddingEvent from "./Components/Services/Bidding/BiddingEvent/EditBiddingEvent";
import { ServiceRoutes } from "./Utils/helpers/routes";
import RedemptionReport from "./Components/Services/RewardPartner/RedemptionReport";
import PartnerCategory from "./Components/Services/RewardPartner/RewardsCategory";
import UpdateRewardCategory from "./Components/Services/RewardPartner/UpdateRewardCategory";

export default function App(props) {
  const existingTokens = getItemFromLS("token");
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const setTokens = (data) => {
    setItemInLS("token", data);
    setAuthTokens(data);
  };

  const userType = existingTokens && jwt(existingTokens).userType;

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <Switch>
          <Route
            exact
            path={"/login"}
            render={(props) => <Login {...props} />}
          />
          <PrivateRoute exact path={"/"} component={Home} />
          <PrivateRoute exact path={"/reward-partner"} component={Home} />
          {/* <PrivateRoute exact path={"/bidding"} component={BiddingHome} />
            <PrivateRoute exact path={"/create-bidding-event"} component={CreateBiddingEvent} />
            <PrivateRoute exact path={"/edit-bidding-event"} component={EditBiddingEvent} /> */}
          <PrivateRoute
            exact
            path={"/add-partner"}
            component={userType !== "partner" ? AddPartner : Error403}
          />
          <PrivateRoute
            exact
            path={"/rewards-category"}
            component={PartnerCategory}
          />
          <PrivateRoute
            exact
            path={
              "/update-reward-category/:partner/:clientId/:clientName/:id/:name"
            }
            component={UpdateRewardCategory}
          />
          <PrivateRoute
            exact
            path={"/redemption-report"}
            component={userType !== "partner" ? RedemptionReport : Error403}
          />
          <PrivateRoute exact path={"/edit-partner"} component={EditPartner} />
          <PrivateRoute exact path={"/add-offer"} component={AddOffer} />
          <PrivateRoute exact path={"/view-offers"} component={ViewOffer} />
          <PrivateRoute exact path={"/edit-offer"} component={EditOffer} />
          <PrivateRoute exact path={"/edit-coupon"} component={EditCoupon} />
          <PrivateRoute exact path={"/settings"} component={ChangePassword} />
          <PrivateRoute
            exact
            path={"/partner-details"}
            component={PartnerDetails}
          />
          <PrivateRoute
            exact
            path={"/register-user"}
            component={RegisterUser}
          />
          <PrivateRoute
            exact
            path={"/partner-mapping"}
            component={userType === "admin" ? PartnerMapping : Error403}
          />
          <PrivateRoute
            exact
            path={"/generate-report"}
            component={userType === "admin" ? RedemptionReport : Error403}
          />
          <PrivateRoute
            exact
            path={"/bulk-upload"}
            component={userType !== "partner" ? BulkUpload : Error403}
          />

          {/* Service Routes */}
          {ServiceRoutes.map((routes) => (
            <PrivateRoute
              exact
              key={routes.path}
              path={routes.path}
              component={routes.component}
            />
          ))}

          <Route path="/page-not-found" component={Error404} />
          <Redirect to="/page-not-found" />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}
