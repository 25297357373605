import React from "react";
import "./CampusAmbassador.scss";

const AnalyticCircle = (props) => {
  return (
    <div className="circle flex flex-justify-center flex-align-center">
      <div className="flex flex-column flex-justify-center flex-align-center">
        {props.children}
      </div>
    </div>
  );
};

export default AnalyticCircle;
