import React, { useState, useEffect } from "react";
import Header from "../../Header";
import { BIDDING_APIS } from "../../../Constants/endpoints";
import { getToken } from "../../../Functions/userAuth";
import "../../Common/flex.scss";
import { URL } from "../../../config";
import MaterialTable from "material-table";
import { convertDateToString } from "../../../Functions/dateFormats";

const MzaaloSubscriber = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const getSubscribersList = () => {
    setLoading(true);
    fetch(BIDDING_APIS._getSubscribersList, {
      method: "GET",
      headers: {
        authorization: `Bearer ${getToken()}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        let data = res && res.data && res.data.data;
        setLoading(false);
        if (data) {
          setData(data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  let columns = [
    {
      field: "id",
      title: "Support Id",
      hidden: false,
      export: true,
    },
    {
      field: "email",
      title: "User Email",
      hidden: false,
      export: true,
    },
    {
      field: "platform",
      title: "Subscriber Origin",
      hidden: false,
      export: true,
    },
    {
      field: "createdAt",
      title: "Created At",
      hidden: false,
      export: true,
      render: (rowData) => convertDateToString(rowData.createdAt),
    },
    {
      field: "updatedAt",
      title: "Updated At",
      hidden: true,
      export: true,
    },
  ];

  useEffect(() => {
    getSubscribersList();
  }, []);

  return (
    <>
      <Header headerText="Mzaalo Subscribers" type="mainHeader" />
      <MaterialTable
        title={"Subscriber List"}
        columns={columns}
        data={data}
        options={{
          headerStyle: {
            backgroundColor: "#245642",
            color: "#FFF",
            //   textAlign: 'center',
          },
          search: true,
          exportButton: true,
          // selection: true,
          // detailPanelType: "multiple",
          sorting: true,
          paginationType: "stepped",
          actionsColumnIndex: -1,
        }}
      />
    </>
  );
};

export default MzaaloSubscriber;
