import React from "react";

const PromoCodeMenuCard = (props) => {
  return (
    <div className="promocode-menucard flexbox-center cursor-pointer">
      {props.children}
    </div>
  );
};

export default PromoCodeMenuCard;
