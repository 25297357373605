import React from "react";
import Header from "../Header";

const GenerateReport = () => {
  return (
    <>
      <Header type="mainHeader" headerText="Generate Report" />
    </>
  );
};

export default GenerateReport;
