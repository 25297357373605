import React, { useEffect, useState } from "react";
import Typist from "react-typist";
import { getItemFromLS } from "../../Utils/localStorage";
import { APIS } from "../../Constants/endpoints";
import { PartnerHomeContainer } from "../../Styles/base";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

export default function PartnerHome(props) {
  const [items, setItems] = useState([]);

  const getItems = () => {
    let token = getItemFromLS("token");
    fetch(APIS._getBrands, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": "018226f84da242f498a008ae1022430b",
      },
    })
      .then((response) => response.json())
      .then((items) => setItems(items))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <>
      {items &&
        items.map((v) => {
          return (
            <div className="partnerHome">
              <Typist className="typist">
                <h1>
                  Hi <span style={{ color: "green" }}>{v.name}</span>, Welcome
                  To <span style={{ color: "blue" }}>Mzaalo</span>
                </h1>
              </Typist>
              <PartnerHomeContainer bgImage={v.company_logo}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Link
                    to={{
                      pathname: "/edit-partner",
                      state: { data: v },
                    }}
                    style={{ margin: "5px" }}
                  >
                    <button className="btn btn-warning">Edit Details</button>
                  </Link>
                  <Link
                    to={{
                      pathname: "/partner-details",
                      state: { data: v },
                    }}
                    style={{ margin: "5px" }}
                  >
                    <button className="btn btn-info">More Details</button>
                  </Link>
                  <Link
                    to={{
                      pathname: "/view-offers",
                      state: {
                        partnerName: v.name,
                        id: v.id,
                        country: v.country,
                      },
                    }}
                    style={{ margin: "5px" }}
                  >
                    <button className="btn btn-primary">View Offers</button>
                  </Link>
                </div>
                <div style={{ margin: "5px" }}>
                  <h5 style={{ color: "green" }}>Live Offer: {v.active}</h5>
                  <h5 style={{ color: "red" }}>Inactive Offer: {v.inactive}</h5>
                </div>
              </PartnerHomeContainer>
            </div>
          );
        })}
    </>
  );
}
