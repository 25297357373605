import React, { useState } from "react";
import Header from "../Header";
import PropTypes from "prop-types";
import { Tabs, Tab, makeStyles, Box, Typography } from "@material-ui/core";
import AddBulkUpload from "./AddBulkUpload";
import EditBulkUpload from "./EditBulkUpload";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "80vh",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const BulkUpload = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Header type="mainHeader" headerText="Bulk Upload" />
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Bulk Upload Feature"
          className={classes.tabs}
        >
          <Tab label="Add New Offers" {...a11yProps(0)} />
          <Tab label="Edit New Offers" disabled {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <AddBulkUpload />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EditBulkUpload />
        </TabPanel>
      </div>
    </>
  );
};

export default BulkUpload;
