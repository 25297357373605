import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import {
  Container,
  Alert,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  CustomInput,
  Button,
} from "reactstrap";
import { URL } from "../../../config";
import { PROMOCODE_APIS } from "../../../Constants/endpoints";
import { errorToast, successToast } from "../../../Containers/toastFunctions";
import { getToken } from "../../../Functions/userAuth";
import Header from "../../Header";
import PromoNavPanel from "./PromoNavPanel";

const AssociatePromoPartner = (props) => {
  const [promoCodeData, setPromoCodeData] = useState();
  const [promoCodeId, setPromoCodeId] = useState();
  const [promoPartnerId, setPromoPartnerId] = useState();
  const [partnerData, setPartnerData] = useState();
  const [isLoading, setLoading] = useState(false);

  const getPartnerList = () => {
    fetch(PROMOCODE_APIS._getPromoPartnerList, {
      method: "GET",
      headers: {
        authorization: `Bearer ${getToken("token")}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.data && res.data.data) {
          setPartnerData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (e) => {
    setPromoPartnerId(e.target.value);
  };

  const handleAssociatePromoPartner = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(PROMOCODE_APIS._associatePartner, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${getToken("token")}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
      body: JSON.stringify({ promoCodeId, promoPartnerId }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        setLoading(false);
        if (res && res.success) {
          successToast(res?.message);
        } else {
          errorToast(res?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorToast("Something went wrong!");
      });
  };

  useEffect(() => {
    if (props.location && props.location.state && props.location.state.data) {
      setPromoCodeId(props.location.state.data.id);
      setPromoCodeData(props.location.state.data);
    }

    getPartnerList();
  }, []);

  return (
    <>
      <Header type="mainHeader" headerText="Associate Partner to PromoCode" />
      <div className="promocode-associate">
        <PromoNavPanel />
        <Container style={{ marginTop: "10px" }}>
          <Form
            onSubmit={handleAssociatePromoPartner}
            style={{
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="promoCodeId">
                    Promo Code<span className="required">*</span>
                  </Label>
                  <Input
                    disabled={true}
                    type="text"
                    name="promoCodeId"
                    id="promoCodeId"
                    placeholder="Enter Promo Code Id"
                    required
                    value={`${promoCodeData?.promoCode}` || ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="partnerId">
                    Select Partner<span className="required">*</span>
                  </Label>
                  <CustomInput
                    type="select"
                    name="partnerId"
                    id="partnerId"
                    placeholder="Select Partner"
                    required
                    onChange={onChange}
                  >
                    <option value="">Select Partner</option>
                    {partnerData &&
                      partnerData.map((pd) => {
                        return (
                          <option
                            selected={pd?.id === promoPartnerId}
                            key={pd?.id}
                            value={pd?.id}
                          >
                            {pd?.partnerName}
                          </option>
                        );
                      })}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" disabled={isLoading}>
              Submit
            </Button>
          </Form>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
};

export default AssociatePromoPartner;
