import React, { useState } from "react";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Tab, Tabs } from "react-bootstrap";

export default function Page2(props) {
  return (
    <>
      <Card className="cardRedeem">
        <CardActionArea>
          <CardMedia
            component="img"
            alt={props.company_name}
            height="140px"
            width="80px"
            style={{ objectFit: "contain", padding: "1rem" }}
            image={props.company_logo}
            title={props.company_name}
          />
          <CardContent style={{ background: "black" }}>
            <Typography
              style={{ color: "white" }}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              <Tabs defaultActiveKey="Details" style={{ color: "white" }}>
                <Tab
                  eventKey="Details"
                  title="Details"
                  style={{ padding: "5px" }}
                >
                  {props.company_name}
                  <br />
                  {props.details}
                </Tab>
                <Tab eventKey="T&C" title="T&C">
                  {props.tnc}
                </Tab>
                <Tab eventKey="How To Redeem" title="How To Redeem">
                  {props.htr}
                </Tab>
              </Tabs>
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className="cardActions">
          <b>Redeem ({props.coins} Coins)</b>
        </CardActions>
      </Card>
    </>
  );
}
