import React, { useState, useEffect } from "react";
import {
  errorToast,
  successToast,
} from "../../../../Containers/toastFunctions";
import { BIDDING_APIS } from "../../../../Constants/endpoints";
import Header from "../../../Header";
import BiddingHeader from "../BiddingHeader";
import ImageUploader from "react-images-upload";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import axios from "axios";
import { getToken } from "../../../../Functions/userAuth";
import { BiddingImageContainer } from "../../../../Styles/base";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { Backdrop } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import { URL } from "../../../../config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const UpdateBiddingImages = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [productImages, setProductImages] = useState([]);
  const [product_images, setImages] = useState([]);
  const [isProductImageUploaded, setProductImageUploaded] = useState(false);
  const [eventId, setEventId] = useState("");
  const [showImage, setShowImage] = useState("");
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [deletedImages, setDeletedImages] = useState([]);

  const onPIDrop = (picture) => {
    productImages.splice(0, 1);
    setProductImages((productImages) => productImages.concat(picture));
  };

  const handleBackdropClose = () => {
    setShowImage("");
    setBackdropOpen(false);
  };
  const handleBackdropToggle = (val) => {
    setShowImage(val);
    setBackdropOpen(!backdropOpen);
  };

  const handleRemoveImages = (id) => {
    const index = product_images.indexOf(id);
    if (index > -1) {
      product_images.splice(index, 1);
      setDeletedImages((deletedImages) => deletedImages.concat(id));
    }
  };

  const handleProductImage = () => {
    if (productImages.length > 0) {
      let data = new FormData();
      for (const image of productImages) {
        data.append("images", image);
      }
      const _URL = window.URL || window.webkitURL;
      var img = new Image();
      img.src = _URL.createObjectURL(productImages[0]);
      img.onload = () => {
        fetch(BIDDING_APIS._uploadMultipleImages, {
          method: "POST",
          headers: {
            "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
          },
          body: data,
        })
          .then((response) => response.json())
          .then((res) => {
            if (res && res.data && res.data.urlArray) {
              setImages((product_images) =>
                product_images.concat(res.data.urlArray),
              );
              setProductImageUploaded(true);
              successToast("Images Uploaded successfully");
            } else {
              errorToast("No Image Uploaded");
            }
          })
          .catch((err) => {
            errorToast("Image Upload Failed");
          });
      };
    } else {
      errorToast("Please Upload an Image");
    }
  };

  const getBiddingImages = () => {
    eventId &&
      axios
        .get(`${BIDDING_APIS._getBiddingImages}?eventId=${eventId}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
          },
        })
        .then((res) => {
          if (res && res.data && res.data.success) {
            const data = res && res.data && res.data.data;
            setImages(data[0]?.product?.product_images);
          } else {
            errorToast(res.message);
          }
        })
        .catch((err) => {
          errorToast(err);
        });
  };

  const handleUpdateImage = (e) => {
    e.preventDefault();

    const bodyObj = {
      eventId,
      product_images,
    };

    axios
      .post(BIDDING_APIS._updateBiddingImages, bodyObj, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
        },
      })
      .then((res) => {
        if (res && res.data) {
          successToast(res.data.message);
          setTimeout(() => {
            history.push("/bidding"); // Navigate to the bidding page
          }, 2000); // Delay to allow the user to see the success message
        }
      })
      .catch((err) => {
        errorToast(err);
      });
  };

  useEffect(() => {
    setEventId(props.location.pathname.split("/").pop());

    getBiddingImages();
  }, [eventId]);

  return (
    <>
      <Header headerText="Update Bidding Images" type="mainHeader" />
      <BiddingHeader />
      <Container
        className="bidding-outer-container"
        style={{ marginTop: "10px" }}
      >
        <Row form>
          {product_images &&
            product_images.map((id) => {
              return (
                <>
                  <Col md={10}>
                    <FormGroup onClick={() => handleBackdropToggle(id)}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ cursor: "pointer" }}>
                            <PhotoLibraryIcon />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="product_images"
                          id="product_images"
                          required
                          placeholder="Upload Product Image (Aspect Ratio 1:1)"
                          value={id || ""}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Button
                        className={`${deletedImages.includes(id) ? "deleted" : "not-deleted"}`}
                        onClick={() => handleRemoveImages(id)}
                        color="danger"
                      >
                        Remove
                      </Button>
                    </FormGroup>
                  </Col>
                </>
              );
            })}
          <Col md={12}>
            <FormGroup>
              <Label for="product_images">
                Add New Product Images<span className="required">*</span>
              </Label>
              <Input
                type="text"
                name="product_images"
                id="product_images"
                required
                placeholder="Upload Product Image"
                value={product_images || ""}
              />
            </FormGroup>
          </Col>
          <Col md={10}>
            <ImageUploader
              withIcon={true}
              buttonText="Choose images"
              onChange={onPIDrop}
              withPreview={true}
              singleImage={false}
              imgExtension={[".jpg", ".gif", ".png", ".gif"]}
              maxFileSize={5242880}
              fileSizeError="File size is too big"
            />
          </Col>
          <Col
            md={2}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Button color="warning" onClick={handleProductImage}>
              {isProductImageUploaded ? "Upload Again" : "Upload"}
            </Button>
          </Col>
          <Col md={12}>
            <Button onClick={handleUpdateImage} color="primary">
              Update Images
            </Button>
          </Col>
        </Row>
      </Container>
      {showImage && (
        <Backdrop
          className={classes.backdrop}
          open={backdropOpen}
          onClick={handleBackdropClose}
        >
          <BiddingImageContainer src={showImage} />
        </Backdrop>
      )}
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
};

export default UpdateBiddingImages;
