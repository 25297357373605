import React, { useEffect, useReducer, useState } from "react";
import Header from "../../Header";
import PromoNavPanel from "./PromoNavPanel";
import {
  Container,
  Alert,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  CustomInput,
  Button,
} from "reactstrap";
import { PROMOCODE_APIS } from "../../../Constants/endpoints";
import { getToken } from "../../../Functions/userAuth";
import { errorToast, successToast } from "../../../Containers/toastFunctions";
import { ToastContainer } from "react-toastify";
import { URL } from "../../../config";

const CreatePromoCode = () => {
  const [alertVisible, setAlertVisible] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [partnerData, setPartnerData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const reducer = (state, newState) => ({ ...state, ...newState });
  const [promocodeData, setPromocodeData] = useReducer(reducer, {
    name: "",
    description: "",
    promoCode: "",
    count: 0,
    types: "",
    partnerId: "",
    event: "",
    startDate: "",
    endDate: "",
    isLimited: false,
    isOncePerUser: true,
    isVisible: true,
    isActive: true,
    isExpirable: true,
  });

  const onDismiss = () => {
    setAlertVisible(false);
  };

  const handleCreatePromoCode = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(PROMOCODE_APIS._createPromoCode, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${getToken("token")}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
      body: JSON.stringify(promocodeData),
    })
      .then((response) => response.json())
      .then(async (res) => {
        setLoading(false);
        if (res && res.success) {
          successToast(res?.message);
        } else {
          errorToast(res?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorToast("Something went wrong!");
      });
  };

  const onChange = (e) => {
    let { name, value } = e.target;

    if (name === "event") {
      let val = `{"tokens": ${value}}`;
      setPromocodeData({ event: JSON.parse(val) });
    } else setPromocodeData({ [name]: value });
  };

  const getPartnerList = () => {
    fetch(PROMOCODE_APIS._getPromoPartnerList, {
      method: "GET",
      headers: {
        authorization: `Bearer ${getToken("token")}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.data && res.data.data) {
          setPartnerData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCampaignList = () => {
    fetch(PROMOCODE_APIS._getCampaignList, {
      method: "GET",
      headers: {
        authorization: `Bearer ${getToken("token")}`,
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.data && res.data.data) {
          setCampaignData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPartnerList();
    getCampaignList();
  }, []);

  return (
    <>
      <Header type="mainHeader" headerText="Create New Promo Code" />
      <div className="promocode-create">
        <PromoNavPanel />
        <Container style={{ marginTop: "10px" }}>
          <Alert color="info" isOpen={alertVisible} toggle={onDismiss}>
            All <span className="required">*</span> fields are mandatory. To
            Create a New Promo Partner, Please fill all the details.
          </Alert>
          <Form
            onSubmit={handleCreatePromoCode}
            style={{
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="promoCode">
                    Promo Code<span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="promoCode"
                    id="promoCode"
                    placeholder="Enter Promo Code"
                    required
                    maxlength="25"
                    onChange={onChange}
                  />
                  <FormText>Limit the text to maximum 25 characters.</FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="name">
                    Name<span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter Name"
                    required
                    maxlength="100"
                    onChange={onChange}
                  />
                  <FormText>Limit the text to maximum 100 characters.</FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    type="text"
                    name="description"
                    id="description"
                    placeholder="Enter Description"
                    maxlength="100"
                    onChange={onChange}
                  />
                  <FormText>Limit the text to maximum 100 characters.</FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="event">Tokens</Label>
                  <Input
                    type="number"
                    name="event"
                    id="event"
                    placeholder="Enter Tokens"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="count">Count</Label>
                  <Input
                    type="number"
                    name="count"
                    id="count"
                    placeholder="Enter Count"
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="startDate">
                    Start Date<span className="required">*</span>
                  </Label>
                  <Input
                    type="datetime-local"
                    name="startDate"
                    id="startDate"
                    placeholder="Enter Start Date"
                    required
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="endDate">
                    End Date<span className="required">*</span>
                  </Label>
                  <Input
                    type="datetime-local"
                    name="endDate"
                    id="endDate"
                    placeholder="Enter End Date"
                    required
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="partnerId">Select Partner</Label>
                  <CustomInput
                    type="select"
                    name="partnerId"
                    id="partnerId"
                    placeholder="Select Partner"
                    onChange={onChange}
                  >
                    <option value="">Select Partner</option>
                    {partnerData &&
                      partnerData.map((pd) => {
                        return (
                          <option key={pd?.id} value={pd?.id}>
                            {pd?.partnerName}
                          </option>
                        );
                      })}
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="campaignId">
                    Select Campaign<span className="required">*</span>
                  </Label>
                  <CustomInput
                    type="select"
                    name="campaignId"
                    id="campaignId"
                    placeholder="Select Campaign"
                    required
                    onChange={onChange}
                  >
                    <option value="">Select Campaign</option>
                    {campaignData &&
                      campaignData.map((cd) => {
                        return (
                          <option key={cd?.id} value={cd?.id}>
                            {cd?.name}
                          </option>
                        );
                      })}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="types">Types</Label>
                  <CustomInput
                    type="select"
                    name="types"
                    id="types"
                    placeholder="Select Types"
                    onChange={onChange}
                  >
                    <option value="">Select Types</option>
                    <option value="generic">Generic</option>
                    <option value="unique">Unique</option>
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="isLimited">Select Limited Status</Label>
                  <CustomInput
                    type="select"
                    name="isLimited"
                    id="isLimited"
                    placeholder="Select Limited Status"
                    onChange={onChange}
                  >
                    <option value="">Select Limited Status</option>
                    <option value="true">Limited</option>
                    <option value="false">Not Limited</option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="isOncePerUser">Select Once Per User Status</Label>
                  <CustomInput
                    type="select"
                    name="isOncePerUser"
                    id="isOncePerUser"
                    placeholder="Select Once Per User Status"
                    onChange={onChange}
                  >
                    <option value="">Select Once Per User Status</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="isVisible">Select Visible Status</Label>
                  <CustomInput
                    type="select"
                    name="isVisible"
                    id="isVisible"
                    placeholder="Select Visible Status"
                    onChange={onChange}
                  >
                    <option value="">Select Visible Status</option>
                    <option value="true">Visible</option>
                    <option value="false">Not Visible</option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="isActive">Select Active Status</Label>
                  <CustomInput
                    type="select"
                    name="isActive"
                    id="isActive"
                    placeholder="Select Visible Status"
                    onChange={onChange}
                  >
                    <option value="">Select Active Status</option>
                    <option value="true">Active</option>
                    <option value="false">Disabled</option>
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="isExpirable">Select Expirable Status</Label>
                  <CustomInput
                    type="select"
                    name="isExpirable"
                    id="isExpirable"
                    placeholder="Select Expirable Status"
                    onChange={onChange}
                  >
                    <option value="">Select Expirable Status</option>
                    <option value="true">Expirable</option>
                    <option value="false">Not Expirable</option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" disabled={isLoading}>
              Submit
            </Button>
          </Form>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
};

export default CreatePromoCode;
