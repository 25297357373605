import Header from "../../Header";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { APIS } from "../../../Constants/endpoints";
import { URL } from "../../../config";

const PartnerCategory = () => {
  const [allPartner, setAllPartner] = useState([]);
  const [category, setCategory] = useState("");
  const [partner, setPartner] = useState("");
  const [loader, setLoader] = useState();

  const allPartners = async () => {
    //Request to Get all partners
    setLoader(true);
    const { data } = await axios.get(`${APIS._getRewardPartners}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": URL.mzaaloAdmin_ocp_key,
      },
    });

    if (data) {
      setAllPartner(data.data);
      setLoader(false);
    }
    localStorage.getItem("partner") &&
      setPartner(JSON.parse(localStorage.getItem("partner")));
  };

  const allCategorys = async () => {
    //Request to Get all partner category and redemption category
    if (partner) {
      setLoader(true);

      const { data } = await axios.get(
        `${APIS._getRewardPartnerCategory}?client_id=${partner}`,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": URL.mzaaloAdmin_ocp_key,
          },
        },
      );
      if (data) {
        setCategory(data.data[0]);
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    allPartners();

    if (partner) {
      localStorage.setItem("partner", JSON.stringify(partner));
      allCategorys();
    }
  }, [partner]);

  return (
    <div>
      <Header headerText="Rewards Category" />

      <Card
        style={{
          margin: 20,
          padding: 20,
          border: "2px solid",
          borderRadius: 10,
        }}
      >
        {loader && (
          <Card style={{ display: "flex" }}>
            <Spinner
              color="primary"
              style={{
                height: "3rem",
                width: "3rem",
              }}
            >
              Loading...
            </Spinner>
          </Card>
        )}

        <Card
          className="my-2"
          style={{
            margin: 10,
            padding: 10,
            border: "1px solid",
            width: "50%",
            borderRadius: 10,
          }}
        >
          <Form>
            <FormGroup required>
              <Label for="selectPartner" tag="h4">
                Select Partner :-
              </Label>
              <Input
                id="selectPartner"
                name="select"
                type="select"
                bsSize="lg"
                value={partner}
                onChange={(e) => setPartner(e.target.value)}
              >
                {allPartner.map((part) => (
                  <option key={part.id} value={part.id}>
                    {part.partner_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Form>
        </Card>

        <hr
          style={{
            background: "black",
            height: "2px",
          }}
        />
        {allPartner && loader && (
          <Card style={{ display: "flex" }}>
            <Spinner
              color="primary"
              style={{
                height: "3rem",
                width: "3rem",
              }}
            >
              Loading...
            </Spinner>
          </Card>
        )}
        {partner && category?.clientCategory?.length === 0 && (
          <h3>No Categories To Show</h3>
        )}
        {partner &&
          category &&
          category.clientCategory.map((i) => (
            <Card
              style={{
                margin: 5,
                padding: 10,
                border: "1px solid",
                width: "60%",
                borderRadius: 5,
              }}
              key={i.client_category_id}
            >
              <Row>
                <Col>
                  <CardTitle tag="h6">Partner Category :- </CardTitle>
                  <CardText tag="h5">{i.client_category_name}</CardText>
                </Col>

                <Col>
                  <CardTitle tag="h6">Category :-</CardTitle>
                  <CardText tag="h5">
                    {i.system_category_name || "NONE"}
                  </CardText>
                </Col>

                <Col>
                  <Link
                    to={`/update-reward-category/${partner}/${i.client_category_id}/${i.client_category_name}/${i.system_category_id}/${i.system_category_name}`}
                  >
                    <Button color="primary" style={{ width: "100%" }}>
                      Edit
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          ))}
      </Card>
    </div>
  );
};

export default PartnerCategory;
