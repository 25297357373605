import React, { useEffect, useState } from "react";
import Header from "../../Header";
import PromoNavPanel from "./PromoNavPanel";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { convertDateToString } from "../../../Functions/dateFormats";

const ViewPromoCodeDetail = (props) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (props.location.state && props.location.state.data) {
      setData(props.location.state.data);
    } else {
      getPromoId();
    }
  }, []);

  const getPromoId = () => {
    return window.location.pathname.split("/").pop();
  };

  return (
    <>
      <Header type="mainHeader" headerText="View PromoCode Detail" />
      <div className="promocode-view-detail">
        <PromoNavPanel />
        <Container className="themed-container">
          <Row
            style={{
              backgroundColor: "white",
              boxShadow: "10px 8px 10px rgba(0,0,0,0.4)",
              padding: "20px",
              margin: "20px",
            }}
          >
            <Col>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Id: </Col>
                <Col sm={8}>{data?.id}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Promo Code: </Col>
                <Col className="highlight" sm={8}>
                  {data?.promoCode}
                </Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Name: </Col>
                <Col sm={8}>{data?.name}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Description: </Col>
                <Col sm={8}>{data?.description}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Start Date: </Col>
                <Col sm={8}>{convertDateToString(data?.startDate)}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>End Date: </Col>
                <Col sm={8}>{convertDateToString(data?.endDate)}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Types: </Col>
                <Col sm={8}>{data?.types}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Count: </Col>
                <Col sm={8}>{data?.count}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Used Count: </Col>
                <Col sm={8}>{data?.usedCount}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Tokens: </Col>
                <Col sm={8}>{data?.event?.tokens}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Active Status: </Col>
                <Col sm={8}>{data?.isActive ? "Active" : "Not Active"}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Visiblity Status: </Col>
                <Col sm={8}>{data?.isVisible ? "Visible" : "Not Visible"}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Limited Status: </Col>
                <Col sm={8}>{data?.isLimited ? "Limited" : "Not Limited"}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Expiry Status: </Col>
                <Col sm={8}>
                  {data?.isExpirable ? "Expirable" : "Not Expirable"}
                </Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Once Per User: </Col>
                <Col sm={8}>{data?.isOncePerUser ? "Yes" : "No"}</Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Campaign Name: </Col>
                <Col className="highlight2" sm={8}>
                  {data?.Campaign?.name}
                </Col>
              </Row>
              <Row className="promocode-view-detail--row">
                <Col sm={4}>Promo Partner Name: </Col>
                <Col className="highlight3" sm={8}>
                  {data?.PromoPartner ? (
                    data?.PromoPartner?.partnerName
                  ) : (
                    <Link
                      to={{
                        pathname: `/promocode/partner/associate/${data?.id}`,
                        state: { data: data },
                      }}
                    >
                      Add Partner
                    </Link>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Link
            style={{ padding: "20px", margin: "20px" }}
            to={{
              pathname: `/promocode/edit/${data?.id}`,
              state: { data: data },
            }}
          >
            Edit Details
          </Link>
        </Container>
      </div>
    </>
  );
};

export default ViewPromoCodeDetail;
