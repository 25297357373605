import React, { useState, useEffect } from "react";
import Header from "../../Header";
import { Button, Row, Col } from "reactstrap";
import { ViewTypeContainer } from "../../../Styles/base";
import { Container, TextField, Grid } from "@material-ui/core";
import GridOnIcon from "@material-ui/icons/GridOn";
import ViewListIcon from "@material-ui/icons/ViewList";
import Tooltip from "@material-ui/core/Tooltip";
import { BIDDING_APIS } from "../../../Constants/endpoints";
import { getToken, notPartner } from "../../../Functions/userAuth";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import DataCard from "../../Cards/DataCard";
import DataTable from "../../../Components/Tables/DataTable";
import PartnerHome from "../../../Components/Home/PartnerHome";
import { URL } from "../../../config";

const RewardPartner = () => {
  const [data, setData] = useState([]);
  const [gridView, setGridView] = useState(true);
  const [searchText, setSearchText] = useState("");

  const getAllBiddingEvents = () => {
    fetch(BIDDING_APIS._getAllBiddingEvents, {
      method: "GET",
      headers: {
        authorization: getToken(),
        "Ocp-Apim-Subscription-Key": URL.bidding_ocp_key,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res) {
          setData(res.data.events);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleGridView = () => {
    setGridView(!gridView);
  };

  useEffect(() => {
    getAllBiddingEvents();
  }, []);

  return (
    <>
      <Header headerText="Reward Partner Home" type="mainHeader" />
      <Container>
        {notPartner() ? (
          <>
            <Row>
              <Col style={{ margin: "2rem 0" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Grid container spacing={1} alignItems="flex-end">
                    <Grid item>
                      <SearchIcon />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        fullWidth
                        label="Search"
                        value={searchText}
                        onChange={(e) => handleSearch(e)}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Col>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                }}
              >
                <ViewTypeContainer gridView={gridView} onClick={handleGridView}>
                  <Tooltip title="List View">
                    <ViewListIcon />
                  </Tooltip>
                </ViewTypeContainer>
                <ViewTypeContainer gridView={gridView} onClick={handleGridView}>
                  <Tooltip title="Grid View">
                    <GridOnIcon />
                  </Tooltip>
                </ViewTypeContainer>

                {notPartner() ? (
                  <>
                    <Link to="/add-partner" target="_blank">
                      <Button color="secondary" style={{ marginRight: "15px" }}>
                        Add New Partner
                      </Button>
                    </Link>
                    <Link to="/redemption-report" target="_blank">
                      <Button color="primary" style={{ marginRight: "15px" }}>
                        Redemption Report
                      </Button>
                    </Link>
                    <Link to="/rewards-category">
                      <Button color="primary" style={{ marginRight: "15px" }}>
                        Rewards Category Mapping
                      </Button>
                    </Link>
                  </>
                ) : null}
              </Col>
            </Row>
            <Row>
              <div style={{ overflow: "hidden" }}>
                <div
                  className="fadeIn"
                  style={{ display: `${gridView ? "block" : "none"}` }}
                >
                  <DataCard searchText={searchText} />
                </div>
                <div
                  className="fadeIn"
                  style={{ display: `${!gridView ? "block" : "none"}` }}
                >
                  <DataTable searchText={searchText} />
                </div>
              </div>
            </Row>
          </>
        ) : (
          <PartnerHome />
        )}
      </Container>
    </>
  );
};

export default RewardPartner;
