import React from "react";
import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/core";
import PropTypes from "prop-types";

export const LoginContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 480px;
  background: white;
  margin: 40px;
  text-align: -webkit-center;
`;

export const ImageContainer = styled("img")`
  margin-top: "5px";
  width: 50px;
  height: 50px;
`;

export const CompanyLogoContainer = styled("img")`
  margin-top: 5px;
  width: 150px;
  height: 150px;
`;

export const InputContainer = styled("input")`
  width: 250px;
`;

export const LabelContainer = styled("label")`
  display: flex;
  justify-content: space-between;
  padding: 5px;
`;

export const FieldsetContainer = styled("fieldset")`
  padding: 1em;
  border: 1px solid green;
  margin: 20px;
`;

export const LegendContainer = styled("legend")`
  width: 60px;
  margin-left: 0.2em;
  margin-right: 0.2em;
  color: green;
  text-align: -webkit-center;
  border-radius: 5px;
`;

export const Button = styled("button")`
  width: 100px;
  color: #ffffff;
  border: 1px solid #87ded6;
  border-radius: 10px;
  padding: 5px;
  background-image: linear-gradient(180deg, #94e2f2, #465e9c);
`;

export const SearchInputContainer = styled("input")`
  padding-left: 10px;
  height: 37px;
  font-size: large;
  border-radius: 5px;
  border: 1px solid #ccc;
  &:focus {
    outline: none;
  }
`;

export const SearchInputBox = styled("div")`
  font-weight: 100;
  color: #000;
  margin-right: 15px;
`;

export const OfferContainer = styled("div")`
  background: whitesmoke;
  padding: 10px;
`;

export const CouponCodeContainer = styled("div")`
  text-align: center;
  padding: 5px;
  font-size: x-large;
  border: 1px dashed;
  border-radius: 5px;
  background: bottom;
  filter: drop-shadow(2px 6px 4px black);
`;

export const ExportCouponContainer = styled("div")`
  text-align: center;
  padding: 5px;
  font-size: x-large;
  filter: drop-shadow(2px 6px 4px black);
`;

export const SpaceEvenlyFlex = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const ViewTypeContainer = styled("div")`
  cursor: pointer;
  font-weight: 600;
  margin-right: 5px;
`;

export const UploadButtonContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PartnerHomeContainer = styled("div")`
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  background: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => props.bgImage});
`;

export const BiddingImageContainer = styled("img")`
  width: 340px;
  height: 340px;
`;
