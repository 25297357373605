import React, { Component } from "react";
import Header from "../Header";
import {
  Form,
  Alert,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";

export default class Error403 extends Component {
  render() {
    return (
      <>
        <Header headerText="Error 403 (Forbidden Access)" type="mainHeader" />
        <Container>
          <Alert color="danger" style={{ margin: "10px" }}>
            You are not Authorized for this page. Please fill the below details
            to contact with Administrator.
          </Alert>
          <Form
            onSubmit={this.handleAddOffer}
            style={{
              border: "1px solid gainsboro",
              borderRadius: "5px",
              padding: "20px",
              margin: "10px",
            }}
          >
            <FormGroup>
              <Label for="coupon_company">Your Company Name*</Label>
              <Input
                type="text"
                name="coupon_company"
                id="coupon_company"
                placeholder="Enter Company Name"
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="user_email">Your Email Address*</Label>
              <Input
                type="text"
                name="user_email"
                id="user_email"
                placeholder="Enter Email Address"
                onChange={this.onChange}
              />
            </FormGroup>
            <Button>Submit</Button>
          </Form>
        </Container>
      </>
    );
  }
}
